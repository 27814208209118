import { Inject, Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom, Observable } from 'rxjs';

import { ActionId, ActionsHub, CreateEditStudyplanEventActionTrigger } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { StudyplanTrackingSource } from '@stsm/analytics/models/source-property-types';
import { CalendarDate } from '@stsm/date/global/util/calendar.util';
import { CalendarDateConverter } from '@stsm/date/global/util/calendar-date-converter';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { StudyplanEvent } from '@stsm/studyplan/models/studyplan-event';
import { Studyset } from '@stsm/studysets/models/studyset';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { CreateEditStudyplanEventComponent } from './create-edit-studyplan-event/create-edit-studyplan-event.component';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to create / edit a studyplan event instead.
 */
@Injectable({ providedIn: 'root' })
export class CreateEditStudyplanEventModalService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.CREATE_EDIT_STUDYPLAN_EVENT, (params: CreateEditStudyplanEventActionTrigger) =>
      firstValueFrom(this._show(params)),
    );
  }

  init(): void {
    noop();
  }

  private _show(options: {
    calendarDate?: CalendarDate;
    studyplanEventToEdit?: StudyplanEvent;
    isNewExam?: boolean;
    studyset?: Studyset;
    source: StudyplanTrackingSource;
  }): Observable<StudyplanEvent | undefined> {
    const { calendarDate, studyplanEventToEdit, isNewExam, studyset, source } = options;

    this._analyticsService.trackEvent({
      action: 'event_modal_open',
      properties: {
        mode: studyplanEventToEdit ? 'edit' : 'create',
        source,
      },
    });

    return this._platformModalService.schedule<InputPropertiesOf<CreateEditStudyplanEventComponent>, StudyplanEvent | undefined>({
      component: CreateEditStudyplanEventComponent,
      data: <InputPropertiesOf<CreateEditStudyplanEventComponent>>{
        date: calendarDate ? CalendarDateConverter.toDate(calendarDate) : undefined,
        studyplanEvent: studyplanEventToEdit,
        isNewExam,
        studyset,
        source,
      },
      webOptions: {
        width: 'min(480px, 80vw)',
      },
      autoFocus: true,
    });
  }
}
