<div class="header">
  <img alt="" [src]="LogoSource.HAT_WHITE" />
  <div class="display-xxs-semibold">{{ "PREMIUM.PREMIUM_LIMIT.HEADING" | translate }}</div>
  <button uiIconButton tertiaryOnTheme size="sm" data-cy="premium-limit-toast-close-button" (click)="dismiss()">
    <i slot="icon-only" class="app-icon-close"></i>
  </button>
</div>

<div class="content">
  <div class="text-lg-semibold">{{ data.heading | translate }}</div>
  <div class="text-lg-regular">{{ data.description | translate }}</div>
</div>

<button uiButton primaryOnTheme data-cy="premium-limit-toast-get-premium-button" (click)="openPremiumModal()">
  {{ (data.hasUsedTrial ? "PREMIUM.PREMIUM_LIMIT.GET_PREMIUM" : "PREMIUM.PREMIUM_LIMIT.TRY_PREMIUM") | translate }}
</button>
