import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { Tab } from '@stsm/shared/enums/tab';
import { User } from '@stsm/user/models/user';
import { isCoreMarketUser } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

export const canActivateProfileInfoGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const userStoreFacade = inject(UserStoreFacade);

  return userStoreFacade.user$.pipe(
    take(1),
    map((user: User) => isCoreMarketUser(user) || router.createUrlTree([Tab.PROFILE])),
  );
};
