import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-pixel',
  templateUrl: './pixel.component.html',
  styleUrls: ['./pixel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PixelComponent {
  /** set via the {@link PixelService} */
  @Input() pixelSource!: string;
}
