import { ChangeDetectionStrategy, Component, EventEmitter, input, InputSignal, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { Tab } from '@stsm/shared/enums/tab';
import { ToolbarItemComponent } from '@stsm/ui-components/toolbar-item';

import { NavigationRoute } from '../../models/navigation-route';
import { TabIndicatorMap } from '../../tab-status.service';

@UntilDestroy()
@Component({
  selector: 'app-navigation-menu',
  imports: [ToolbarItemComponent, TranslatePipe],
  templateUrl: './navigation-menu.component.html',
  styleUrl: './navigation-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent {
  readonly visibleNavigationRoutes: InputSignal<NavigationRoute[] | undefined> = input();
  readonly currentTab: InputSignal<Tab | undefined> = input();
  readonly indicators: InputSignal<TabIndicatorMap | undefined> = input();
  readonly isFullPageRouteActive: InputSignal<boolean | undefined> = input();

  @Output() readonly navItemClicked: EventEmitter<Tab> = new EventEmitter();
}
