<ui-dialog-header showCloseButton (closeButtonClick)="cancel()">
  {{ "FLASHCARD_DASHBOARD.AB_SPACED_REPETITION_SETTINGS" | translate }}
</ui-dialog-header>

<form class="content" [formGroup]="form">
  <section class="settings">
    <p data-cy="flashcard-dashboard-settings-modal-global-settings-title" class="title">
      {{ "FLASHCARD_DASHBOARD.GLOBAL_SETTINGS" | translate }}
    </p>

    <ui-form-field>
      <label uiLabel data-cy="flashcard-dashboard-settings-modal-chunk-size-label">
        {{ "FLASHCARD_DASHBOARD.FLASHCARDS_PER_CYCLE" | translate }}
      </label>
      <ui-number-input
        data-cy="flashcard-dashboard-settings-modal-chunk-size-input"
        data-testid="chunk-size-input"
        formControlName="chunkSize"
        ngDefaultControl
        [min]="minimumChunkSize"
        [max]="maximumChunkSize"
      />
    </ui-form-field>

    <ui-form-field>
      <label uiLabel id="order-label">{{ "FILTER.ORDER" | translate }}</label>
      <ui-select data-cy="filter-dialog-sort-by-select" formControlName="orderMode" aria-labelledby="order-label">
        <ui-select-trigger>
          {{ form.controls.orderMode.value | translate }}
        </ui-select-trigger>
        <ui-option *ngFor="let option of orderOptions; trackBy: trackByDefault" [value]="option">{{ option | translate }}</ui-option>
      </ui-select>
    </ui-form-field>
  </section>

  <section class="buttons">
    <p *ngIf="studyset" data-cy="flashcard-dashboard-settings-modal-studyset-settings-title" class="title">
      {{ "FLASHCARD_DASHBOARD.STUDYSET_SETTINGS" | translate: { studysetName: studyset.name | uiTruncateCenterWithEllipsis: 25 } }}
    </p>
    <button uiButton secondary data-cy="flashcard-dashboard-settings-modal-pause-button" (click)="pauseSpacedRepetition()">
      <i class="app-icon-pause" slot="start"></i>
      {{ "FLASHCARD_DASHBOARD.AB_PAUSE_SPACED_REPETITION" | translate }}
    </button>
  </section>
</form>

<div class="button-footer">
  <button uiButton secondary data-cy="flashcard-dashboard-settings-modal-cancel-button" (click)="cancel()">
    {{ "GLOBAL.CANCEL" | translate }}
  </button>
  <button uiButton data-cy="flashcard-dashboard-settings-modal-confirm-button" [disabled]="form.invalid" (click)="submit()">
    {{ "GLOBAL.SAVE" | translate }}
  </button>
</div>
