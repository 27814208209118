<div class="dialog-wrapper">
  <button
    uiIconButton
    secondary
    size="xs"
    class="close-button"
    data-cy="sharing-dialog-close-button"
    [attr.aria-label]="'GLOBAL.CLOSE' | translate"
    (click)="onClose()"
  >
    <i class="app-icon-close"></i>
  </button>
  <div class="header">
    <div class="icon-wrapper">
      <i uiAnimatedIllustration name="referrals-icon"></i>
    </div>
    <h3 data-cy="sharing-dialog-heading">{{ "PREMIUM.PREMIUM_FOR_INVITES.INVITATION_HEADER" | translate }}</h3>
  </div>
  <div class="content">
    @if (hasInviteHistory()) {
      <ui-tab-group-swipeable hasStickyNav noSwiping forceMobileLayout [autoHeight]="!isMobileApp" (tabChange)="onTabChange($event)">
        <ng-template
          uiSwipeableTab
          tabId="how_it_works"
          dataCy="sharing-dialog-tab-how-it-works"
          [label]="'SHARE.SHARING_DIALOG.HOW_IT_WORKS' | translate"
        >
          <div class="tab-content how-it-works" data-cy="sharing-dialog-tab-content-how-it-works">
            <ng-container [ngTemplateOutlet]="howItWorksSteps" />
          </div>
        </ng-template>

        <ng-template
          uiSwipeableTab
          tabId="invite_history"
          dataCy="sharing-dialog-tab-invite-history"
          [label]="'SHARE.SHARING_DIALOG.TAB_INVITE_HISTORY.TITLE' | translate"
        >
          <div class="tab-content invite-history" data-cy="sharing-dialog-tab-content-invite-history">
            <div class="referral-total">
              <h5 class="display-xxxs-h5-medium">
                {{ "SHARE.SHARING_DIALOG.TAB_INVITE_HISTORY.TOTAL_REFERRALS" | translate }}
              </h5>
              <ui-badge data-cy="sharing-dialog-invite-total">{{ signupsGenerated() }}</ui-badge>
            </div>

            <p class="text-sm-regular">{{ "SHARE.SHARING_DIALOG.TAB_INVITE_HISTORY.EXPLANATION" | translate }}</p>

            <ol class="invited-users" (click)="trackInviteHistoryClick()">
              @for (invitedUser of invitedUsers(); track $index) {
                <li class="invited-user" data-cy="sharing-dialog-invited-user">
                  <div class="invited-user-details">
                    <i class="app-icon-user"></i>

                    <div class="signup-details">
                      <address>{{ invitedUser.email }}</address>
                      <time [dateTime]="invitedUser.dateJoined">
                        {{ invitedUser.dateJoined | localeDateString: dateFormat }}
                      </time>
                    </div>
                  </div>

                  <ui-tag class="invited-user-status" [colorId]="invitedUser.referralState === 'activated' ? 'denim' : undefined">
                    {{ inviteStatusTranslationKeys[invitedUser.referralState] | translate }}
                  </ui-tag>
                </li>
              }
            </ol>
          </div>
        </ng-template>
      </ui-tab-group-swipeable>
    } @else {
      <h4 class="sub-title">{{ "SHARE.SHARING_DIALOG.HOW_IT_WORKS" | translate }}</h4>
      <ng-container [ngTemplateOutlet]="howItWorksSteps" />
    }

    <span class="terms-conditions">{{ "SHARE.SHARING_DIALOG.TERMS_CONDITIONS_NOTE" | translate }}</span>
  </div>
</div>

@if (isMobileApp) {
  <div class="buttons-container mobile-container">
    <button uiButton size="lg" primary data-cy="sharing-dialog-mobile-cta" (click)="shareApp()">
      <i slot="icon" [class]="shareIconName"></i>{{ "SHARE.SHARING_DIALOG.INVITE_FRIENDS" | translate }}
    </button>
  </div>
} @else {
  <div class="buttons-container">
    <a uiButton secondary data-cy="sharing-dialog-share-via-email-button" [href]="mailtoHref()" (click)="trackShareViaEmail()">
      <i class="app-icon-mail" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.SHARE_EMAIL_BUTTON" | translate }}
    </a>

    <button uiButton secondary data-cy="sharing-dialog-copy-link-button" (click)="copyShareLink()">
      <i class="app-icon-link" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.COPY_LINK_BUTTON" | translate }}
    </button>
  </div>
}

<ng-template #howItWorksSteps>
  <div class="steps-wrapper">
    @for (step of steps; track step.title; let last = $last) {
      <div class="step">
        <div class="stepper-path">
          <div class="icon-wrapper">
            <i [class]="step.icon"></i>
          </div>
          @if (!last) {
            <div class="line"></div>
          }
        </div>
        <div class="step-content">
          <h5>{{ step.title | translate }}</h5>
          <p>{{ step.description | translate }}</p>
        </div>
      </div>
    }
  </div>
</ng-template>
