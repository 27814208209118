<button uiButton secondary size="sm" class="download-btn">
  <a download target="_self" data-cy="flashcard-import-xls-download-template-button" [href]="templateLink" (click)="downloadXlsTemplate()">
    {{ "IMPORT_FLASHCARDS.DOWNLOAD_TEMPLATE" | translate }}
  </a>
  <i class="app-icon-excel"></i>
</button>
<div class="upload-container">
  <ui-file-upload-drop-zone [dashedStyle]="true" (uploadFiles)="onFilesDropped($event)">
    <div class="upload">
      <ng-container *ngIf="!selectedFile">
        <button uiButton class="upload-button" size="lg" data-cy="flashcard-import-xls-choose-file-button">
          <label class="upload-btn">
            <span class="button-text">{{ "IMPORT_FLASHCARDS.UPLOAD.FILE" | translate }}</span>
            <i class="app-icon-download"></i>
            <ui-file-select
              dataCy="flashcard-import-xls-upload-file-input"
              [accept]="acceptedFormat"
              (filesSelected)="onSelectFiles($event)"
            />
          </label>
        </button>
        <span class="upload-text">
          {{ "IMPORT_FLASHCARDS.UPLOAD.TEXT" | translate }}
        </span>
      </ng-container>

      <div *ngIf="selectedFile" class="confirm">
        <span class="name">
          {{ selectedFile.name }}
        </span>

        <button uiIconButton quarternary size="xs" (click)="removeFile()">
          <i class="app-icon-trash"></i>
        </button>
      </div>
    </div>
  </ui-file-upload-drop-zone>
</div>
<div class="row-bottom">
  <div class="buttons">
    <button class="cancel-btn" uiButton secondary data-cy="flashcard-import-xls-cancel-button" (click)="onClose()">
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button
      uiButton
      class="button-control"
      data-cy="flashcard-import-xls-upload-button"
      [disabled]="!selectedFile"
      (click)="uploadXlsFile()"
    >
      <i class="app-icon-upload" slot="start"></i>
      <span class="button-text">{{ "IMPORT_FLASHCARDS.UPLOAD.BUTTON" | translate }}</span>
    </button>
  </div>
</div>
