@if (showNotificationBadge()) {
  <div class="badge-wrapper">
    <ui-dot-badge notificationBadge [badgeValue]="true" />
  </div>
}

<div class="gradient-border" [@layoutContainer]="vm.animationState()">
  <div class="container">
    <div class="content" [@content]="vm.animationState()">
      <app-ai-assistant
        [showChatContent]="showChatContent()"
        [isChatOpen]="vm.isChatOpen()"
        [isGeneratingResponse]="isGeneratingResponse()"
      >
        <ng-container slot="header">
          @if (showBottomSheetToggle() && showChatContent()) {
            <button @fadeInOut class="bottom-sheet-toggle" (touchstart)="toggleChatHeight($event)" (click)="toggleChatHeight($event)">
              <span class="toggle-bar"></span>
            </button>
          }

          <app-chat-top-row
            [animationState]="animationState()"
            [isGeneratingResponse]="isGeneratingResponse()"
            [isAnimatingAvatar]="vm.isProcessing()"
            [showNotification]="!vm.isChatOpen()"
            [pillMessage]="vm.pillMessage()"
            [isRefreshDisabled]="premiumVm.messageLimitReached()"
            (resetAssistant)="reset()"
            (closeAssistant)="onCloseClick()"
            (click)="openAssistant()"
          />
        </ng-container>
      </app-ai-assistant>
    </div>
  </div>
</div>
