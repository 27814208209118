import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { ShareDialogOpenSource } from '@stsm/analytics/models/source-property-types';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { InvitedUser } from '@stsm/user/models/invited-user';
import { USER_SERVICE } from '@stsm/user/services/tokens/user-service.token';
import { UserBaseService } from '@stsm/user/services/user-base.service';

import { SharingDialogComponent } from '../components/sharing-dialog/sharing-dialog.component';

@Injectable({ providedIn: 'root' })
export class SharingDialogService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(USER_SERVICE) private readonly _userService: UserBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _abTestService: AbTestService,
  ) {}

  async openSharingDialog(source: ShareDialogOpenSource): Promise<void> {
    const variantData = await this._abTestService.getExperimentValue(ExperimentFlag.REFERRAL_V1);
    const hasInviteHistory = variantData.value === 'treatment';

    let invitedUsers: InvitedUser[] = [];

    if (hasInviteHistory) {
      invitedUsers = await firstValueFrom(this._userService.getInvitedUsers()).catch(() => []);
    }

    this._analyticsService.trackEvent({
      action: 'share_app_modal_open',
      properties: { source },
    });

    this._platformModalService.create({
      component: SharingDialogComponent,
      data: {
        source,
        hasInviteHistory,
        invitedUsers,
      },
      webOptions: {
        maxWidth: '600px',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
