import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, TrackByFunction } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { clone, isNil } from 'lodash-es';
import { catchError, combineLatest, finalize, Observable, take, tap } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { FlashcardOrderMode, FlashcardOrderModeUtil } from '@stsm/flashcards/types/flashcard-order-mode';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { trackByDefault } from '@stsm/shared/util/generic-utils';
import { SpacedRepetitionService } from '@stsm/spaced-repetition/services/spaced-repetition.service';
import { SpacedRepetitionSettingsService } from '@stsm/spaced-repetition/services/spaced-repetition-settings.service';
import { SpacedRepetitionUtils } from '@stsm/spaced-repetition/util/spaced-repetition-utils';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogHeaderComponent } from '@stsm/ui-components/dialogs/components/dialog-header/dialog-header.component';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { FormFieldComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { NumberInputComponent } from '@stsm/ui-components/number-input/number-input.component';
import { OptionComponent } from '@stsm/ui-components/option';
import { SelectComponent, SelectTriggerDirective } from '@stsm/ui-components/select';
import { TruncateCenterWithEllipsisPipe } from '@stsm/ui-components/truncate-center-with-ellipsis-pipe';
import { User } from '@stsm/user/models/user';

interface Form {
  /**
   * @description
   * Between 5 and 20 (included)
   */
  readonly chunkSize: FormControl<number>;
  readonly orderMode: FormControl<FlashcardOrderMode>;
}

@UntilDestroy()
@Component({
  selector: 'app-flashcard-dashboard-settings-modal',
  templateUrl: './flashcard-dashboard-settings-modal.component.html',
  styleUrls: ['./flashcard-dashboard-settings-modal.component.scss'],
  imports: [
    ButtonComponent,
    DialogHeaderComponent,
    FormFieldComponent,
    LabelComponent,
    NgForOf,
    NgIf,
    NumberInputComponent,
    OptionComponent,
    ReactiveFormsModule,
    SelectComponent,
    SelectTriggerDirective,
    TranslatePipe,
    TruncateCenterWithEllipsisPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'flashcard-dashboard-settings-modal',
  },
})
export class FlashcardDashboardSettingsModalComponent implements OnInit {
  @Input() studyset?: Studyset;

  readonly minimumChunkSize: 5 = 5 as const;
  readonly maximumChunkSize: 20 = 20 as const;
  readonly form: FormGroup<Form> = new FormGroup<Form>({
    chunkSize: new FormControl<number>(15, {
      nonNullable: true,
      validators: [
        Validators.min(this.minimumChunkSize),
        Validators.max(this.maximumChunkSize),
        Validators.required,
        Validators.pattern(/^\d{1,2}$/),
      ],
    }),
    orderMode: new FormControl<FlashcardOrderMode>(FlashcardOrderMode.RANDOM, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  protected readonly orderOptions: FlashcardOrderMode[] = [FlashcardOrderMode.RANDOM, FlashcardOrderMode.CREATED];
  protected readonly trackByDefault: TrackByFunction<string> = trackByDefault;

  private _originalChunkSize: number = 15;
  private _originalOrderMode: FlashcardOrderMode = FlashcardOrderMode.RANDOM;

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _spacedRepetitionService: SpacedRepetitionService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _toastService: ToastService,
    private readonly _spacedRepetitionSettingsService: SpacedRepetitionSettingsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _loggerService: LoggerService,
    private readonly _sentryService: SentryService,
  ) {}

  ngOnInit(): void {
    combineLatest([this._spacedRepetitionSettingsService.getChunkSize(), this._spacedRepetitionSettingsService.getOrderMode()])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([chunkSize, orderMode]: [number, string]) => {
        this._originalChunkSize = chunkSize;
        this.form.controls.chunkSize.setValue(this._originalChunkSize);
        this._originalOrderMode = FlashcardOrderModeUtil.from(orderMode);
        this.form.controls.orderMode.setValue(this._originalOrderMode);

        this._changeDetectorRef.markForCheck();
      });
  }

  cancel(): void {
    this._loggerService.debug('cancel()');

    this._closeDialog();
  }

  submit(): void {
    this._loggerService.debug('submit()');

    this.form.disable();
    this._save()
      .pipe(
        finalize((): void => {
          this._closeDialog();
          this.form.enable();
          this._changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  async pauseSpacedRepetition(): Promise<void> {
    const isConfirmed: boolean = await this._confirmPauseSpacedRepetition();

    if (isConfirmed) {
      await this._spacedRepetitionService.pauseSpacedRepetition(this.studyset);
      this._closeDialog();
    }
  }

  private _save(): Observable<User> {
    this._loggerService.debug('_save()');

    const chunkSize: number = clone(this.form.controls.chunkSize.value);
    const orderMode: FlashcardOrderMode = clone(this.form.controls.orderMode.value);

    return this._spacedRepetitionSettingsService
      .patchChunkSizeAndOrderMode(chunkSize, FlashcardOrderModeUtil.toBackendString(orderMode))
      .pipe(
        tap((): void => {
          this._onSaveSuccess(chunkSize, FlashcardOrderModeUtil.toTrackEvent(orderMode, this._originalOrderMode));
        }),
        catchError((error: unknown): never => {
          this._onSaveError(error);

          throw error;
        }),
      );
  }

  private _onSaveSuccess(chunkSize: number, order: string): void {
    this._loggerService.debug('_onSaveSuccess()');

    this._analyticsService.trackEvent({
      action: 'spaced_repetition_settings_interact',
      properties: {
        action: 'change_settings',
        cycleSize: chunkSize,
        cycleSizeBefore: this._originalChunkSize,
        flashcardCount: isNil(this.studyset) ? 0 : SpacedRepetitionUtils.getFlashcardCount(this.studyset),
        flashcardsDueForReview: isNil(this.studyset) ? 0 : SpacedRepetitionUtils.getReviewCount(this.studyset),
        order,
      },
    });

    this._toastService.successToast('FLASHCARD_DASHBOARD.SAVE_SUCCESS_TOAST_MESSAGE');
  }

  private _onSaveError(error: unknown): void {
    this._loggerService.debug('_onSaveError()');

    this._loggerService.error(error);
    this._sentryService.reportToSentry('Flashcard Dashboard settings error', error);
    this._toastService.errorToast('FLASHCARD_DASHBOARD.SAVE_ERROR_TOAST_MESSAGE');
  }

  private async _confirmPauseSpacedRepetition(): Promise<boolean> {
    return new Promise<boolean>((resolve: (value: boolean) => void): void => {
      this._simpleDialogService.confirm({
        heading: 'FLASHCARD_DASHBOARD.AB_SPACED_REPETITION_PAUSE_CONFIRM_TITLE',
        text: 'FLASHCARD_DASHBOARD.SPACED_REPETITION_PAUSE_CONFIRM_TEXT',
        confirmText: 'GLOBAL.YES',
        confirmHandler: () => resolve(true),
        cancelHandler: () => resolve(false),
      });
    });
  }

  private _closeDialog(): void {
    this._loggerService.debug('_closeDialog()');

    this._platformModalService.dismiss();
  }
}
