import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom, Observable } from 'rxjs';

import { ActionId, ActionsHub, ImportFlashcardsActionResponse, ImportFlashcardsActionTrigger } from '@stsm/actions-hub';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { FlashcardImportDialogComponent } from './flashcard-import-dialog.component';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} instead.
 */
@Injectable({ providedIn: 'root' })
export class FlashcardImportDialogService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.IMPORT_FLASHCARDS, ({ studysetId }: ImportFlashcardsActionTrigger) => {
      return firstValueFrom(this._schedule(studysetId));
    });
  }

  init(): void {
    noop();
  }

  private _schedule(studysetId: number): Observable<ImportFlashcardsActionResponse | undefined> {
    return this._platformModalService.schedule<
      InputPropertiesOf<FlashcardImportDialogComponent>,
      ImportFlashcardsActionResponse | undefined
    >({
      component: FlashcardImportDialogComponent,
      data: {
        studysetId,
      },
      webOptions: {
        width: '700px',
        maxWidth: '80vw',
      },
    });
  }
}
