import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, input, InputSignal, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { once } from 'lodash-es';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { ShareDialogOpenSource } from '@stsm/analytics/models/source-property-types';
import { LocaleDateStringPipe } from '@stsm/i18n/global/pipes/locale-date-string.pipe';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { TranslationKey } from '@stsm/i18n/models/translation-key';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { shareIconName } from '@stsm/shared/util/platform-util';
import { IconFontName } from '@stsm/styles/icon-font';
import { BadgeComponent } from '@stsm/ui-components/badge';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { AnimatedIllustrationDirective } from '@stsm/ui-components/lottie-view/animated-illustration.directive';
import { SwipeableTabDirective } from '@stsm/ui-components/tabs/tab-group-swipable/swipeable-tab.directive';
import { TabGroupSwipeableComponent } from '@stsm/ui-components/tabs/tab-group-swipable/tab-group-swipeable.component';
import { TagComponent } from '@stsm/ui-components/tag';
import { InvitedUser } from '@stsm/user/models/invited-user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { SharingBaseService } from '../../services/sharing-base.service';
import { SHARING_SERVICE } from '../../tokens/sharing-service.token';

@UntilDestroy()
@Component({
  selector: 'app-sharing-dialog',
  templateUrl: './sharing-dialog.component.html',
  styleUrls: ['./sharing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AnimatedIllustrationDirective,
    BadgeComponent,
    ButtonComponent,
    IconButtonComponent,
    LocaleDateStringPipe,
    NgTemplateOutlet,
    ReactiveFormsModule,
    SwipeableTabDirective,
    TabGroupSwipeableComponent,
    TagComponent,
    TranslatePipe,
  ],
  host: { ['data-cy']: 'sharing-dialog' },
})
export class SharingDialogComponent {
  readonly source: InputSignal<ShareDialogOpenSource> = input.required();

  readonly hasInviteHistory: InputSignal<boolean> = input(false);

  readonly invitedUsers: InputSignal<InvitedUser[]> = input<InvitedUser[]>([]);

  protected readonly shareIconName: IconFontName = shareIconName;

  protected readonly steps: { icon: string; title: TranslationKey; description: TranslationKey }[] = [
    {
      icon: 'app-icon-link',
      title: 'SHARE.SHARING_DIALOG.STEPS.INVITE_FRIENDS_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.INVITE_FRIENDS_DESC',
    },
    {
      icon: 'app-icon-glasses',
      title: 'SHARE.SHARING_DIALOG.STEPS.FRIEND_STUDIES_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.FRIEND_STUDIES_DESC',
    },
    {
      icon: 'app-icon-logo',
      title: 'SHARE.SHARING_DIALOG.STEPS.FREE_PREMIUM_HEADER',
      description: 'SHARE.SHARING_DIALOG.STEPS.FREE_PREMIUM_DESC',
    },
  ];

  protected readonly inviteStatusTranslationKeys: Record<string, TranslationKey> = {
    pending: 'SHARE.STATUS.PENDING',
    activated: 'SHARE.STATUS.ACTIVATED',
  };

  protected readonly signupsGenerated: Signal<number> = computed(() => {
    return this._userStoreFacade.user()?.signupsGenerated ?? 0;
  });

  protected readonly sharingLink: Signal<string> = computed(() => {
    const url = new URL(`https://${this._targetMarketProvider.resolveWebAppUrl()}/register`);

    url.searchParams.append('web_source', 'activeuserreferral');
    url.searchParams.append('utm_medium', this.isMobileApp ? 'mobileapp' : 'webapp');
    url.searchParams.append('utm_term', 'mailreferral');
    url.searchParams.append('ref', this._userStoreFacade.user()?.refId ?? '');
    url.searchParams.append('ref_type', 'app_share');

    return url.toString();
  });

  protected readonly mailtoHref: Signal<string> = computed(() => {
    const subject = this._translationService.get('SHARE.SHARING_DIALOG.APP_TITLE_VARIANT');
    const body = this._translationService.get('SHARE.SHARING_DIALOG.APP_TEXT_VARIANT', {
      link: this.sharingLink(),
      name: this._userStoreFacade.user()?.appUser.firstName ?? '',
    });

    return `mailto:...?subject=${subject}&body=${encodeURIComponent(body)}`;
  });

  protected readonly trackInviteHistoryClick: VoidFunction = once(() => {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'user_select',
        source: this.source(),
      },
    });
  });

  protected dateFormat: Intl.DateTimeFormatOptions = { dateStyle: 'short' };

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(SHARING_SERVICE) private readonly _sharingService: SharingBaseService,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _targetMarketProvider: TargetMarketProvider,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _translationService: TranslationService,
    private readonly _toastService: ToastService,
    private readonly _dialogRef: DialogRef,
  ) {}

  protected onClose(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'close',
        source: this.source(),
      },
    });

    this._dialogRef.dismiss();
  }

  protected copyShareLink(): void {
    void navigator.clipboard.writeText(this.sharingLink());
    this._toastService.successToast('SHARE.LINK_COPIED');
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'copy_link',
        source: this.source(),
      },
    });
  }

  protected shareViaMail(): void {
    this.trackShareViaEmail();
    window.location.href = this.mailtoHref();
  }

  protected trackShareViaEmail(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'share_via_email',
        source: this.source(),
      },
    });
  }

  protected shareApp(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'invite_friends',
        source: this.source(),
      },
    });

    this._sharingService.nativeShare({
      title: this._translationService.get('PROFILE.INVITE_TITLE'),
      text: this._translationService.get('PROFILE.INVITE_MESSAGE'),
      url: this.sharingLink(),
      source: this.source(),
    });
  }

  protected onTabChange(tabId: string): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'tab_change',
        target: tabId,
        source: this.source(),
      },
    });
  }
}
