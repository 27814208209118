import { Pipe, PipeTransform } from '@angular/core';
import { floor, isString } from 'lodash-es';

import { getElapsedTimeInSeconds } from '@stsm/date/global/functions/get-elapsed-time-in-seconds';
import { createSafeDate } from '@stsm/date/global/functions/safe-date';
import { DurationInSeconds } from '@stsm/date/models/duration-in-seconds';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';

@Pipe({
  name: 'elapsedDuration',
})
export class ElapsedDurationPipe implements PipeTransform {
  constructor(private readonly _translationService: TranslationService) {}

  /**
   * Get a localized string
   *
   * @param {string} date - key for calculating time
   */
  transform(date: string | Date): string {
    const compareDate: Date = isString(date) ? createSafeDate(date) : date;
    const elapsedTime = getElapsedTimeInSeconds(compareDate);

    if (elapsedTime >= DurationInSeconds.WEEK) {
      return this._translationService.get('GLOBAL.ELAPSED_TIME.WEEKS');
    } else if (elapsedTime >= DurationInSeconds.DAY) {
      const days: number = floor(elapsedTime / DurationInSeconds.DAY);

      return this._translationService.get('GLOBAL.ELAPSED_TIME.DAY', { count: days });
    } else if (elapsedTime >= DurationInSeconds.HOUR) {
      const hours: number = floor(elapsedTime / DurationInSeconds.HOUR);

      return this._translationService.get('GLOBAL.ELAPSED_TIME.HOUR', { count: hours });
    } else if (elapsedTime >= DurationInSeconds.MINUTE) {
      const minutes: number = floor(elapsedTime / DurationInSeconds.MINUTE);

      return this._translationService.get('GLOBAL.ELAPSED_TIME.MINUTE', { count: minutes });
    } else {
      return this._translationService.get('GLOBAL.ELAPSED_TIME.SECONDS');
    }
  }
}
