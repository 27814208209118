import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import {
  catchError,
  combineLatest,
  debounceTime,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  interval,
  map,
  Observable,
  pipe,
  retry,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import { Ad, ADS_AUTO_REFRESH_INTERVAL } from '@stsm/advertisement/models/ad';
import { AdGroup, getAdGroupsForContainerHeight, getPreferredAdOrientationCombinationInfo } from '@stsm/advertisement/util/ad-sidebar-util';
import { insertPremiumAds } from '@stsm/advertisement/util/premium-ads-util';
import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { VariantData } from '@stsm/analytics/models/variant-data';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { filterForValueTransition, filterUndefined, latestWhen, switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';

import { AdsService } from './ads.service';
import { AdsStatusService } from './ads-status.service';

interface SidebarAdsState {
  adGroups: AdGroup[];
  currentAdGroupIndex: number;
  containerHeight: number | undefined;
  adsLoaded: boolean;
  didErrorWithOptions: FetchAdsOptions | undefined;
}

const INITIAL_AD_GROUP_INDEX: number = 0;

const initialState: SidebarAdsState = {
  adGroups: [],
  currentAdGroupIndex: INITIAL_AD_GROUP_INDEX,
  containerHeight: undefined,
  adsLoaded: false,
  didErrorWithOptions: undefined,
};

interface FetchAdsOptions {
  shouldResetAdGroups: boolean;
}

@Injectable()
export class SidebarAdsStore extends ComponentStore<SidebarAdsState> {
  readonly areAdsAvailable$: Observable<boolean>;
  readonly currentAdGroup$: Observable<AdGroup | undefined>;

  readonly setContainerHeight: (containerHeight: number | Observable<number>) => void = this.updater(
    (state: SidebarAdsState, containerHeight: number) => ({
      ...state,
      containerHeight,
    }),
  );

  protected readonly isPremiumAdsTreatmentGroup: Signal<boolean> = toSignal(
    defer(() => this._abTestService.getExperimentValue(ExperimentFlag.PREMIUM_ADS)).pipe(
      map(({ value }: VariantData) => value === 'treatment'),
    ),
    { initialValue: false },
  );

  private readonly _currentAdGroupIndex$: Observable<number> = this.select((state: SidebarAdsState) => state.currentAdGroupIndex);
  private readonly _adGroups$: Observable<AdGroup[]> = this.select((state: SidebarAdsState) => state.adGroups);
  private readonly _adsLoaded$: Observable<boolean> = this.select((state: SidebarAdsState) => state.adsLoaded);

  private readonly _containerHeight$: Observable<number> = this.select((state: SidebarAdsState) => state.containerHeight).pipe(
    filterUndefined(),
  );

  private readonly _didErrorWithOptions$: Observable<FetchAdsOptions | undefined> = this.select(
    (state: SidebarAdsState) => state.didErrorWithOptions,
  );

  private readonly _isNextAdGroupAvailable$: Observable<boolean> = this.select(
    this._adGroups$,
    this._currentAdGroupIndex$,
    (adGroups: AdGroup[], currentAdGroupIndex: number) => !isNil(adGroups[currentAdGroupIndex + 1]),
  );

  private readonly _onAdsFetched: (params: { ads: Ad[]; shouldResetAdGroups: boolean }) => void = this.updater(
    (state: SidebarAdsState, params: { ads: Ad[]; shouldResetAdGroups: boolean }) => {
      const { ads, shouldResetAdGroups } = params;
      const adGroups = getAdGroupsForContainerHeight(ads, state.containerHeight);

      return {
        ...state,
        adsLoaded: true,
        didErrorWithOptions: undefined,
        adGroups: shouldResetAdGroups ? adGroups : [...state.adGroups, ...adGroups],
        currentAdGroupIndex: shouldResetAdGroups ? INITIAL_AD_GROUP_INDEX : state.currentAdGroupIndex,
      };
    },
  );

  constructor(
    private readonly _adsService: AdsService,
    private readonly _loggerService: LoggerService,
    private readonly _adsStatusService: AdsStatusService,
    private readonly _layoutStore: LayoutStore,
    private readonly _appActiveService: AppActiveService,
    private readonly _abTestService: AbTestService,
  ) {
    super(initialState);

    this.currentAdGroup$ = this.select(
      this._adGroups$,
      this._currentAdGroupIndex$,
      this._adsStatusService.adsDisabled$,
      (adGroups: AdGroup[], currentAdGroupIndex: number, areAdsDisabled: boolean) =>
        areAdsDisabled ? undefined : adGroups[currentAdGroupIndex],
    );

    this.areAdsAvailable$ = this._adsLoaded$.pipe(
      filter(Boolean),
      switchMap(() => this.currentAdGroup$),
      map((adGroup: AdGroup | undefined) => !isNil(adGroup)),
    );

    // fetch more ads
    this.effect(
      pipe(
        filterForValueTransition({ from: true, to: false }),
        switchMap(() => this._fetchAds({ shouldResetAdGroups: false })),
      ),
    )(this._isNextAdGroupAvailable$);

    // handle container height changes
    this.effect(
      pipe(
        map((containerHeight: number) => getPreferredAdOrientationCombinationInfo(containerHeight)?.minHeightThreshold),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(() => this._fetchAds({ shouldResetAdGroups: true })),
      ),
    )(this._containerHeight$);

    const isActive$: Observable<boolean> = combineLatest([
      this._layoutStore.canShowSidebarBasedOnBreakpoint$,
      this._layoutStore.isFullPageRouteActive$,
      this._adsStatusService.adsDisabled$,
      this._appActiveService.isAppActive$,
    ]).pipe(
      map(
        ([canShowSidebar, isFullPageRouteActive, areAdsDisabled, isAppActive]: [boolean, boolean, boolean, boolean]) =>
          isAppActive && !areAdsDisabled && canShowSidebar && !isFullPageRouteActive,
      ),
    );

    // auto refresh ads
    this.effect(
      pipe(
        switchMap((isNextAdGroupAvailable: boolean) => {
          if (!isNextAdGroupAvailable) {
            return EMPTY;
          }

          return interval(ADS_AUTO_REFRESH_INTERVAL).pipe(
            latestWhen(isActive$),
            tap(() => {
              this.patchState((state: SidebarAdsState) => ({ currentAdGroupIndex: state.currentAdGroupIndex + 1 }));
            }),
          );
        }),
      ),
    )(this._isNextAdGroupAvailable$);

    // try to recover from an error
    this.effect(
      pipe(
        switchMap((didErrorWithOptions: FetchAdsOptions | undefined) => {
          if (isNil(didErrorWithOptions)) {
            return VOID;
          }

          return timer(10_000).pipe(switchMap(() => this._fetchAds(didErrorWithOptions)));
        }),
      ),
    )(this._didErrorWithOptions$);
  }

  private _fetchAds(options: FetchAdsOptions): Observable<void> {
    const info = getPreferredAdOrientationCombinationInfo(this.state().containerHeight);

    return defer(() => this._adsService.getAds({ preferredMediaOrientationsCombination: info?.combination, source: 'sidebar' })).pipe(
      retry({ count: 1, delay: 2_000, resetOnSuccess: true }),
      map((ads: Ad[]) => (this.isPremiumAdsTreatmentGroup() ? insertPremiumAds(ads) : ads)),
      tap((ads: Ad[]) => this._onAdsFetched({ ads, shouldResetAdGroups: options.shouldResetAdGroups })),
      switchToVoid(),
      catchError((error: unknown) => {
        this._loggerService.warn(error);

        this.patchState({
          adsLoaded: true,
          didErrorWithOptions: options,
        });

        return VOID;
      }),
    );
  }
}
