import { ObjectValue } from '@stsm/shared/types/object-value';

const SHARED_TIME_TRACK_FEATURES = {
  unknown: 'unknown',
  home: 'feed',
  flashcards: 'flashcards',
  exercises: 'exercises',
  quiz: 'quiz',
  summary: 'summary',
  onboarding: 'onboarding',
  search: 'search',
  profile: 'profile',
  spacedRepetition: 'spaced-repetition',
  textbooks: 'textbooks',
  document: 'document',
  explainAi: 'explain-ai',
  flashcardSearchDetails: 'flashcard-search-details',
  library: 'library',
  deals: 'deals',
} as const;

export const MOBILE_TIME_TRACK_FEATURES = {
  ...SHARED_TIME_TRACK_FEATURES,
  studysetDetail: 'subject-detail',
  signup: 'signup',
  flashcardEditor: 'flashcard-editor',
} as const;

export const WEB_TIME_TRACK_FEATURES = {
  ...SHARED_TIME_TRACK_FEATURES,
  code: 'code',
  login: 'login',
  register: 'register',
  standaloneEditor: 'standalone-editor',
  forgotPassword: 'forgot-password',
  studyset: 'subjects',
  studysetSummaryPreview: 'subject-summary-preview',
} as const;

type MobileTimeTrackFeature = ObjectValue<typeof MOBILE_TIME_TRACK_FEATURES>;

type WebTimeTrackFeature = ObjectValue<typeof WEB_TIME_TRACK_FEATURES>;

export type TimeTrackFeature = MobileTimeTrackFeature | WebTimeTrackFeature;
