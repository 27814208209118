import { InjectionToken } from '@angular/core';

import { ShareStudysetBaseService } from './share-studyset-base.service';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to share a studyset / studygroup instead.
 */
export const SHARE_STUDYSET_SERVICE: InjectionToken<ShareStudysetBaseService> = new InjectionToken<ShareStudysetBaseService>(
  'ShareStudysetService',
);
