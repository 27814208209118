import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, Observable, switchMap, tap, throwError } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { FlashcardExcelUploadResponse, FlashcardsBaseService } from '@stsm/flashcards/services/flashcards-base-service';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { handleUploadProgress } from '@stsm/shared/models/file-upload-event';
import { TagContentFilter } from '@stsm/tags/models/tag';
import { TagsBaseService } from '@stsm/tags/services/tags-base.service';
import { TAGS_SERVICE } from '@stsm/tags/services/tokens/tags-service.token';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { FileSelectComponent } from '@stsm/ui-components/file-select';
import { FileUploadDropZoneComponent } from '@stsm/ui-components/file-upload-drop-zone';
import { LoadingService } from '@stsm/ui-components/loading-dialog';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

@UntilDestroy()
@Component({
  selector: 'app-flashcard-import-xls',
  templateUrl: './flashcard-import-xls.component.html',
  styleUrls: ['./flashcard-import-xls.component.scss'],
  imports: [ButtonComponent, FileUploadDropZoneComponent, FileSelectComponent, TranslatePipe, NgIf, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashcardImportXlsComponent {
  @Input({ required: true }) studysetId!: number;

  // Emits whether flashcards have been imported or not
  @Output() readonly closeImport: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected templateLink?: string;

  protected selectedFile: File | undefined = undefined;

  protected readonly acceptedFormat: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  private _user: User | undefined;

  constructor(
    @Inject(FLASHCARDS_SERVICE) private readonly _flashcardsService: FlashcardsBaseService,
    private readonly _translationService: TranslationService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _userStoreFacade: UserStoreFacade,
    @Inject(TAGS_SERVICE) private readonly _tagsService: TagsBaseService,
    private readonly _loadingService: LoadingService,
    private readonly _simpleDialogService: SimpleDialogService,
  ) {
    this._userStoreFacade.user$
      .pipe(
        tap((user: User): void => {
          this._user = user;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  onClose(flashcardsImported: boolean = false): void {
    this.closeImport.emit(flashcardsImported);
  }

  onSelectFiles(fileList: FileList): void {
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
    }
  }

  downloadXlsTemplate(): void {
    this._analyticsService.trackEvent({ action: 'import_flashcards_download_template' });

    if (this._user?.language.startsWith('de')) {
      this.templateLink = 'https://publicfiles.studysmarter.de/templates/karteikarten_import_template.xlsx';
    } else {
      this.templateLink = 'https://publicfiles.studysmarter.de/templates/flashcard_import_template.xlsx';
    }
  }

  onFilesDropped(files: FileList): void {
    if (!files.length) {
      return;
    }

    const file = files[0];

    if (file?.type !== this.acceptedFormat) {
      return;
    }

    this.selectedFile = file;
  }

  removeFile(): void {
    this.selectedFile = undefined;
  }

  uploadXlsFile(): void {
    if (!this.selectedFile) {
      return;
    }

    this._analyticsService.trackEvent({ action: 'import_flashcards_upload_file' });

    this._flashcardsService
      .importFlashcardsViaExcel(this.studysetId, this.selectedFile)
      .pipe(
        handleUploadProgress((progress: number): void =>
          this._loadingService.showLoading(this._translationService.get('IMPORT_FLASHCARDS.UPLOAD.PROGRESS', { progress })),
        ),
        tap((res: FlashcardExcelUploadResponse): void => {
          const importedFlashcardsCount: number = Number(res['flashcards_count']);
          this._analyticsService.trackEvent({
            action: 'import_flashcards_upload_file_complete',
            properties: { lines_ok: importedFlashcardsCount },
          });
        }),
        // New tags can be added during importing flashcards, so we need to update them in tagsStoreFacade
        switchMap((): Observable<void> => this._tagsService.fetchTags(this.studysetId, TagContentFilter.FLASHCARDS)),
        tap((): void => {
          this._loadingService.hideLoading();

          this._simpleDialogService.showAlert({
            text: 'IMPORT_FLASHCARDS.UPLOAD.SUCCESS',
          });

          this.onClose(true);
        }),
        catchError((error: Error): Observable<never> => {
          this._analyticsService.trackEvent({ action: 'import_flashcards_upload_file_error' });
          this._simpleDialogService.showError('IMPORT_FLASHCARDS.UPLOAD.ERROR');
          this._loadingService.hideLoading();

          return throwError(() => error);
        }),
        finalize((): void => this._loadingService.hideLoading()),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
