import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { catchError, finalize, map, Observable, of, switchMap, take } from 'rxjs';

import { ContentDestinationChange } from '@stsm/flashcards/types/content-destination-change';
import { MoveFlashcardsDestination } from '@stsm/flashcards/types/move-flashcards-destination';
import { StudysetSelectionDialogOptions } from '@stsm/flashcards/types/studyset-selection-dialog-options';
import { Studyset } from '@stsm/studysets/models/studyset';
import { getStudysetInfoForStudyset, StudysetInfo } from '@stsm/studysets/models/studyset-info';
import { StudysetSelectionDialogBaseService } from '@stsm/studysets/selection';

@Injectable({ providedIn: 'root' })
export class AddExamDateDialogService extends StudysetSelectionDialogBaseService {
  override openDialog({ trackingSource }: StudysetSelectionDialogOptions): Observable<ContentDestinationChange | undefined> {
    this.loadingService.showLoading();

    return this._getLibraryStudysetsAsStudysetInfos().pipe(
      catchError((error: Error) => this.onStudysetsLoadError(error)),
      switchMap((studysetInfos: StudysetInfo[]) => {
        this.loadingService.hideLoading();

        return this.openStudysetSelectionDialog({
          headerTranslationKey: 'STUDYPLAN.STUDY_SET.PICKER_LABEL',
          showSubsets: false,
          studysetInfos,
          trackingSource,
          contentDestinationSelectionMode: 'examDate',
        });
      }),
      switchMap((destination: MoveFlashcardsDestination | undefined): Observable<ContentDestinationChange | undefined> => {
        if (isNil(destination)) {
          return of(undefined);
        }

        return of({ destination });
      }),
      finalize(() => this.loadingService.hideLoading()),
    );
  }

  private _getLibraryStudysetsAsStudysetInfos(): Observable<StudysetInfo[]> {
    return this.studysetsStoreFacade.libraryStudysets$.pipe(
      take(1),
      map((studysets: Studyset[]): StudysetInfo[] => studysets.map((studyset: Studyset) => getStudysetInfoForStudyset(studyset, []))),
    );
  }
}
