import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { ZendeskArticleType } from '@stsm/global/models/zendesk-article-type';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { InfoCardComponent } from '@stsm/ui-components/info-card';

import { BrowserService } from '../../services/browser.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { FeedbackModalService } from '../feedback-form/feedback-modal.service';

type HelpCenterOption = 'give-feedback' | 'external-help-center' | 'bug-report';

@Component({
  selector: 'app-help-center',
  imports: [DialogTemplateComponent, InfoCardComponent, TranslatePipe],
  templateUrl: './help-center.component.html',
  styleUrl: './help-center.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'data-cy': 'help-center-dialog' },
})
export class HelpCenterComponent {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _dialogRef: DialogRef,
    private readonly _feedbackModalService: FeedbackModalService,
    private readonly _browserService: BrowserService,
    private readonly _errorHandlingService: ErrorHandlingService,
  ) {}

  protected chooseOption(option: HelpCenterOption): void {
    this._dialogRef.dismiss();

    switch (option) {
      case 'give-feedback':
        this._feedbackModalService.showFeedbackModal({ eventSource: 'navbar' });
        break;
      case 'external-help-center':
        this._analyticsService.trackEvent({ action: 'profile_help', properties: { source: 'navbar' } });

        this._browserService.openZendeskHelpLink(ZendeskArticleType.OVERVIEW, () => {
          this._errorHandlingService.handleError({
            messageToDisplay: 'ERROR.ERROR_OPEN_LINK',
          });
        });
        break;
      case 'bug-report':
        this._feedbackModalService.showFeedbackModal({ eventSource: 'navbar', isBugReport: true, subject: '[Bug Report]' });
        break;
      default:
        throw new Error(`Unknown option: ${option}`);
    }
  }
}
