import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SwiperOptions } from 'swiper/types';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { AnimatedIllustrationDirective } from '@stsm/ui-components/lottie-view/animated-illustration.directive';
import { AnimatedIllustrationName } from '@stsm/ui-components/lottie-view/animated-illustration-names';
import { SwiperDirective, SwiperSlideChangeEvent } from '@stsm/ui-components/swiper/swiper.directive';
import { SwiperSlideDirective } from '@stsm/ui-components/swiper/swiper-slide.directive';
import { User } from '@stsm/user/models/user';

import { ThemingStore } from '../../services/theming.store';

export interface WhatsNewSlideData {
  imageSrc?: (isDarkMode: boolean) => string;
  illustrationName?: AnimatedIllustrationName;
  subtitleTranslationKey: string;
  textTranslationKey: string;
  trackingFeature: string;
  linkTranslationKey?: string;
  linkTextTranslationKey?: string;
  showToUser?: (user: User) => boolean;
}

export interface WhatsNewModalProps {
  slides: WhatsNewSlideData[];
  titleTranslationKey?: string;
  confirmButton?: boolean;
  confirmTranslationKey?: string;
  dismissTranslationKey?: string;
}

@Component({
  selector: 'app-whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.scss'],
  imports: [
    AsyncPipe,
    ButtonComponent,
    NgIf,
    TranslatePipe,
    AnimatedIllustrationDirective,
    SwiperDirective,
    SwiperSlideDirective,
    IconButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'data-cy': 'whats-new-modal' },
})
export class WhatsNewModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() slides: WhatsNewSlideData[] = [];
  @Input() titleTranslationKey?: string;
  @Input() confirmButton?: boolean;
  @Input() confirmTranslationKey?: string;
  @Input() dismissTranslationKey?: string;

  @ViewChild(SwiperDirective) swiperDirectiveRef?: SwiperDirective;

  isEnd: boolean = false;

  protected readonly logoSource: LogoSource = LogoSource.HAT_BLACK;

  protected readonly swiperOptions: SwiperOptions = {
    pagination: true,
  };

  private _currentSlide?: WhatsNewSlideData;
  private _isClosed: boolean = false;

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _dialogRef: DialogRef,
    private readonly _themingStore: ThemingStore,
  ) {}

  ngOnInit(): void {
    this.isEnd = this.slides.length === 1;
    this._currentSlide = this.slides[0];
  }

  ngAfterViewInit(): void {
    this.swiperDirectiveRef?.update();
    setTimeout(() => this.swiperDirectiveRef?.update(), 500);
  }

  ngOnDestroy(): void {
    if (!this._isClosed) {
      this._sendEvent('close');
    }
  }

  onActiveIndexChange({ swiper, slideIndex }: SwiperSlideChangeEvent): void {
    this.isEnd = swiper.isEnd;
    this._currentSlide = this.slides[slideIndex];

    const action = swiper.activeIndex > swiper.previousIndex ? 'next' : 'previous';
    this._sendEvent(action);
  }

  resolveImageSource(slide: WhatsNewSlideData): Observable<string | null> {
    return this._themingStore.darkThemeActive$.pipe(map((isDarkMode: boolean) => (slide.imageSrc ? slide.imageSrc(isDarkMode) : null)));
  }

  nextSlide(): void {
    if (this.isEnd) {
      this.close(true);
    } else {
      this.swiperDirectiveRef?.slideNext();
    }
  }

  close(confirm?: boolean): void {
    this._dialogRef.dismiss(confirm);
    this._isClosed = true;

    const action = confirm && this.confirmButton ? 'try' : 'close';
    this._sendEvent(action);
  }

  private _sendEvent(action: 'next' | 'previous' | 'close' | 'try'): void {
    const feature = this._currentSlide?.trackingFeature ?? this.slides.map((slide: WhatsNewSlideData) => slide.trackingFeature).join(', ');
    this._analyticsService.trackEvent({ action: 'receive_release_update', properties: { action, feature } });
  }
}
