import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, Optional, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PermissionState } from '@capacitor/core';
import { Platform } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { isDate, isEqualWith, isNil } from 'lodash-es';
import { debounceTime, defer, exhaustMap, map, Observable, Subject, Subscription, take } from 'rxjs';

import { StudyplanTrackingSource } from '@stsm/analytics/models/source-property-types';
import { getYearsRange } from '@stsm/date/global/functions/get-years-range';
import { createSafeDate } from '@stsm/date/global/functions/safe-date';
import { getPlainDateString } from '@stsm/date/global/util/tagged-date-util';
import { DateLike } from '@stsm/date/models/date-like';
import { ContentDestinationChange } from '@stsm/flashcards/types/content-destination-change';
import { DeviceStore } from '@stsm/global/composite/services/device-store.service';
import { BACK_BUTTON_TAP_PRIORITY, NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NotificationsBaseService } from '@stsm/global/composite/services/notifications-base.service';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { NOTIFICATIONS_SERVICE } from '@stsm/global/composite/tokens/notifications-service.token';
import { NotificationsPermissionState } from '@stsm/global/models/notifications-permission-state';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { PlatformBaseService } from '@stsm/shared/services/platform-base.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { ExtractFormControl } from '@stsm/shared/types/controls-of';
import { CreateEditMode } from '@stsm/shared/types/create-edit-mode';
import { Id } from '@stsm/shared/types/id';
import { StudyplanEvent, StudyplanEventPatch } from '@stsm/studyplan/models/studyplan-event';
import { StudyplanEventsService } from '@stsm/studyplan/services/studyplan-events.service';
import { StudysetInputDirective } from '@stsm/studysets/feature/directives/studyset-input.directive';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DateInputComponent } from '@stsm/ui-components/date-input/date-input.component';
import { DateSelectionObject } from '@stsm/ui-components/date-picker';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService, ToastType } from '@stsm/ui-components/dialogs/toast/toast.service';
import { FormFieldComponent, FormHintComponent, PrefixComponent, SuffixComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { InputDirective } from '@stsm/ui-components/input';
import { SlideToggleComponent } from '@stsm/ui-components/slide-toggle';
import { TimeInputComponent } from '@stsm/ui-components/time-input/time-input.component';

import { AddExamDateDialogService } from '../add-exam-date-dialog.service';

import { WeekdayPickerComponent } from './weekday-picker/weekday-picker.component';

interface StudyplanEventForm {
  name: FormControl<string>;
  studysetId: FormControl<Id | null>;
  date: FormControl<Date | null>;
  isDateUnsure: FormControl<boolean>;
  reminderDays: FormControl<number[]>;
  reminderTime: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-create-edit-studyplan-event',
  templateUrl: './create-edit-studyplan-event.component.html',
  styleUrls: ['./create-edit-studyplan-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    DateInputComponent,
    FormFieldComponent,
    InputDirective,
    LetDirective,
    NgIf,
    ReactiveFormsModule,
    SlideToggleComponent,
    StudysetInputDirective,
    TimeInputComponent,
    TranslatePipe,
    WeekdayPickerComponent,
    DialogTemplateComponent,
    FormHintComponent,
    PrefixComponent,
    SuffixComponent,
    LabelComponent,
  ],
  host: {
    'data-cy': 'create-edit-studyplan-event',
  },
})
export class CreateEditStudyplanEventComponent implements OnDestroy {
  @Input() isNewExam: boolean = false;

  @Input()
  set studyplanEvent(studyplanEvent: StudyplanEvent) {
    if (isNil(studyplanEvent)) {
      return;
    }

    this.mode = CreateEditMode.EDIT;
    this._studyplanEvent = studyplanEvent;
    const { name, date, isDateUnsure, reminderDays = [], reminderTime = '', studysetId = null } = studyplanEvent;
    this.studyplanEventForm.setValue({ name, studysetId, date: createSafeDate(date), reminderDays, reminderTime, isDateUnsure });

    if (!this._initialEvent) {
      this._initialEvent = {
        name,
        studysetId,
        date: createSafeDate(date),
        reminderDays,
        reminderTime: reminderTime ? reminderTime.slice(0, 5) : '',
        isDateUnsure,
      };
    }

    if (reminderDays.length > 0) {
      this.showReminders = true;
    }
  }

  get studyplanEvent(): StudyplanEvent | undefined {
    return this._studyplanEvent;
  }

  @Input()
  set date(dateLike: DateLike | undefined) {
    if (!isNil(dateLike)) {
      this.studyplanEventForm.patchValue({ date: createSafeDate(dateLike) });
    }
  }

  get date(): Date | null {
    return this.studyplanEventForm.getRawValue().date;
  }

  @Input()
  set studyset(studyset: Pick<Studyset, 'id' | 'name'>) {
    if (isNil(studyset)) {
      return;
    }

    const { id, name: studysetName } = studyset;
    let name = this.studyplanEventForm.value.name;

    if (!this.studyplanEventForm.value.name) {
      name = this._translationService.get('STUDYPLAN.CREATE_EDIT.NAME_FROM_EXAM', { name: studysetName });
    }

    this.studyplanEventForm.patchValue({ studysetId: id, name });
  }

  @Input({ required: true }) source!: StudyplanTrackingSource;

  protected get isDateUnsure(): boolean {
    return this.studyplanEventForm.getRawValue().isDateUnsure;
  }

  protected get reminderDays(): number[] {
    return this.studyplanEventForm.getRawValue().reminderDays;
  }

  protected set reminderTime(reminderTime: string) {
    this.studyplanEventForm.patchValue({ reminderTime });
  }

  protected get reminderTime(): string {
    return this.studyplanEventForm.getRawValue().reminderTime;
  }

  protected dateInputColor: Signal<'primary' | 'dark'> = toSignal(
    this._themingStore.darkThemeActive$.pipe(map((isDarkThemeActive: boolean) => (isDarkThemeActive ? 'primary' : 'dark'))),
    { initialValue: 'dark' },
  );

  protected readonly studyplanEventForm: FormGroup<StudyplanEventForm>;

  protected readonly yearValues: number[] = getYearsRange({ yearsIntoPast: 2, yearsIntoFuture: 2 });

  protected notificationsPermissionState: NotificationsPermissionState | undefined;
  protected notificationsEnabled$: Observable<boolean> = this._deviceStore.notificationsEnabled$;

  protected mode: CreateEditMode = CreateEditMode.CREATE;
  protected showReminders: boolean = false;

  private readonly _backButtonSubscription: Subscription | undefined;
  private _studyplanEvent: StudyplanEvent | undefined;

  private readonly _enableNotificationRequest$: Subject<void> = new Subject<void>();
  private _initialEvent: ExtractFormControl<StudyplanEventForm> | undefined;

  private _isNewStudyset: boolean = false;

  constructor(
    @Inject(IS_MOBILE_APP) protected readonly isMobile: boolean,
    @Inject(PLATFORM_SERVICE) private readonly _platformService: PlatformBaseService,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Optional() @Inject(NOTIFICATIONS_SERVICE) private readonly _notificationsService: NotificationsBaseService,

    private readonly _toastService: ToastService,
    private readonly _addExamDateDialogService: AddExamDateDialogService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _deviceStore: DeviceStore,
    private readonly _loggerService: LoggerService,
    private readonly _platform: Platform,
    private readonly _platformModalService: PlatformModalService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _studyplanEventService: StudyplanEventsService,
    private readonly _translationService: TranslationService,
    private readonly _themingStore: ThemingStore,
  ) {
    this.studyplanEventForm = new FormGroup<StudyplanEventForm>({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(/\S/)],
      }),
      studysetId: new FormControl<Id | null>(null, Validators.required),
      date: new FormControl<Date | null>(null, {
        validators: [Validators.required],
      }),
      isDateUnsure: new FormControl<boolean>(false, { nonNullable: true }),
      reminderDays: new FormControl<number[]>([], { nonNullable: true }),
      reminderTime: new FormControl<string>('', { nonNullable: true }),
    });

    if (this.isMobile) {
      void this._checkPermissions();

      this._platformService
        .resumed$()
        .pipe(untilDestroyed(this))
        .subscribe(() => void this._checkPermissions());

      this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(BACK_BUTTON_TAP_PRIORITY, () => this.close());

      this._enableNotificationRequest$
        .pipe(
          debounceTime(500),
          exhaustMap(() => {
            return this._notificationsService.enableDeviceNotifications(true, {
              referToSystemSettings: true,
            });
          }),
          untilDestroyed(this),
        )
        .subscribe((notificationPermissionState: PermissionState | undefined) => {
          this.notificationsPermissionState = notificationPermissionState;
        });
    }
  }

  ngOnDestroy(): void {
    this._backButtonSubscription?.unsubscribe();
  }

  protected toggleReminders(): void {
    this.showReminders = !this.showReminders;
  }

  protected close(studyplanEvent?: StudyplanEvent, studysetId?: number): void {
    if (this.isNewExam && studysetId) {
      const messageTranslationKey = this._isNewStudyset ? 'FEED.EXAM_PLUS_STUDYSET_SUCCESSFULLY_CREATED' : 'FEED.EXAM_SUCCESSFULLY_CREATED';
      this._toastService.showToast({
        duration: 3000,
        toastType: ToastType.SUCCESS,
        message: this._translationService.get(messageTranslationKey),
        actions: [
          {
            label: 'STUDYPLAN.SHOW_STUDYSET',
            callback: (): void => {
              void this._navigationService
                .navigateToLibrary()
                .then(() => this._navigationService.navigateForwardToStudyset(studysetId, 'exam_created_toast_message'));
            },
          },
        ],
      });
    }

    this._platformModalService.dismiss(studyplanEvent);
  }

  protected onSelectStudySet(): void {
    this._addExamDateDialogService
      .openDialog({
        trackingSource: this.source,
      })
      .pipe(untilDestroyed(this))
      .subscribe((result: ContentDestinationChange | undefined) => {
        if (isNil(result) || isNil(result.destination)) {
          return;
        }

        const { name, studysetId, isNew } = result.destination;
        this._isNewStudyset = isNew ?? false;
        this.studyset = { id: studysetId, name };
        this._changeDetectorRef.markForCheck();
      });
  }

  protected selectDate(dateSelection: DateSelectionObject): void {
    this.studyplanEventForm.patchValue({ date: createSafeDate(dateSelection.date), isDateUnsure: dateSelection.isDateUnsure });
  }

  protected enableNotifications(): void {
    // At this point, the user has definitely been prompted to receive notifications
    if (this.notificationsPermissionState !== 'granted') {
      this.studyplanEventForm.patchValue({ reminderDays: [] });
    }

    // By clicking a reminder day, the user implicitly declared their intent to receive notifications
    this._browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.HAS_USER_DECLINED_NOTIFICATIONS, false);

    if (this._platformService.isDevice) {
      this._enableNotificationRequest$.next();
    }
  }

  protected upsertStudyplanEvent(): void {
    if (!this.showReminders) {
      this.studyplanEventForm.patchValue({ reminderDays: [], reminderTime: '' });
    }

    const { name, date, reminderDays, reminderTime, isDateUnsure } = this.studyplanEventForm.getRawValue();

    if (isNil(date)) {
      return;
    }

    const studysetId = this.studyplanEventForm.getRawValue().studysetId ?? undefined;

    if (this._isEventUnchanged()) {
      this.close(this.studyplanEvent, studysetId);

      return;
    }

    const studyplanEventPatch: StudyplanEventPatch = {
      ...(this.isNewExam ? { type: 'studyset_exam_date' } : {}),
      name,
      date: getPlainDateString(date),
      isDateUnsure,
      reminderDays,
      reminderTime,
      studysetId,
    };

    defer((): Observable<StudyplanEvent> => {
      return this.mode === CreateEditMode.EDIT && !isNil(this._studyplanEvent) && !isNil(this._studyplanEvent.id)
        ? this._studyplanEventService.updateStudyplanEvent(this._studyplanEvent.id, studyplanEventPatch, this.source)
        : this._studyplanEventService.createStudyplanEvent(studyplanEventPatch, this.source);
    })
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (updatedEvent: StudyplanEvent) => this.close(updatedEvent, studysetId),
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._simpleDialogService.showError(
            this.mode === CreateEditMode.EDIT ? 'STUDYPLAN.CREATE_EDIT.ERROR_EDIT' : 'STUDYPLAN.CREATE_EDIT.ERROR_CREATE',
          );
        },
      });
  }

  private _isEventUnchanged(): boolean {
    return isEqualWith(
      this._initialEvent,
      this.studyplanEventForm.value,
      (initialValue: unknown, formValue: unknown): boolean | undefined => {
        return isDate(initialValue) && isDate(formValue) ? getPlainDateString(initialValue) === getPlainDateString(formValue) : undefined;
      },
    );
  }

  private async _checkPermissions(): Promise<void> {
    if (!this._platformService.isDevice) {
      this.notificationsPermissionState = 'granted';

      return;
    }

    this.notificationsPermissionState = await this._notificationsService.checkPermissions();
  }
}
