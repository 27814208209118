import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

import { Id } from '@stsm/shared/types/id';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';

@Directive({
  selector: '[appStudysetInput]',
})
export class StudysetInputDirective implements OnInit {
  constructor(
    private readonly _ngControl: NgControl,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
  ) {}

  ngOnInit(): void {
    const initialStudysetId: Id = this._ngControl.control?.value;
    this._setStudysetNameById(initialStudysetId);

    this._ngControl.control?.valueChanges.subscribe((studysetId: Id) => {
      this._setStudysetNameById(studysetId);
    });
  }

  private _setStudysetNameById(studysetId: Id | undefined): void {
    if (!studysetId) {
      this._ngControl.valueAccessor?.writeValue('');

      return;
    }

    void firstValueFrom(this._studysetsStoreFacade.studysetById(studysetId)).then((studyset: Studyset) => {
      this._ngControl.valueAccessor?.writeValue(studyset?.name ?? studysetId);
    });
  }
}
