import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Timeslot } from '@stsm/analytics/models/time-slot';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { BaseService } from '@stsm/shared/services/base.service';
import { JsonObject } from '@stsm/shared/types/json-object';

@Injectable({
  providedIn: 'root',
})
export class TimeslotService {
  constructor(
    private readonly _authStore: AuthStore,
    private readonly _baseService: BaseService,
  ) {}

  postTimeslot({ startTime, endTime, feature, isMobile, studysetId }: Timeslot): Observable<number> {
    const url = studysetId ? `studysets/${studysetId}/timeslots/` : `users/${this._authStore.userId}/timeslots/`;
    const body = JSON.stringify({
      start_time: startTime,
      end_time: endTime,
      feature,
      is_mobile: isMobile,
    });

    return this._baseService.post(url, body).pipe(map((res: JsonObject) => res['seconds_total'] as number));
  }
}
