import { Inject, Injectable } from '@angular/core';
import { isNil, noop } from 'lodash-es';
import { firstValueFrom, Observable, of } from 'rxjs';

import { ActionId, ActionsHub, CreateEditStudysetActionTrigger } from '@stsm/actions-hub';
import { StudysetCrudTrackingSource } from '@stsm/analytics/models/source-property-types';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { PremiumFeature } from '@stsm/premium/models/premium-feature';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { ContentDestinationSelectionMode } from '@stsm/studysets/models/content-destination-selection-mode';
import { Studyset } from '@stsm/studysets/models/studyset';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { CREATE_EDIT_STUDYSET_DIALOG_ID, CreateEditStudysetDialogComponent } from './create-edit-studyset-dialog.component';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to create / edit a studyset instead.
 */
@Injectable({ providedIn: 'root' })
export class CreateEditStudysetDialogService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
    private readonly _premiumLimitsService: PremiumLimitsService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(
      ActionId.CREATE_EDIT_STUDYSET,
      async (params: CreateEditStudysetActionTrigger): Promise<Studyset | undefined> => {
        const studyset = await firstValueFrom(this._openDialog(params));

        if (!isNil(studyset) && !isNil(params.studysetOpenTrackingSource)) {
          void this._navigationService.navigateForwardToStudyset(studyset.id, params.studysetOpenTrackingSource);
        }

        return studyset;
      },
    );
  }

  init(): void {
    noop();
  }

  private _openDialog({
    studyset,
    contentDestinationSelectionMode,
    trackingSource,
  }: {
    studyset?: Studyset;
    contentDestinationSelectionMode?: ContentDestinationSelectionMode;
    trackingSource: StudysetCrudTrackingSource;
  }): Observable<Studyset | undefined> {
    const isCreationMode = isNil(studyset);

    if (isCreationMode && this._premiumLimitsService.hasReachedStudysetCreationPremiumLimit()) {
      void this._actionsHub.triggerAction(ActionId.OPEN_PREMIUM_DIALOG, {
        source: 'studyset_creation_limit',
        highlightedFeature: PremiumFeature.UNLIMITED_STUDYSETS,
      });

      return of(undefined);
    }

    const dialogRef: DialogRef<CreateEditStudysetDialogComponent, Studyset | undefined> = this._platformModalService.create({
      id: CREATE_EDIT_STUDYSET_DIALOG_ID,
      component: CreateEditStudysetDialogComponent,
      data: <InputPropertiesOf<CreateEditStudysetDialogComponent>>{
        studyset,
        contentDestinationSelectionMode,
        trackingSource,
      },
      webOptions: {
        width: 'min(480px, 80vw)',
      },
      autoFocus: !this._isMobileApp,
    });

    return dialogRef.afterClosed();
  }
}
