import { head, isNil } from 'lodash-es';

import { Ad } from '@stsm/advertisement/models/ad';
import { MediaOrientation } from '@stsm/advertisement/models/media-orientation';

import { AvailableAdMediaSourceInfoMap, getAvailableAdMediaSourceInfosByOrientation } from './ad-media-orientation-priority-util';

export const adContainerMinHeightThresholds = [904, 778, 680, 512, 428, 0] as const;

type AdsContainerMinHeightThreshold = (typeof adContainerMinHeightThresholds)[number];

interface AdOrientationCombinationInfo {
  minHeightThreshold: AdsContainerMinHeightThreshold;
  combination: MediaOrientation[];
}

const SIDEBAR_AD_ORIENTATION_COMBINATIONS: AdOrientationCombinationInfo[] = [
  { minHeightThreshold: 904, combination: ['skyscraper'] }, // 16:60
  { minHeightThreshold: 778, combination: ['portrait', 'square'] }, // 9:16 + 1:1
  { minHeightThreshold: 680, combination: ['portrait', 'landscape'] }, // 9:16 + 16:9
  { minHeightThreshold: 512, combination: ['square', 'square'] }, // 1:1 + 1:1
  { minHeightThreshold: 428, combination: ['portrait'] }, // 9:16
  { minHeightThreshold: 0, combination: ['landscape', 'landscape'] }, // 16:9 + 16:9
];

export type AdGroup = { ad: Ad; orientation: MediaOrientation }[];

/**
 * returns the preferred combination of media orientations for the given container height
 *
 * @param containerHeight the height of the ads sidebar
 */
export function getPreferredAdOrientationCombinationInfo(containerHeight: number = 0): AdOrientationCombinationInfo | undefined {
  return head(
    SIDEBAR_AD_ORIENTATION_COMBINATIONS.filter((item: AdOrientationCombinationInfo) => containerHeight >= item.minHeightThreshold),
  );
}

/**
 * groups the given ads based on the preferred combinations of media orientations for the given container height.
 * It is not guaranteed that every ad is used.
 *
 * @param allAds the ads received from the backend
 * @param containerHeight the height of the ads sidebar
 */
export function getAdGroupsForContainerHeight(allAds: Ad[], containerHeight: number = 0): AdGroup[] {
  if (allAds.length === 0) {
    return [];
  }

  const combinations = getMediaOrientationCombinations(containerHeight);

  const usedAdIndices = new Set<number>();

  const adGroups = combinations.reduce((adGroups: AdGroup[], combination: MediaOrientation[]) => {
    const availableAdGroupsForCombination = getAllAvailableAdGroupsForCombination(allAds, combination, usedAdIndices);

    return [...adGroups, ...availableAdGroupsForCombination];
  }, []);

  // Usually, the ad group [landscape, landscape] should always be available.
  // The only exception would be if the backend only returns one ad.
  if (adGroups.length === 0 && !isNil(allAds[0])) {
    // We know that there is at least one ad
    return [getFallbackAdGroup(allAds[0])];
  }

  return adGroups;
}

/**
 * returns a fallback AdGroup from the passed ad
 *
 * @param ad the ad
 */
function getFallbackAdGroup(ad: Ad): AdGroup {
  const availableOrientations = getAvailableAdMediaSourceInfosByOrientation(ad);

  if (Object.keys(availableOrientations).length === 0) {
    console.error('No available orientation!');

    return [];
  }

  // square should always be available
  const orientation = !isNil(availableOrientations.square) ? 'square' : (head(Object.keys(availableOrientations)) as MediaOrientation);

  return [{ ad, orientation }];
}

/**
 * returns a prioritized list of combinations for the given containerHeight
 *
 * @param containerHeight the container height
 */
function getMediaOrientationCombinations(containerHeight: number = 0): MediaOrientation[][] {
  return SIDEBAR_AD_ORIENTATION_COMBINATIONS.filter((item: AdOrientationCombinationInfo) => containerHeight >= item.minHeightThreshold).map(
    (item: AdOrientationCombinationInfo) => item.combination,
  );
}

/**
 * returns as many ad groups for the given combination as possible using the passed ads array
 *
 * @param ads the ads
 * @param combination the combination for the resulting ad groups
 * @param usedAdIndices the used ad indices
 */
function getAllAvailableAdGroupsForCombination(ads: Ad[], combination: MediaOrientation[], usedAdIndices: Set<number>): AdGroup[] {
  const availableAdGroups: AdGroup[] = [];
  let adGroup: AdGroup = [];

  do {
    adGroup = getAdGroupForCombination(ads, combination, usedAdIndices);

    if (adGroup.length > 0) {
      availableAdGroups.push(adGroup);
    }
  } while (adGroup.length > 0);

  return availableAdGroups;
}

/**
 * tries to return an adgroup that fulfills the given combination of media orientation. If the combination cannot be
 * fulfilled, an empty AdGroup is returned.
 *
 * The passed {@param usedAdIndices} set is added to if the combination could be fulfilled (SIDE EFFECT!)
 *
 * @param ads ads
 * @param combination the combination to fulfill
 * @param usedAdIndices the used indices from the ads array
 */
function getAdGroupForCombination(ads: Ad[], combination: MediaOrientation[], usedAdIndices: Set<number>): AdGroup {
  const availableSourceInfoMap = ads.map((ad: Ad) => getAvailableAdMediaSourceInfosByOrientation(ad));

  const adGroup: AdGroup = [];

  // We need to make sure that the full combination can be fulfilled before we can add the newly used indices to the passed set.
  // Thus, we need a local copy of the set
  const tempUsedIndices = new Set([...usedAdIndices]);

  for (const orientation of combination) {
    // find ad where this orientation is available which has not been used
    const index = availableSourceInfoMap.findIndex(
      (sourceInfoMap: AvailableAdMediaSourceInfoMap, index: number) => !tempUsedIndices.has(index) && !isNil(sourceInfoMap[orientation]),
    );

    const ad = ads[index];

    if (!isNil(ad)) {
      adGroup.push({ ad, orientation });
      tempUsedIndices.add(index);
    }
  }

  // combination could be fulfilled
  if (adGroup.length === combination.length) {
    tempUsedIndices.forEach((usedIndex: number) => usedAdIndices.add(usedIndex));

    return adGroup;
  }

  return [];
}
