import { SpacedRepetitionAction } from '@stsm/flashcards/types/spaced-repetition';

export enum LearnContextMenuSelection {
  PRACTICE = 'practice',
  QUIZ = 'quiz',
  SPACED_REPETITION = 'spaced_repetition',
  ORAL_QUIZ = 'oral_quiz',
}

export type LearnContextMenuSelectionData =
  | { selection: Exclude<LearnContextMenuSelection, LearnContextMenuSelection.SPACED_REPETITION> }
  | {
      selection: LearnContextMenuSelection.SPACED_REPETITION;
      action: SpacedRepetitionAction;
    };
