import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom, tap } from 'rxjs';

import { TimeslotService } from '@stsm/analytics/global/services/timeslot.service';
import { getElapsedTimeInSeconds } from '@stsm/date/global/functions/get-elapsed-time-in-seconds';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { TimeTrackingBaseService, TrackingData } from '@stsm/global/composite/services/time-tracking-base.service';

@Injectable({
  providedIn: 'root',
})
export class TimeTrackingService extends TimeTrackingBaseService {
  constructor(
    private readonly _appActiveService: AppActiveService,
    private readonly _timeslotService: TimeslotService,
  ) {
    super();

    this._appActiveService.isAppActive$
      .pipe(
        tap((isAppActive: boolean) => {
          this.trackingActive$.next(isAppActive);
        }),
      )
      .subscribe();
  }

  init(): void {
    noop();
  }

  override postTimeslot(trackingData: TrackingData): Promise<number | undefined> {
    const { startTime, feature, studysetId } = trackingData;

    this.loggerService.debug(`#TT post timeslot: ${getElapsedTimeInSeconds(startTime)} feature ${feature} | studysetId ${studysetId}`);

    return firstValueFrom(
      this._timeslotService.postTimeslot({
        ...trackingData,
        endTime: new Date(),
        isMobile: false,
      }),
    );
  }
}
