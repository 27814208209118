<ui-dialog-header showCloseButton>
  <div class="header-wrapper">
    <h3 class="header">{{ "IMPORT_FLASHCARDS.HEADER" | translate }}</h3>
    <button *ngIf="showHelp()" uiIconButton tertiary (click)="onShowHelp()">
      <i class="app-icon-help"></i>
    </button>
  </div>
</ui-dialog-header>

<ui-tab-group data-cy="flashcard-import-dialog-tab-group">
  <ui-tab data-cy="flashcard-import-dialog-quizlet-tab" [label]="'IMPORT_FLASHCARDS.AS_TEXT_TAB' | translate">
    <app-flashcard-import-as-text [studysetId]="studysetId" (closeRequest)="close($event)" />
  </ui-tab>
  <ui-tab data-cy="flashcard-import-dialog-xls-tab" [label]="'IMPORT_FLASHCARDS.AS_XLSX_TAB' | translate">
    <app-flashcard-import-xls [studysetId]="studysetId" (closeImport)="close($event)" />
  </ui-tab>
</ui-tab-group>
