import { Inject, Injectable } from '@angular/core';
import { filter, Subject, switchMap, takeWhile } from 'rxjs';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { Ad } from '@stsm/advertisement/models/ad';
import { AdClickEvent } from '@stsm/advertisement/models/ad-click-event';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { getElapsedTimeInSeconds } from '@stsm/date/global/functions/get-elapsed-time-in-seconds';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { tapOnce } from '@stsm/shared/util/rxjs.util';

import { AdLinkOpeningBaseService } from './ad-link-opening-base.service';

interface LastOpenedAdInfo {
  ad: Ad;
  location: string;
}

@Injectable({ providedIn: 'root' })
export class AdLinkOpeningWebService extends AdLinkOpeningBaseService {
  private readonly _lastOpenedAd$: Subject<LastOpenedAdInfo> = new Subject<LastOpenedAdInfo>();

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _appActiveService: AppActiveService,
    private readonly _actionsHub: ActionsHub,
  ) {
    super();

    this._lastOpenedAd$
      .pipe(
        switchMap(({ ad, location }: LastOpenedAdInfo) => {
          let hasLeftApp = false;
          const startDate = new Date();

          return this._appActiveService.isAppActive$.pipe(
            tapOnce(() => (hasLeftApp = true), { withCondition: (isAppActive: boolean) => !isAppActive }),
            filter(Boolean),
            tapOnce(
              () => {
                this._analyticsService.trackEvent({
                  action: 'offer_return_to_app',
                  properties: {
                    campaign_name: ad.campaignName,
                    duration: getElapsedTimeInSeconds(startDate),
                    location,
                  },
                });
              },
              { withCondition: () => hasLeftApp },
            ),
            takeWhile(() => !hasLeftApp),
          );
        }),
      )
      .subscribe();
  }

  handleAdClick({ ad, mediaOrientation, location }: AdClickEvent): void {
    if (ad.isLeadGenAd) {
      void this._actionsHub.triggerAction(ActionId.OPEN_LEAD_GEN_AD_DIALOG, { ad });

      return;
    }

    this._lastOpenedAd$.next({ ad, location });

    const link = this.getAdLinkForMediaOrientation(ad, mediaOrientation);

    window.open(link, '_blank');
  }
}
