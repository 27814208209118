import { Ad } from '@stsm/advertisement/models/ad';
import { AdClickEvent } from '@stsm/advertisement/models/ad-click-event';
import { MediaOrientation } from '@stsm/advertisement/models/media-orientation';

export abstract class AdLinkOpeningBaseService {
  protected getAdLinkForMediaOrientation(ad: Ad, mediaOrientation: MediaOrientation | undefined): string {
    // the links might be undefined, null or "" which is why we use || for the fallback to ad.link
    switch (mediaOrientation) {
      case 'landscape':
        return ad.linkLandscape || ad.link;
      case 'square':
        return ad.linkSquare || ad.link;
      case 'portrait':
        return ad.linkPortrait || ad.link;
      case 'skyscraper':
        return ad.linkSkyscraper || ad.link;
      default:
        return ad.link;
    }
  }

  abstract handleAdClick(adClick: AdClickEvent): void;
}
