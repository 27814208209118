import { Injectable, ViewContainerRef } from '@angular/core';
import { isNil } from 'lodash-es';

import { ActionId, ActionsHub, ShowAdImagePixelActionTrigger } from '@stsm/actions-hub';
import { AdPixel } from '@stsm/advertisement/models/ad-pixel';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { randomInt } from '@stsm/shared/util/generic-utils';

import { PixelComponent } from '../components/pixel/pixel.component';

@Injectable({
  providedIn: 'root',
})
export class PixelService {
  private _viewContainerRef: ViewContainerRef | undefined;

  constructor(
    private readonly _loggerService: LoggerService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.SHOW_AD_IMAGE_PIXEL, ({ pixel }: ShowAdImagePixelActionTrigger) => {
      this._showImagePixel(pixel);

      return Promise.resolve();
    });
  }

  /**
   * Registers a ViewContainerRef to which the pixel component will be attached
   *
   * @param viewContainerRef the ViewContainerRef
   */
  registerViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this._viewContainerRef = viewContainerRef;
  }

  /**
   * dynamically creates a {@see PixelComponent} to display the img tag for the given pixelSource.
   * The component is automatically removed from the DOM after 2 seconds
   *
   * @param pixel the pixel
   */
  private _showImagePixel(pixel: AdPixel): void {
    if (pixel.type !== 'image') {
      return;
    }

    if (isNil(this._viewContainerRef)) {
      this._loggerService.error('No ViewContainerRef set for displaying the pixel!');

      return;
    }

    const pixelSource = this._generateImagePixelSource(pixel.source);

    this._loggerService.debug('show image pixel', pixelSource);

    const componentRef = this._viewContainerRef.createComponent(PixelComponent);
    componentRef.setInput('pixelSource', pixelSource);

    setTimeout(() => {
      this._viewContainerRef?.remove(0); // FIFO
    }, 2000);
  }

  private _generateImagePixelSource(pixelSource: string): string {
    const pixelSourceWithCacheBusting = pixelSource
      .replace('[CACHEBUSTER]', `${randomInt(9_000_000) + 1_000_000}`)
      .replace('[timestamp]', `${randomInt(9_000_000) + 1_000_000}`);

    // Take the pixelSource as is if the source contains "doubleclick"
    return pixelSourceWithCacheBusting.includes('doubleclick')
      ? pixelSourceWithCacheBusting
      : pixelSourceWithCacheBusting.replace(/\${gdpr}/gim, 'true').replace(/\${gdpr_consent_\d+}/gim, 'true');
  }
}
