import { Injectable } from '@angular/core';

import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { ReportImportProblemComponent } from './report-import-problem.component';

@Injectable({ providedIn: 'root' })
export class ReportImportProblemService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  showDialog(inputText: string): DialogRef<ReportImportProblemComponent> {
    return this._platformModalService.create({
      component: ReportImportProblemComponent,
      data: {
        content: inputText,
      },
      webOptions: {
        width: 350,
        height: 250,
      },
    });
  }
}
