<ui-dialog-header showCloseButton>
  <h3 class="header">{{ "IMPORT_FLASHCARDS.REPORT_A_PROBLEM" | translate }}</h3>
</ui-dialog-header>

<div class="text-area">
  <textarea [placeholder]="'IMPORT_FLASHCARDS.DESCRIBE_THE_PROBLEM' | translate" [(ngModel)]="reportArea"></textarea>
</div>

<div class="buttons">
  <button class="cancel-btn" uiButton secondary (click)="closeProblemReporting()">
    {{ "GLOBAL.CANCEL" | translate }}
  </button>
  <button uiButton [disabled]="reportArea.length === 0" (click)="submitProblemReport()">
    {{ "IMPORT_FLASHCARDS.SEND" | translate }}
  </button>
</div>
