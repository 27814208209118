import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isNil } from 'lodash-es';

import { ElapsedDurationPipe } from '@stsm/global/composite/pipes/elapsed-duration.pipe';
import { ProgressSection } from '@stsm/global/models/progress-section';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { QuizHistory } from '@stsm/quiz/models/quiz-history';
import { AppColor } from '@stsm/shared/util/color-util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { CircleMultipleProgressbarComponent } from '@stsm/ui-components/circle-multiple-progressbar';
import { DisabledDirective } from '@stsm/ui-components/disabled';
import { InfoCardComponent } from '@stsm/ui-components/info-card';

@Component({
  selector: 'app-ai-learn-mode-widget',
  imports: [
    InfoCardComponent,
    DisabledDirective,
    TranslatePipe,
    NgIf,
    ElapsedDurationPipe,
    NgTemplateOutlet,
    CircleMultipleProgressbarComponent,
  ],
  templateUrl: './ai-learn-mode-widget.component.html',
  styleUrls: ['../learn-mode-widgets.style.scss', './ai-learn-mode-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'ai-learn-mode-widget',
  },
})
export class AiLearnModeWidgetComponent {
  @Input() studyset?: Studyset;
  @Input() isOffline: boolean = false;
  @Input() minimumExamQuantity: number = 3;
  @Input() quizHistory?: QuizHistory;
  @Input() disabled: boolean = false;

  @Output() readonly startExam: EventEmitter<void> = new EventEmitter<void>();

  get hasEnoughFlashcards(): boolean {
    return !isNil(this.studyset) && this.studyset.flashcardCount >= this.minimumExamQuantity;
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.studyset || this.isOffline || !this.hasEnoughFlashcards) {
      return;
    }

    this.startExam.emit();
  }

  get lastQuizProgressSections(): ProgressSection[] {
    if (!this.quizHistory) {
      return [];
    }

    return [
      {
        progress: this.quizHistory.score,
        color: AppColor.ACCENT,
      },
    ];
  }
}
