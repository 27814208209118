import { Routes } from '@angular/router';

import { canDeactivateFnForNavAnimation } from '@stsm/scaffold/router-util/can-deactivate-fn-for-nav-animation';

import { canActivateProfileInfoGuard } from './can-activate-profile-info.guard';
import { unsavedWorkExperienceGuard } from './pages/create-edit-work-experience/unsaved-work-experience.guard';

export const profileRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./profile.component').then((m) => m.ProfileComponent),
    canDeactivate: [canDeactivateFnForNavAnimation],
  },
  {
    path: 'info',
    loadComponent: () =>
      import('./pages/profile-personal-data-page/profile-personal-data-page.component').then((m) => m.ProfilePersonalDataPageComponent),
    canActivate: [canActivateProfileInfoGuard],
  },
  {
    path: 'experience/:experienceId/edit',
    loadComponent: () =>
      import('./pages/create-edit-work-experience/create-edit-work-experience.component').then((m) => m.CreateEditWorkExperienceComponent),
    canDeactivate: [unsavedWorkExperienceGuard],
  },
  {
    path: 'experience',
    loadComponent: () =>
      import('./pages/create-edit-work-experience/create-edit-work-experience.component').then((m) => m.CreateEditWorkExperienceComponent),
    canDeactivate: [unsavedWorkExperienceGuard],
  },
  {
    path: 'qualification/:qualificationId/edit',
    loadComponent: () =>
      import('./pages/create-edit-educational-qualification/create-edit-educational-qualification.component').then(
        (m) => m.CreateEditEducationalQualificationComponent,
      ),
  },
  {
    path: 'qualification',
    loadComponent: () =>
      import('./pages/create-edit-educational-qualification/create-edit-educational-qualification.component').then(
        (m) => m.CreateEditEducationalQualificationComponent,
      ),
  },
  {
    path: 'tongues',
    loadComponent: () =>
      import('./pages/edit-user-tongues-page/edit-user-tongues-page.component').then((m) => m.EditUserTonguesPageComponent),
  },
  {
    path: 'skills',
    loadComponent: () => import('./pages/edit-user-skills-page/edit-user-skills-page.component').then((m) => m.EditUserSkillsPageComponent),
  },
  {
    path: 'settings',
    loadComponent: () => import('./pages/profile-settings/profile-settings.component').then((m) => m.ProfileSettingsComponent),
    canDeactivate: [canDeactivateFnForNavAnimation],
  },
];
