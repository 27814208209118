<ui-dialog-template
  showCloseButton
  [heading]="(isNewExam ? 'STUDYPLAN.CREATE_EDIT.EXAM_HEADING' : 'STUDYPLAN.CREATE_EDIT.HEADING') | translate"
>
  <form slot="content" class="form" [formGroup]="studyplanEventForm" (keydown.enter)="$event.preventDefault()">
    <ui-form-field>
      <label uiLabel required>{{ "GLOBAL.TITLE" | translate }}</label>

      <input
        uiInput
        formControlName="name"
        type="text"
        data-cy="create-edit-studyplan-event-title-input"
        [placeholder]="'STUDYPLAN.CREATE_EDIT.TITLE_INPUT_PLACEHOLDER' | translate"
      />
    </ui-form-field>
    <ui-form-hint *ngIf="studyplanEventForm.controls.name.dirty && studyplanEventForm.controls.name.invalid">
      {{ "GLOBAL.REQUIRED_FIELD" | translate }}
    </ui-form-hint>

    <ui-form-field class="studyset-selection-field">
      <label uiLabel required>{{ "STUDYPLAN.STUDY_SET.LABEL" | translate }}</label>
      <input
        uiInput
        appStudysetInput
        formControlName="studysetId"
        type="text"
        data-cy="create-edit-studyplan-event-studyset-input"
        readonly
        [placeholder]="'STUDYPLAN.STUDY_SET.PLACEHOLDER' | translate"
        (click)="onSelectStudySet()"
      />
      <ui-suffix><i class="app-icon-chevron-forward"></i></ui-suffix>
    </ui-form-field>
    <ui-form-hint *ngIf="!studyplanEventForm.controls.studysetId.untouched && studyplanEventForm.controls.studysetId.invalid">
      {{ "GLOBAL.REQUIRED_FIELD" | translate }}
    </ui-form-hint>

    <label uiLabel id="date-input-label" required>{{ "STUDYPLAN.CREATE_EDIT.DATE_INPUT_LABEL" | translate }}</label>

    <ui-date-input
      aria-labelledby="date-input-label"
      data-cy="create-edit-studyplan-event-date-input"
      modalTitleTranslationKey="STUDYPLAN.DATE_PICKER_MODAL_TITLE"
      minDate="today"
      [color]="dateInputColor()"
      [date]="date"
      [showMonthSelector]="true"
      [canClear]="false"
      [yearValues]="yearValues"
      [placeholderTranslationKey]="'FEED.DATE_PLACEHOLDER'"
      [isDateUnsure]="isDateUnsure"
      (dateChange)="selectDate($event)"
    />
    <ui-form-hint *ngIf="!studyplanEventForm.controls.date.untouched && studyplanEventForm.controls.date.invalid">
      {{ "GLOBAL.REQUIRED_FIELD" | translate }}
    </ui-form-hint>

    <label *ngIf="isMobile" uiLabel class="slide-toggle-wrapper" id="reminder-toggle">
      {{ "FEED.ADD_REMINDERS" | translate }}
      <ui-slide-toggle
        data-cy="create-edit-studyplan-event-reminder-toggle"
        ariaLabelledBy="reminder-toggle"
        small
        [checked]="showReminders"
        (checkedChange)="toggleReminders()"
      />
    </label>

    <div *ngIf="showReminders" class="reminder-controls">
      <label uiLabel id="reminder-day-picker-label" class="reminder-label">
        {{ "STUDYPLAN.CREATE_EDIT.REMINDER_DAY_PICKER_LABEL" | translate }}
      </label>

      <ng-container *ngrxLet="notificationsEnabled$ as notificationsEnabled">
        <app-weekday-picker
          formControlName="reminderDays"
          aria-labelledby="reminder-day-picker-label"
          data-cy="create-edit-studyplan-event-reminder-day-picker"
          [multiple]="true"
          (click)="!notificationsEnabled && enableNotifications()"
        />
      </ng-container>

      <ui-form-field [class.ui-disabled]="!reminderDays.length">
        <label uiLabel id="reminder-time-input-label">{{ "STUDYPLAN.CREATE_EDIT.REMINDER_TIME_LABEL" | translate }}</label>
        <ui-prefix><i class="app-icon-time"></i></ui-prefix>
        <ui-time-input
          aria-labelledby="reminder-time-input-label"
          icon="app-icon-chevron-down"
          data-cy="create-edit-studyplan-event-reminder-time-input"
          [disabled]="!reminderDays.length"
          [(time)]="reminderTime"
        />
        <!-- Absence of uiInput leads to TypeError within FieldComponent -->
        <input uiInput type="hidden" />
      </ui-form-field>
    </div>
  </form>

  <button
    slot="footer"
    uiButton
    type="submit"
    class="submit"
    data-cy="create-edit-studyplan-event-submit-button"
    [disabled]="studyplanEventForm.invalid"
    (click)="upsertStudyplanEvent()"
  >
    {{ "GLOBAL.DONE" | translate }}
  </button>
</ui-dialog-template>
