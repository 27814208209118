import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, InputSignal, OnInit, Output, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  FlashcardGenerationFormat,
  FlashcardGenerationType,
  FlashcardSettingsValues,
} from '@stsm/ai-generation/types/flashcard-generation';
import { AvailableLanguage } from '@stsm/global/models/available-language';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { getBaseLanguage, SupportedBaseLanguage } from '@stsm/i18n/models/supported-language';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { FormFieldComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { LanguageInputComponent } from '@stsm/ui-components/language-input/language-input.component';
import { OptionComponent } from '@stsm/ui-components/option';
import { SelectComponent } from '@stsm/ui-components/select';
import { ToggleButtonComponent, ToggleButtonGroupComponent } from '@stsm/ui-components/toggle-button-group';

export interface FlashcardSettingsControls {
  readonly type: FormControl<FlashcardGenerationType>;
  readonly format: FormControl<FlashcardGenerationFormat>;
  readonly language: FormControl<string>;
  readonly language2: FormControl<string>;
  readonly instructions: FormControl<string>;
}

interface FlashcardFormat {
  readonly option: string;
  readonly value: FlashcardGenerationFormat;
}

interface FlashcardType {
  readonly option: string;
  readonly value: FlashcardGenerationType;
}

@UntilDestroy()
@Component({
  selector: 'app-generate-flashcards-settings-form',
  imports: [
    ReactiveFormsModule,
    SelectComponent,
    OptionComponent,
    FormFieldComponent,
    LabelComponent,
    TranslatePipe,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    LanguageInputComponent,
    IconButtonComponent,
  ],
  templateUrl: './generate-flashcards-settings-form.component.html',
  styleUrls: ['./generate-flashcards-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'generate-flashcards-settings-form',
  },
})
export class GenerateFlashcardsSettingsFormComponent implements OnInit {
  /**
   * List of all available languages for the user to choose from
   */
  readonly availableLanguages: InputSignal<AvailableLanguage[]> = input.required<AvailableLanguage[]>();

  @Output() readonly formDataOutput: EventEmitter<FlashcardSettingsValues> = new EventEmitter<FlashcardSettingsValues>();
  @Output() readonly showPremiumModal: EventEmitter<void> = new EventEmitter<void>();

  protected readonly initialLanguage: Signal<SupportedBaseLanguage> = computed(() =>
    getBaseLanguage(this._translationService.currentLanguage()),
  );

  /** Used for vocabulary flashcards, so should be different from the initial language */
  protected readonly initialSecondLanguage: Signal<SupportedBaseLanguage> = computed(() => {
    const language = this.initialLanguage();

    return language === 'en' ? 'es' : 'en';
  });

  protected get showSecondLanguageInput(): boolean {
    return this.form.get('format')?.value === 'vocabulary';
  }

  protected readonly flashcardFormats: FlashcardFormat[] = [
    { option: `AI_GENERATION.ADVANCED_SETTINGS_DIALOG.FORMAT.QUESTION_ANSWER`, value: 'question_and_answer' },
    { option: `AI_GENERATION.ADVANCED_SETTINGS_DIALOG.FORMAT.TERM_DEFINITION`, value: 'term_and_definition' },
    { option: `AI_GENERATION.ADVANCED_SETTINGS_DIALOG.FORMAT.VOCABULARY`, value: 'vocabulary' },
  ];

  protected readonly flashcardTypes: FlashcardType[] = [
    { option: `AI_GENERATION.ADVANCED_SETTINGS_DIALOG.TYPE.REGULAR`, value: 'regular' },
    { option: `AI_GENERATION.ADVANCED_SETTINGS_DIALOG.TYPE.MULTIPLE_CHOICE`, value: 'multiple_choice' },
  ];

  protected readonly form: FormGroup<FlashcardSettingsControls> = new FormGroup<FlashcardSettingsControls>({
    type: new FormControl<FlashcardGenerationType>('regular', { nonNullable: true }),
    format: new FormControl<FlashcardGenerationFormat>('question_and_answer', { nonNullable: true }),
    language: new FormControl<string>(this.initialLanguage(), { nonNullable: true }),
    language2: new FormControl<string>(this.initialSecondLanguage(), { nonNullable: true }),
    instructions: new FormControl<string>('', { nonNullable: true }),
  });

  constructor(private readonly _translationService: TranslationService) {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this._emitFormData();
    });
  }

  ngOnInit(): void {
    this._emitFormData();
  }

  protected switchLanguageDirection(): void {
    const { language, language2 } = this.form.getRawValue();

    this.form.patchValue({ language: language2, language2: language });
  }

  private _emitFormData(): void {
    const { language, language2, ...formData } = this.form.getRawValue();
    let languageStringToEmit = language;

    // the backend always expects one language string, so we need to concatenate the two languages
    if (this.showSecondLanguageInput && language !== language2) {
      languageStringToEmit = `${language} -> ${language2}`;
    }

    this.formDataOutput.emit({ ...formData, language: languageStringToEmit });
  }
}
