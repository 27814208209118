<ui-info-card data-testid="info-card" isInteractive [uiDisabled]="isOffline || !hasEnoughFlashcards" [isContentAligned]="true">
  <ng-container slot="header">
    <span class="header">
      {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.AB_TITLE" | translate }}
    </span>
  </ng-container>

  <ng-container slot="description">
    <ng-container *ngIf="!isOffline" [ngTemplateOutlet]="quizHistory ? descriptionWithQuizHistory : descriptionWithoutQuizHistory" />
    <span *ngIf="isOffline" class="offline-message">
      <i role="img" class="icon app-icon-offline" aria-hidden="true"></i>
      {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.OFFLINE.BUTTON" | translate }}
    </span>

    <ng-template #descriptionWithoutQuizHistory>
      {{
        hasEnoughFlashcards
          ? ("FLASHCARDS.LEARN_MODES_CAROUSEL.QUIZ.AB_DESCRIPTION" | translate)
          : ("FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.DISABLED_BUTTON_WITH_COUNT" | translate: { count: minimumExamQuantity })
      }}
    </ng-template>

    <ng-template #descriptionWithQuizHistory>
      @if (quizHistory) {
        {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.AB_LAST_EXAM" | translate }}
        <span class="last-quiz-date">{{ quizHistory.createdAt | elapsedDuration }}</span>
      }
    </ng-template>
  </ng-container>

  <ng-container slot="icon">
    <div class="icon-with-background">
      <i role="img" class="icon app-icon-exam" aria-hidden="true"></i>
      <ui-circle-multiple-progressbar *ngIf="quizHistory" [progressSections]="lastQuizProgressSections" />
    </div>
  </ng-container>
</ui-info-card>
