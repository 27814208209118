import { Inject, Injectable } from '@angular/core';
import { partition } from 'lodash-es';
import { filter, Observable, pairwise, tap } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TaskTrackingSource } from '@stsm/analytics/models/source-property-types';
import { getPlainDateString } from '@stsm/date/global/util/tagged-date-util';
import { DateFnsUtil } from '@stsm/date/global/util/timezone-safe-date-fns-util';
import { PlainDateString } from '@stsm/date/models/plain-date-string';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { switchToVoid, tapOnce } from '@stsm/shared/util/rxjs.util';
import { StudyplanTask, StudyplanTaskType } from '@stsm/studyplan/models/studyplan-task';
import { TasksStoreFacade } from '@stsm/studyplan/store/tasks/tasks-store-facade.service';

type TaskGroup = 'today' | `today+${number}`;

@Injectable({ providedIn: 'root' })
export class TasksAnalyticsService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsBaseService: AnalyticsBaseService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _taskStoreFacadeService: TasksStoreFacade,
  ) {
    this._trackFetchedAndCompletedTasks().subscribe();
  }

  trackTodaysTasksFetchEvent(tasks: StudyplanTask[]): void {
    const lastTrackedTime: string | undefined = this._browserStorageService.getItemLocalStorage<string | undefined>(
      GlobalLocalStorageKey.LAST_TIME_TRACKED_FETCH_OF_TODAY_TASKS,
    );

    if (lastTrackedTime && DateFnsUtil.isToday(lastTrackedTime)) {
      return;
    }

    const [completedTasks, openTasks] = partition(tasks, (task: StudyplanTask) => task.isCompleted);

    this._analyticsBaseService.trackEvent({
      action: 'tasklist_today_tasks_fetch',
      properties: {
        openTasks: {
          ...this._getTasksCountByType(openTasks),
        },
        completedTasks: completedTasks.length,
      },
    });

    this._browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.LAST_TIME_TRACKED_FETCH_OF_TODAY_TASKS, getPlainDateString());
  }

  trackTaskOpen(task: StudyplanTask, source: TaskTrackingSource): void {
    this._analyticsBaseService.trackEvent({
      action: 'tasklist_task_open',
      properties: {
        ...this._getTaskProperties(task),
        source,
      },
    });
  }

  trackTaskCompleted(task: StudyplanTask, allTasksInGroup?: StudyplanTask[]): void {
    this._analyticsBaseService.trackEvent({
      action: 'tasklist_task_complete',
      properties: {
        ...this._getTaskProperties(task),
        tasksInGroup: allTasksInGroup?.length,
        completedAsNr: allTasksInGroup?.filter(({ isCompleted }: StudyplanTask): boolean => isCompleted).length,
      },
    });
  }

  private _trackFetchedAndCompletedTasks(): Observable<void> {
    return this._taskStoreFacadeService.supportedTasksToday$.pipe(
      filter((tasks: StudyplanTask[]): boolean => tasks.length > 0),
      tapOnce((tasks: StudyplanTask[]): void => {
        this.trackTodaysTasksFetchEvent(tasks);
      }),
      pairwise(),
      tap(([previousTasks, currentTasks]: [StudyplanTask[], StudyplanTask[]]): void => {
        const completedTasks: StudyplanTask[] = currentTasks.filter(({ id, isCompleted }: StudyplanTask): boolean => {
          const existingTask: StudyplanTask | undefined = previousTasks.find((task: StudyplanTask): boolean => task.id === id);

          return isCompleted && (!existingTask || !existingTask.isCompleted);
        });

        completedTasks.forEach((task: StudyplanTask): void => {
          this.trackTaskCompleted(task, currentTasks);
        });
      }),
      switchToVoid(),
    );
  }

  private _getTaskProperties(task: StudyplanTask): { type: StudyplanTaskType; group: TaskGroup; examDate: PlainDateString | null } {
    return {
      type: task.type,
      group: this._getTaskGroup(task),
      examDate: task.examDate ? getPlainDateString(task.examDate) : null,
    };
  }

  private _getTaskGroup(task: StudyplanTask): TaskGroup {
    return DateFnsUtil.isToday(task.date) ? 'today' : `today+${DateFnsUtil.differenceInCalendarDays(task.date)}`;
  }

  private _getTasksCountByType(tasks: StudyplanTask[]): Record<string, number> {
    return tasks.reduce(
      (acc: Record<string, number>, task: StudyplanTask) => {
        acc[task.type] = (acc[task.type] ?? 0) + 1;

        return acc;
      },
      { total: tasks.length },
    );
  }
}
