import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { floor, isNil } from 'lodash-es';
import { distinctUntilChanged, EMPTY, Observable, ReplaySubject, Subject, switchMap, tap, timer } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TimeTrackRecord } from '@stsm/analytics/models/properties-per-event-action';
import { TimeTrackFeature } from '@stsm/analytics/models/time-track-feature';
import { Seconds } from '@stsm/date/models/duration';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { JsonObject } from '@stsm/shared/types/json-object';
import { PerformanceMeasureUtil } from '@stsm/shared/util/performance-measure-util';

interface CurrentFeature {
  feature: TimeTrackFeature;
  url: string;
  timer: PerformanceMeasureUtil;
}

const TRACKING_INTERVAL: number = 2 * 60 * 1000; // = 2 minutes
const MINIMUM_FEATURE_TIME_SECONDS: Seconds = 3;

export abstract class FeatureTimeTrackingBaseService {
  feature$: Observable<TimeTrackFeature>;

  protected readonly router: Router = inject(Router);
  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly routerStoreFacade: RouterStoreFacade = inject(RouterStoreFacade);

  private _currentFeature: CurrentFeature | undefined = undefined;

  private readonly _feature$: ReplaySubject<TimeTrackFeature> = new ReplaySubject<TimeTrackFeature>(1);
  private readonly _trackingActive$: Subject<boolean> = new Subject<boolean>();

  private readonly _analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);

  protected constructor() {
    this.feature$ = this._feature$.pipe(distinctUntilChanged());

    this._trackingActive$
      .pipe(
        switchMap((isTrackingActive: boolean) => {
          if (!isTrackingActive) {
            if (this._currentFeature) {
              this._trackTimeOfLastFeature();
            }

            this._currentFeature = undefined;

            return EMPTY;
          }

          return this._feature$.pipe(
            distinctUntilChanged(),
            switchMap((feature: TimeTrackFeature) =>
              timer(0, TRACKING_INTERVAL).pipe(
                tap(() => {
                  this._trackTimeOfLastFeature();

                  this._currentFeature = {
                    feature,
                    url: this.router.url,
                    timer: new PerformanceMeasureUtil(feature),
                  };
                }),
              ),
            ),
          );
        }),
      )
      .subscribe();

    this.routerStoreFacade.url$.subscribe(() => this._setFeatureFromUrl());
  }

  init(): void {
    this.startTracking();
  }

  protected startTracking(): void {
    this._setFeatureFromUrl();
    this._trackingActive$.next(true);
  }

  protected stopTracking(): void {
    this._trackingActive$.next(false);
  }

  private _setFeatureFromUrl(): void {
    const url = this.router.url;

    // during init the url is still '/' -> don't set feature then
    if (url?.length > 1) {
      const feature = this.resolveFeatureFromUrl(url);
      this.loggerService.debug('[Feature Time Tracking] new feature:', feature);
      this._feature$.next(feature);
    }
  }

  private _trackTimeOfLastFeature(): void {
    if (!this._currentFeature) {
      return;
    }

    const { feature, timer, url } = this._currentFeature;

    const measure = timer.measure();

    if (isNil(measure)) {
      return;
    }

    const diff = floor(measure);
    const seconds = Math.min(Math.round(diff / 1000), 120); // limit feature time seconds to 2 minutes
    const minutes = parseFloat((seconds / 60).toFixed(3)); // minutes with 3 decimals
    const hours = parseFloat((seconds / 3600).toFixed(3)); // hours with 3 decimals

    // Happens when features are switched quickly: If there are no seconds to report, no event is sent
    if (!seconds || seconds < MINIMUM_FEATURE_TIME_SECONDS) {
      return;
    }

    const trackingRecord: TimeTrackRecord = {
      feature,
      hours,
      minutes,
      seconds,
      ...(feature === 'unknown' && { url }), // send url if feature is unknown
      ...this.getStudysetEventProps(),
    };

    this._analyticsService.trackEvent({ action: 'feature_time', properties: trackingRecord });
  }

  abstract resolveFeatureFromUrl(url: string): TimeTrackFeature;

  abstract getStudysetEventProps(): JsonObject;
}
