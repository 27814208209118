import { Inject, Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { ActionId, ActionsHub, OpenLearnContextMenuActionTrigger } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { LearnModeTriggerSource } from '@stsm/analytics/models/source-property-types';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { PropertiesOf } from '@stsm/shared/types/properties-of';
import { SpacedRepetitionService } from '@stsm/spaced-repetition/services/spaced-repetition.service';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { LearnContextMenuComponent } from './learn-context-menu.component';
import { LearnContextMenuSelection, LearnContextMenuSelectionData } from './learn-context-menu-selection';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to show the learn context menu instead.
 */
@Injectable({ providedIn: 'root' })
export class LearnContextMenuService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    private readonly _spacedRepetitionService: SpacedRepetitionService,
  ) {
    this._actionsHub.registerActionHandler(ActionId.OPEN_LEARN_CONTEXT_MENU, (trigger: OpenLearnContextMenuActionTrigger) =>
      this._showModal(trigger),
    );
  }

  init(): void {
    noop();
  }

  private async _showModal(options: OpenLearnContextMenuActionTrigger): Promise<void> {
    const { studyset, trackingSource, contextMenuConfig } = options;

    const dialogRef: DialogRef<LearnContextMenuComponent, LearnContextMenuSelectionData | undefined> =
      this._platformModalService.createContextMenu({
        component: LearnContextMenuComponent,
        data: <PropertiesOf<LearnContextMenuComponent>>{
          studyset,
          trackingSource,
        },
        ...contextMenuConfig,
      });

    this._analyticsService.trackEvent({
      action: 'studyset_learn_flashcards_context_menu_open',
      properties: {
        source: trackingSource,
      },
    });

    const result: LearnContextMenuSelectionData | undefined = await firstValueFrom(dialogRef.willClose());

    if (result) {
      this._analyticsService.trackEvent({
        action: 'studyset_learn_flashcards_context_menu_interact',
        properties: {
          action: result,
        },
      });

      const learnModeTrackingSource: LearnModeTriggerSource = `${trackingSource}_learn_menu`;

      switch (result.selection) {
        case LearnContextMenuSelection.PRACTICE:
          void this._navigationService.navigateToLearnFlashcards(studyset.id, { trackingSource: learnModeTrackingSource });
          break;
        case LearnContextMenuSelection.SPACED_REPETITION:
          void firstValueFrom(
            this._spacedRepetitionService.performSpacedRepetitionAction(result.action, studyset, {
              trackingSource: learnModeTrackingSource,
            }),
          );
          break;
        case LearnContextMenuSelection.QUIZ:
          void this._actionsHub.triggerAction(ActionId.START_QUIZ, {
            studysetOrId: studyset,
            trackingSource: learnModeTrackingSource,
          });
          break;
        case LearnContextMenuSelection.ORAL_QUIZ:
          void this._navigationService.navigateToOralQuiz(studyset.id);
          break;
        default:
          break;
      }
    }
  }
}
