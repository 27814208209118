<div class="input-area">
  <div class="explanation-text">
    {{ "IMPORT_FLASHCARDS.IMPORT_BY_TEXT_SELECT_EXPORT" | translate }}
  </div>

  <div class="separators-block">
    <div class="explanation-text">
      {{ "IMPORT_FLASHCARDS.USE" | translate }}
      {{ questionAnswerSeparator }}
    </div>
    <button class="copy-button" size="sm" uiButton secondary (click)="copyToClipboard($event, questionAnswerSeparator)">
      {{ "IMPORT_FLASHCARDS.COPY" | translate }}
    </button>

    <div class="explanation-text">
      {{ "IMPORT_FLASHCARDS.AND" | translate }}
      {{ nextFlashcardSeparator }}
    </div>
    <button class="copy-button" size="sm" uiButton secondary (click)="copyToClipboard($event, nextFlashcardSeparator)">
      {{ "IMPORT_FLASHCARDS.COPY" | translate }}
    </button>

    <div class="explanation-text">
      {{ "IMPORT_FLASHCARDS.AS_SEPARATORS" | translate }}
    </div>

    <img src="assets/illustrations/quizlet_import.png" class="import-illustration" alt="" />
  </div>
  <div class="explanation-text">
    {{ (isFirefox ? "IMPORT_FLASHCARDS.KEYBOARD_COPY_EXPLANATION" : "IMPORT_FLASHCARDS.DEFAULT_COPY_EXPLANATION") | translate }}
  </div>
  <div class="text-edit-button-row">
    <button *ngIf="!isFirefox" uiButton size="sm" class="button-control" (click)="pasteFromClipboard()">
      {{ "IMPORT_FLASHCARDS.PASTE_TEXT" | translate }}
    </button>
    <button *ngIf="textareaContent.length" uiButton secondary size="sm" (click)="clearInput()">
      {{ "IMPORT_FLASHCARDS.DELETE_TEXT" | translate }}
    </button>
  </div>
  <div class="text-area">
    <div #backdrop class="backdrop">
      <div #highlights class="highlights"></div>
    </div>
    <textarea
      #textarea
      data-cy="flashcard-import-as-text-textarea"
      [class.initial-cursor-position]="!textareaContent.length"
      [placeholder]="'IMPORT_FLASHCARDS.TEXTAREA_PLACEHOLDER' | translate"
      [(ngModel)]="textareaContent"
      (ngModelChange)="onInputChange()"
      (keydown.tab)="onTabKeyDown($event)"
      (scroll)="handleScroll()"
    ></textarea>
  </div>
  <div *ngIf="linesWithErrors?.length" class="error-lines-message">
    {{ "IMPORT_FLASHCARDS.LINES_WITH_ERRORS" | translate: { lines: linesWithErrors.join(", "), count: linesWithErrors.length } }}
  </div>
</div>
<div class="row-bottom">
  <button class="button-control" uiButton secondary (click)="reportProblem()">
    {{ "IMPORT_FLASHCARDS.REPORT_A_PROBLEM" | translate }}
  </button>
  <div class="buttons">
    <button class="cancel-btn" uiButton secondary data-cy="flashcard-import-as-text-cancel-button" (click)="onClose()">
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button
      uiButton
      data-cy="flashcard-import-as-text-upload-button"
      [disabled]="!textareaContent.length"
      (click)="importFlashcardsFromText()"
    >
      <i class="app-icon-upload" slot="start"></i>
      <span class="button-text">{{ "IMPORT_FLASHCARDS.UPLOAD.BUTTON" | translate }}</span>
    </button>
  </div>
</div>
