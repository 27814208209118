import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { User } from '@stsm/user/models/user';
import { UserFeature } from '@stsm/user/models/user-feature';

import { UserStoreFacade } from './user-store-facade.service';

const DEFAULT_BETA_USER_GROUPS: UserFeature[] = [UserFeature.DOGFOODING, UserFeature.USER_PANEL];

@Injectable({
  providedIn: 'root',
})
export class UserFeaturesService {
  constructor(private readonly _userStoreFacade: UserStoreFacade) {}

  get isDefaultBetaUser$(): Observable<boolean> {
    return this._userStoreFacade.userAvailable$.pipe(map((user: User) => UserFeaturesService.isDefaultBetaUser(user)));
  }

  get isDogfoodingUser$(): Observable<boolean> {
    return this._userStoreFacade.userAvailable$.pipe(
      map((user: User) => !isNil(user.featureGroups.find((group: UserFeature) => group === UserFeature.DOGFOODING))),
    );
  }

  get isAiAssistantManuallyEnabled$(): Observable<boolean> {
    return this._userStoreFacade.user$.pipe(
      map((user: User) =>
        user.featureGroups.some((group: UserFeature) => group === UserFeature.AI_ASSISTANT || group === UserFeature.DOGFOODING),
      ),
    );
  }

  get isNpsSurveyEnabled$(): Observable<boolean> {
    return this._userStoreFacade.user$.pipe(map((user: User) => UserFeaturesService.isNpsSurveyEnabledForUser(user)));
  }

  static isDefaultBetaUser(user: User): boolean {
    return user.featureGroups.some((group: UserFeature) => DEFAULT_BETA_USER_GROUPS.includes(group));
  }

  static isNpsSurveyEnabledForUser(user: User): boolean {
    return user.featureGroups.includes(UserFeature.NPS_SURVEY);
  }
}
