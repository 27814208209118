@if (currentAdGroup(); as currentAdGroup) {
  @if (currentAdGroup[0]; as group) {
    @if (group.ad.id === PREMIUM_AD_ID) {
      <app-premium-enigma
        isMobileView
        shouldEmitOnce
        (isVisible)="onIsPremiumAdVisible($event, { location: currentAdGroup[1] ? AdLocation.SIDEBAR_SLOT1 : AdLocation.SIDEBAR })"
      />
    } @else {
      <app-enigma
        labelType="ditchTheAds"
        [shouldSendAdImpression]="!isFullPageRouteActive()"
        [ad]="group.ad"
        [location]="currentAdGroup[1] ? AdLocation.SIDEBAR_SLOT1 : AdLocation.SIDEBAR"
        [preferredMediaOrientation]="group.orientation"
        (getPremiumClick)="onGetPremiumClick()"
      />
    }
  }

  @if (currentAdGroup[1]; as group) {
    @if (group.ad.id === PREMIUM_AD_ID) {
      <app-premium-enigma shouldEmitOnce isMobileView (isVisible)="onIsPremiumAdVisible($event, { location: AdLocation.SIDEBAR_SLOT2 })" />
    } @else {
      <app-enigma
        [shouldSendAdImpression]="!isFullPageRouteActive()"
        [ad]="group.ad"
        [location]="AdLocation.SIDEBAR_SLOT2"
        [preferredMediaOrientation]="group.orientation"
        (getPremiumClick)="onGetPremiumClick()"
      />
    }
  }
}
