import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, debounceTime, firstValueFrom, of, skip, takeUntil } from 'rxjs';

import { GenerateAiFlashcardsService } from '@stsm/ai-generation/services/generate-ai-flashcards.service';
import type { FlashcardSettingsValues, GenerateAiFlashcardsOptions } from '@stsm/ai-generation/types/flashcard-generation';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { Flashcard, FlashcardFactory } from '@stsm/flashcards/types/flashcard';
import { FlashcardPreviewCardComponent } from '@stsm/flashcards/ui/flashcard-preview-card/flashcard-preview-card.component';
import { AvailableLanguage } from '@stsm/global/models/available-language';
import { AiGeneratedSource } from '@stsm/global/models/enums/ai-generated-source';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { AvailableTongueLanguagesStore } from '@stsm/qualifications/services/available-tongue-languages.store';
import { Id } from '@stsm/shared/types/id';
import { firstValueFromOrNull } from '@stsm/shared/util/rxjs.util';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { AnimatedIllustrationDirective } from '@stsm/ui-components/lottie-view/animated-illustration.directive';
import { SkeletonCardComponent } from '@stsm/ui-components/skeletons/skeleton-card';

import { GenerateFlashcardsSettingsFormComponent } from '../generate-flashcards-settings-form/generate-flashcards-settings-form.component';

@UntilDestroy()
@Component({
  selector: 'app-ai-creation-settings-and-preview',
  imports: [
    GenerateFlashcardsSettingsFormComponent,
    DialogTemplateComponent,
    SkeletonCardComponent,
    FlashcardPreviewCardComponent,
    ButtonComponent,
    TranslatePipe,
    AnimatedIllustrationDirective,
  ],
  templateUrl: './ai-creation-settings-and-preview.component.html',
  styleUrl: './ai-creation-settings-and-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'ai-creation-settings-and-preview',
  },
})
export class AiCreationSettingsAndPreviewComponent implements OnInit {
  @Input({ required: true }) studysetId!: Id;
  @Input({ required: true }) generationOptions: GenerateAiFlashcardsOptions = { feature: AiGeneratedSource.AI_PREVIEW_FLASHCARD };
  @Input() slidesetId?: Id;

  protected readonly additionalSettings$: BehaviorSubject<FlashcardSettingsValues | undefined> = new BehaviorSubject<
    FlashcardSettingsValues | undefined
  >(undefined);

  protected readonly availableLanguages: Signal<AvailableLanguage[]> = this._availableTongueLanguagesStore.availableTongueLanguages;

  protected isLoadingPreview: WritableSignal<boolean> = signal(true);

  protected previewFlashcard: Flashcard = FlashcardFactory.create({
    questionFlashcardEntry: {
      text: this._translationService.get('AI_GENERATION.ADVANCED_SETTINGS_DIALOG.PREVIEW.QUESTION'),
      isCorrect: true,
    },
    answerFlashcardEntries: [
      {
        text: this._translationService.get('AI_GENERATION.ADVANCED_SETTINGS_DIALOG.PREVIEW.ANSWER'),
        isCorrect: true,
      },
    ],
    aiGeneratedSource: AiGeneratedSource.AI_PREVIEW_FLASHCARD,
  });

  constructor(
    private readonly _dialogRef: DialogRef,
    private readonly _generateAiFlashcardsService: GenerateAiFlashcardsService,
    private readonly _translationService: TranslationService,
    private readonly _availableTongueLanguagesStore: AvailableTongueLanguagesStore,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {}

  ngOnInit(): void {
    this.additionalSettings$.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      void this.regenerateExample();
    });

    this._analyticsService.trackEvent({
      action: 'generate_ai_overlay_creation_settings_open',
    });

    this._availableTongueLanguagesStore.fetchAvailableTongueLanguages();
  }

  async submit(): Promise<void> {
    const additionalSettings = await firstValueFrom(this.additionalSettings$);

    const eventsMap: Record<AiGeneratedSource, 'document' | 'scan' | 'text' | 'topics'> = {
      [AiGeneratedSource.EXPLAIN_AI]: 'text',
      [AiGeneratedSource.FREE_TEXT]: 'text',
      [AiGeneratedSource.GENERATE_FROM_TOPICS]: 'topics',
      [AiGeneratedSource.GENERATE_FROM_DOCUMENT]: 'document',
      [AiGeneratedSource.GENERATE_FROM_SCAN]: 'scan',
      [AiGeneratedSource.GENERATE_FROM_SUMMARY]: 'text',
      [AiGeneratedSource.GENERATE_FROM_HIGHLIGHT]: 'document',
      [AiGeneratedSource.AI_ASSISTANT_CONVERSATION]: 'text',
      [AiGeneratedSource.AI_ASSISTANT_DOCUMENT]: 'document',
      [AiGeneratedSource.AI_ASSISTANT_SCAN]: 'scan',
      [AiGeneratedSource.STUDYPLAN_DOCUMENT]: 'document',
      [AiGeneratedSource.STUDYPLAN_SCAN]: 'scan',
      [AiGeneratedSource.STUDYPLAN_DESCRIPTION]: 'text',
      [AiGeneratedSource.AI_PREVIEW_FLASHCARD]: 'text',
    };

    this._analyticsService.trackEvent({
      action: 'generate_ai_overlay_creation_start',
      properties: {
        advancedAICreationSettings: additionalSettings,
        flashcardAiGenSource: eventsMap[this.generationOptions.feature],
      },
    });

    this._dialogRef.dismiss(additionalSettings);
  }

  cancel(): void {
    this._dialogRef.dismiss();
  }

  updateAdditionalSettings(settings: FlashcardSettingsValues): void {
    this.additionalSettings$.next(settings);
  }

  regenerateExampleClick(): void {
    this._analyticsService.trackEvent({
      action: 'generate_ai_regenerate_preview_click',
    });

    void this.regenerateExample();
  }

  async regenerateExample(): Promise<void> {
    this.isLoadingPreview.set(true);

    const additionalSettings = (await firstValueFrom(this.additionalSettings$)) || {};

    const combinedSettings: GenerateAiFlashcardsOptions = {
      ...this.generationOptions,
      ...additionalSettings,
    };

    const previewFlashcard = await firstValueFromOrNull(
      this._generateAiFlashcardsService.generatePreviewFlashcard(this.studysetId, combinedSettings, this.slidesetId).pipe(
        catchError(() => of(null)),
        takeUntil(this.additionalSettings$.pipe(skip(1))),
      ),
    );

    if (previewFlashcard) {
      this.previewFlashcard = previewFlashcard;
    }

    this.isLoadingPreview.set(false);
  }
}
