import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { catchError, from, tap, throwError } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { FlashcardsBaseService } from '@stsm/flashcards/services/flashcards-base-service';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { PlatformBaseService } from '@stsm/shared/services/platform-base.service';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { ButtonComponent } from '@stsm/ui-components/button';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { ReportImportProblemService } from '../report-import-problem/report-import-problem.service';

@UntilDestroy()
@Component({
  selector: 'app-flashcard-import-as-text',
  templateUrl: './flashcard-import-as-text.component.html',
  styleUrls: ['./flashcard-import-as-text.component.scss'],
  imports: [ButtonComponent, TranslatePipe, NgIf, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashcardImportAsTextComponent {
  @ViewChild('highlights') highlights?: ElementRef<HTMLElement>;
  @ViewChild('backdrop') backdrop?: ElementRef<HTMLElement>;
  @ViewChild('textarea') textarea?: ElementRef<HTMLTextAreaElement>;

  @Input({ required: true }) studysetId!: number;

  // Emits whether flashcards have been imported or not
  @Output() readonly closeRequest: EventEmitter<boolean> = new EventEmitter<boolean>();

  linesWithErrors: number[] = [];
  textareaContent: string = '';
  nextFlashcardSeparator: string = '\\n\\#\\n';
  questionAnswerSeparator: string = '/#*#/';

  private _sendPasteAnalyticsEvent: boolean = false;

  protected get isFirefox(): boolean {
    return this._platformService.isFireFox();
  }

  constructor(
    @Inject(FLASHCARDS_SERVICE) private readonly _flashcardsService: FlashcardsBaseService,
    private readonly _translationService: TranslationService,
    private readonly _simpleDialogService: SimpleDialogService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _reportImportProblemService: ReportImportProblemService,
    @Inject(PLATFORM_SERVICE) private readonly _platformService: PlatformBaseService,
  ) {
    this._analyticsService.trackEvent({ action: 'import_flashcards_text_open' });
  }

  @HostListener('paste', ['$event'])
  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text');

    if (!isNil(text)) {
      this._sendPasteAnalyticsEvent = true;
      this._insertAtCursor(text);
    }
  }

  onInputChange(): void {
    this.linesWithErrors = this._checkErrorLines(this.textareaContent);
  }

  onTabKeyDown(event: Event): void {
    event.preventDefault();
    this._insertAtCursor('\t');
  }

  clearInput(): void {
    this.textareaContent = '';
    this.linesWithErrors = [];

    if (!isNil(this.highlights)) {
      this.highlights.nativeElement.innerHTML = '';
    }
  }

  onClose(flashcardsImported: boolean = false): void {
    this.closeRequest.emit(flashcardsImported);
  }

  importFlashcardsFromText(): void {
    this._flashcardsService
      .importFlashcardsFromText(this.studysetId, this.textareaContent)
      .pipe(
        tap((res: { flashcardCount: number; missingLines: number[] }) => {
          this._simpleDialogService.showAlert({
            text: 'IMPORT_FLASHCARDS.UPLOAD.SUCCESS',
          });

          this._analyticsService.trackEvent({
            action: 'import_flashcards_text_confirm_import',
            properties: {
              lines_ok: res.flashcardCount,
              lines_failed: res.missingLines.length,
            },
          });

          this.onClose(true);
        }),
        catchError((error: Error) => {
          this._analyticsService.trackEvent({
            action: 'import_flashcards_text_confirm_import',
            properties: {
              error: error.message,
            },
          });
          this._simpleDialogService.showError('IMPORT_FLASHCARDS.UPLOAD.ERROR');

          return throwError(() => error);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  handleScroll(): void {
    if (!isNil(this.backdrop) && !isNil(this.textarea)) {
      this.backdrop.nativeElement.scrollTop = this.textarea.nativeElement.scrollTop;
      this.backdrop.nativeElement.scrollLeft = this.textarea.nativeElement.scrollLeft;
    }
  }

  async copyToClipboard(event: MouseEvent, val: string): Promise<void> {
    await navigator.clipboard.writeText(val);

    const target = event.target as HTMLButtonElement;
    target.classList.add('copied');
    target.textContent = this._translationService.get('IMPORT_FLASHCARDS.COPIED');
    setTimeout(() => {
      target.classList.remove('copied');
      target.textContent = this._translationService.get('IMPORT_FLASHCARDS.COPY');
    }, 1500);
  }

  pasteFromClipboard(): void {
    from(navigator.clipboard.readText())
      .pipe(
        tap((text: string) => {
          if (!isNil(this.textarea)) {
            this._sendPasteAnalyticsEvent = true;
            this.textarea.nativeElement.focus();
            this._insertAtCursor(text);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  reportProblem(): void {
    this._reportImportProblemService.showDialog(this.textareaContent);
  }

  private _insertAtCursor(text: string): void {
    if (this.isFirefox && !isNil(this.textarea)) {
      this.textarea.nativeElement.setRangeText(text);
      this.textarea.nativeElement.selectionStart = this.textarea.nativeElement.selectionStart + text.length;
      this.textareaContent = this.textarea.nativeElement.value;
      this.onInputChange();

      return;
    }

    document.execCommand('insertHTML', false, text);
  }

  private _checkErrorLines(text: string): number[] {
    const linesWithErrors: number[] = [];
    let highlightedErrorText = '';
    const formattedNextFlashcardSeparator = this.nextFlashcardSeparator.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    const formattedQuestionAnswerSeparator = this.questionAnswerSeparator.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    const htmlNextFlashcardSeparator = '\\#<br>';

    if (text.length > 0) {
      const textLines = text.split(formattedNextFlashcardSeparator);

      textLines.forEach((line: string, index: number) => {
        const splittedLine = line.split(formattedQuestionAnswerSeparator);
        const hasError = splittedLine.length !== 2;
        const hasEmptyFields = !splittedLine[0]?.length || !splittedLine[1]?.length;

        if (hasError || hasEmptyFields) {
          linesWithErrors.push(index + 1);
          highlightedErrorText = `${highlightedErrorText}<span class="line-number error">${
            index + 1
          }</span><mark>${line}</mark>${this.nextFlashcardSeparator.replace(/\\n/g, '<br>')}`;
        } else {
          highlightedErrorText = `${highlightedErrorText}<span class="line-number">${
            index + 1
          }</span>${line}${this.nextFlashcardSeparator.replace(/\\n/g, '<br>')}`;
        }
      });

      highlightedErrorText = highlightedErrorText
        .replace(
          new RegExp(this._escapeRegExp(formattedQuestionAnswerSeparator), 'g'),
          `<span class="question-answer-separator">${formattedQuestionAnswerSeparator}</span>`,
        )
        .replace(
          new RegExp(this._escapeRegExp(htmlNextFlashcardSeparator), 'g'),
          `<span class="question-answer-separator">${htmlNextFlashcardSeparator}</span>`,
        );

      if (this._sendPasteAnalyticsEvent) {
        this._sendPasteAnalyticsEvent = false;
        this._analyticsService.trackEvent({
          action: 'import_flashcards_text_paste',
          properties: {
            lines_ok: textLines.length - linesWithErrors.length,
            lines_failed: linesWithErrors.length,
          },
        });
      }
    }

    if (!isNil(this.highlights)) {
      this.highlights.nativeElement.innerHTML = highlightedErrorText;
    }

    return linesWithErrors;
  }

  private _escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
