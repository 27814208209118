import { ShareDialogOpenSource } from '@stsm/analytics/models/source-property-types';

export abstract class SharingBaseService {
  copyToClipboard(content: string): Promise<void> {
    return navigator.clipboard.writeText(content);
  }

  abstract shareApp(options: { source: ShareDialogOpenSource }): void;

  /**
   * Uses the native sharing dialog for mobile to share a file
   * Automatically downloads a file on Web.
   *
   * Returns a Promise which results to true if the user shared (or downloaded) the file or false in case the user
   * cancelled the sharing prompt.
   *
   * @param params params
   * @param params.blob the file blob
   * @param params.fileName the fileName
   */
  abstract shareOrDownloadFile(params: { blob: Blob; fileName: string }): Promise<boolean>;

  abstract nativeShare(options: { text: string; title: string; url: string; source: ShareDialogOpenSource }): void;
}
