import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { isNil, noop } from 'lodash-es';
import { catchError, map, Observable, pipe, switchMap, take, tap } from 'rxjs';

import { ActionId, ActionsHub, OpenLeadGenAdDialogActionTrigger } from '@stsm/actions-hub';
import { Ad, isLeadGenAdWithEntryQuestion } from '@stsm/advertisement/models/ad';
import { getLeadGenAdReward } from '@stsm/advertisement/models/ad-reward';
import { AdRewardCheckService } from '@stsm/advertisement/services/ad-reward-check.service';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { DurationInMilliseconds } from '@stsm/date/models/duration-in-milliseconds';
import { LeadsService } from '@stsm/global/composite/services/leads.service';
import { LeadSubmitData } from '@stsm/global/models/lead-question';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalWebOptions } from '@stsm/ui-components/dialogs/models/platform-modal-options';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { LEAD_GEN_AD_MODAL_ID, LeadGenAdModalStep, LeadGenAdSubmitData } from './lead-gen-ad.models';
import { LeadGenAdEntryQuestionDialogComponent } from './lead-gen-ad-entry-question-dialog/lead-gen-ad-entry-question-dialog.component';
import { LeadGenAdModalFailureComponent } from './lead-gen-ad-modal-failure/lead-gen-ad-modal-failure.component';
import { LeadGenAdModalFormComponent } from './lead-gen-ad-modal-form.component';
import { LeadGenAdModalRewardComponent } from './lead-gen-ad-modal-reward/lead-gen-ad-modal-reward.component';

@Injectable({
  providedIn: 'root',
})
export class LeadGenAdModalService {
  private readonly _openLeadGenAdModal: (data: OpenLeadGenAdDialogActionTrigger) => void = rxMethod<OpenLeadGenAdDialogActionTrigger>(
    pipe(
      tap(({ trackingSource }: OpenLeadGenAdDialogActionTrigger) => this._trackingSource.set(trackingSource)),
      switchMap(({ ad }: OpenLeadGenAdDialogActionTrigger) => {
        if (isLeadGenAdWithEntryQuestion(ad)) {
          return this._openLeadGenAdEntryQuestionDialog(ad).pipe(
            switchMap((shouldContinue: boolean) => {
              if (!shouldContinue) {
                this.trackStep({ ad, step: 'entry_question', event: 'leadgen_form_close' });

                return VOID;
              }

              return this._openLeadGenAdFormModal(ad, { shouldSkipAdDisplay: true });
            }),
          );
        }

        return this._openLeadGenAdFormModal(ad);
      }),
    ),
  );

  private readonly _webOverlayConfig: PlatformModalWebOptions = {
    maxWidth: 500,
    minWidth: 300,
  };

  private readonly _trackingSource: WritableSignal<string | undefined> = signal(undefined);

  private readonly _rewardDurationInDays: number = getLeadGenAdReward();

  constructor(
    private readonly _platformModalService: PlatformModalService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _loggerService: LoggerService,
    private readonly _adRewardCheckService: AdRewardCheckService,
    private readonly _leadsService: LeadsService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.OPEN_LEAD_GEN_AD_DIALOG, (trigger: OpenLeadGenAdDialogActionTrigger) => {
      this._openLeadGenAdModal(trigger);

      return Promise.resolve();
    });
  }

  init(): void {
    noop();
  }

  trackStep({
    ad,
    step,
    questionNumber,
    event = 'leadgen_form_open',
  }: {
    ad: Ad | undefined;
    step?: LeadGenAdModalStep;
    questionNumber?: number;
    event?: 'leadgen_form_open' | 'leadgen_form_close';
  }): void {
    if (ad) {
      this._analyticsService.trackEvent({
        action: event,
        properties: {
          companyName: ad.companyName,
          campaignName: ad.campaignName,
          step,
          source: this._trackingSource(),
          callToAction: ad.leadGenAdCtaType,
          questionsTotal: ad.leadQuestions.length,
          questionNumber,
        },
      });
    }
  }

  private _openLeadGenAdEntryQuestionDialog(ad: Ad): Observable<boolean> {
    this.trackStep({ ad, step: 'entry_question' });

    const dialogRef: DialogRef<LeadGenAdEntryQuestionDialogComponent, string | undefined> = this._platformModalService.create({
      component: LeadGenAdEntryQuestionDialogComponent,
      data: <InputPropertiesOf<LeadGenAdEntryQuestionDialogComponent>>{
        ad,
      },
      allowBackdropDismiss: false,
      webOptions: this._webOverlayConfig,
    });

    return dialogRef.afterClosed().pipe(map((userAnswer: string | undefined) => userAnswer === ad.entryTriggerAnswer));
  }

  private _openLeadGenAdFormModal(ad: Ad, options: { shouldSkipAdDisplay?: boolean } = {}): Observable<void> {
    return this._createLeadGenAdFormModal(ad, options).pipe(
      switchMap((data: LeadSubmitData | undefined): Observable<void> => {
        if (!isNil(data)) {
          return this._submitLeadGenAdFormData({ data, ad });
        }

        return VOID;
      }),
    );
  }

  private _createLeadGenAdFormModal(ad: Ad, options: { shouldSkipAdDisplay?: boolean } = {}): Observable<LeadSubmitData | undefined> {
    return this._userStoreFacade.user$.pipe(
      take(1),
      switchMap((user: User) => {
        const dialogRef: DialogRef<LeadGenAdModalFormComponent, LeadSubmitData | undefined> = this._platformModalService.create({
          component: LeadGenAdModalFormComponent,
          allowBackdropDismiss: false,
          data: <InputPropertiesOf<LeadGenAdModalFormComponent>>{
            ad,
            user,
            shouldSkipAdDisplay: !!options.shouldSkipAdDisplay,
          },
          id: LEAD_GEN_AD_MODAL_ID,
          webOptions: this._webOverlayConfig,
        });

        return dialogRef.afterClosed();
      }),
    );
  }

  private _submitLeadGenAdFormData({ data, ad }: LeadGenAdSubmitData): Observable<void> {
    this._analyticsService.trackEvent({
      action: 'leadgen_form_submit',
      properties: {
        companyName: ad.companyName,
        campaignName: ad.campaignName,
        source: this._trackingSource(),
        callToAction: ad.leadGenAdCtaType,
        rewardDurationInDays: this._rewardDurationInDays,
      },
    });

    return this._leadsService.sendLeadData(data, { leadType: 'ad', clientName: ad.companyName, adId: ad.id }).pipe(
      tap(() => this._onFormSuccessfullySubmitted(ad)),
      catchError((error: Error) => {
        this._loggerService.warn({ error });

        this._onFormSubmissionFailed(ad);

        return VOID;
      }),
    );
  }

  private _onFormSuccessfullySubmitted(ad: Ad): void {
    this._adRewardCheckService.giveReward({
      rewardExpirationTimestamp: Date.now() + DurationInMilliseconds.DAY * this._rewardDurationInDays,
    });
    this._analyticsService.trackEvent({
      action: 'leadgen_form_success',
      properties: {
        companyName: ad.companyName,
        campaignName: ad.campaignName,
        source: this._trackingSource(),
        rewardDurationInDays: this._rewardDurationInDays,
      },
    });

    this._platformModalService.create({
      component: LeadGenAdModalRewardComponent,
      allowBackdropDismiss: false,
      data: <InputPropertiesOf<LeadGenAdModalRewardComponent>>{
        ad,
        rewardDurationInDays: this._rewardDurationInDays,
      },
      id: LEAD_GEN_AD_MODAL_ID,
      webOptions: this._webOverlayConfig,
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }

  private _onFormSubmissionFailed(ad: Ad): void {
    this._analyticsService.trackEvent({
      action: 'leadgen_form_error',
      properties: {
        companyName: ad.companyName,
        campaignName: ad.campaignName,
        source: this._trackingSource(),
        rewardDurationInDays: this._rewardDurationInDays,
      },
    });

    this._platformModalService.create({
      component: LeadGenAdModalFailureComponent,
      allowBackdropDismiss: false,
      data: <InputPropertiesOf<LeadGenAdModalFailureComponent>>{
        ad,
      },
      id: LEAD_GEN_AD_MODAL_ID,
      webOptions: this._webOverlayConfig,
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
