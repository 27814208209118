import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, TrackByFunction } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatten } from 'lodash-es';
import { filter, map } from 'rxjs';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { trackByIndex } from '@stsm/shared/util/generic-utils';
import {
  studysetCountryIdMapping,
  studysetCountryOptions,
  StudysetCountryPickerOption,
  SupportedStudysetCountry,
} from '@stsm/studysets/models/studyset-country-picker';
import { FormFieldComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { OptionComponent } from '@stsm/ui-components/option';
import { SelectComponent } from '@stsm/ui-components/select';

@UntilDestroy()
@Component({
  selector: 'app-studyset-country-picker',
  templateUrl: './studyset-country-picker.component.html',
  styleUrls: ['./studyset-country-picker.component.scss'],
  imports: [
    FormFieldComponent,
    NgForOf,
    NgIf,
    NgOptimizedImage,
    OptionComponent,
    ReactiveFormsModule,
    TranslatePipe,
    LabelComponent,
    SelectComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StudysetCountryPickerComponent),
      multi: true,
    },
  ],
})
export class StudysetCountryPickerComponent implements ControlValueAccessor {
  countries: StudysetCountryPickerOption[] = studysetCountryOptions;
  control: FormControl<SupportedStudysetCountry[]> = new FormControl<SupportedStudysetCountry[]>([], { nonNullable: true });

  protected readonly trackByIndex: TrackByFunction<StudysetCountryPickerOption> = trackByIndex;

  registerOnChange(onChange: (countryIds: number[]) => void): void {
    this.control.valueChanges
      .pipe(
        filter((selectedCountries: SupportedStudysetCountry[]) => !!selectedCountries),
        map((selectedCountries: SupportedStudysetCountry[]) =>
          flatten(
            this.countries
              .filter(({ value }: { value: SupportedStudysetCountry }) => selectedCountries.includes(value))
              .map(({ value }: { value: SupportedStudysetCountry }) => studysetCountryIdMapping[value]),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(onChange);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_: () => void): void {}

  writeValue(countryIds: number[]): void {
    if (countryIds) {
      const countries: SupportedStudysetCountry[] = Object.entries(studysetCountryIdMapping)
        .filter(([_, ids]: [string, number[]]) => ids.some((id: number) => countryIds.includes(id)))
        .map(([country, _]: [string, number[]]) => country) as SupportedStudysetCountry[];

      this.control.setValue(countries, { emitEvent: false });
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
