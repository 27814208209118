import { Ad } from '@stsm/advertisement/models/ad';
import { LeadSubmitData } from '@stsm/global/models/lead-question';

export const LEAD_GEN_AD_MODAL_ID = 'lead-gen-ad-modal';

export interface LeadGenAdSubmitData {
  ad: Ad;
  data: LeadSubmitData;
}

export type LeadGenAdModalStep = 'company_details' | 'personal_info' | 'questionnaire' | 'entry_question';
