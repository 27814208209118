<ui-selection-list
  class="weekdays"
  data-cy="weekday-picker-selection-list"
  [attr.aria-label]="'WEEKDAY_PICKER.ARIA_LABEL' | translate: { count: multiple ? 2 : 1 }"
  [formControl]="weekdayControl"
  [multiple]="multiple"
>
  <button
    *ngFor="let weekdayLabel of weekdayLabels$ | async; trackBy: trackByDefault; index as index"
    uiListOption
    class="weekday-control"
    data-cy="weekday-picker-list-option"
    [attr.aria-label]="weekdayLabelsLong[index]"
    [attr.abbr]="weekdayLabelsLong[index]"
    [value]="index + 1"
  >
    {{ weekdayLabel }}
  </button>
</ui-selection-list>
