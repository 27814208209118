<ng-container *ngIf="studyset?.isSpacedRepetitionStarted; then spacedRepetitionStartedItem; else spacedRepetitionNotStartedItem" />

<ng-template #spacedRepetitionNotStartedItem>
  <ui-info-card isInteractive [uiDisabled]="isOffline" [isContentAligned]="true">
    <ng-container slot="header">
      {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.AB_TITLE" | translate }}
    </ng-container>

    <ng-container slot="description">
      <span *ngIf="!isOffline">{{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.AB_DESCRIPTION" | translate }}</span>
      <ng-container *ngIf="isOffline">
        <ng-container *ngTemplateOutlet="offlineMessage" />
      </ng-container>
    </ng-container>

    <ng-container slot="icon">
      <div class="icon-with-background">
        <i role="img" class="icon app-icon-spaced-repetition" aria-hidden="true"></i>
      </div>
    </ng-container>
  </ui-info-card>
</ng-template>

<ng-template #spacedRepetitionStartedItem>
  <ui-info-card isInteractive [uiDisabled]="isOffline" [isContentAligned]="true" [class.all-learned]="isLearned">
    <ng-container slot="header">
      <span class="header">{{ titleTranslationKey | translate: { learnCount: learnChunkSize, reviewCount } }}</span>
    </ng-container>

    <ng-container slot="description">
      <ng-container *ngIf="!isOffline; else offlineMessage">
        {{ descriptionTranslationKey | translate }}
        <span *ngIf="nextReviewDate" class="next-review-date">
          {{ nextReviewDate }}
        </span>
      </ng-container>
    </ng-container>

    <ng-container slot="icon">
      <ng-container [ngTemplateOutlet]="learnProgress" />
    </ng-container>

    <button *ngIf="showOptions" uiIconButton tertiary slot="options" size="sm" data-cy="spaced-repetition-options-button">
      <i class="app-icon-ellipsis-vertical-fill" slot="icon-only" (click)="onOptionsClick($event)"> </i>
    </button>
  </ui-info-card>
</ng-template>

<ng-template #offlineMessage>
  <span class="offline-message">
    <i role="img" class="icon app-icon-offline" aria-hidden="true"></i>
    {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.OFFLINE.BUTTON" | translate }}
  </span>
</ng-template>

<ng-template #learnProgress>
  <ui-circle-multiple-progressbar data-cy="spaced-repetition-progress-indicator" [progressSections]="progressSections">
    <span *ngIf="isReview" class="review-count" data-testid="progress-content">
      {{ reviewCount }}
    </span>
    <span *ngIf="isLearning" class="learn-count" data-testid="progress-content">+{{ learnChunkSize }}</span>
    <i *ngIf="isLearned" role="img" aria-hidden="true" class="icon app-icon-check"></i>
  </ui-circle-multiple-progressbar>
</ng-template>
