import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import * as fromSlidesets from '@stsm/documents/store/slidesets.reducer';
import * as fromExercises from '@stsm/exercises/store/exercises.reducer';
import * as fromFlashcards from '@stsm/flashcards/store';
import { routerStateKey } from '@stsm/shared/router-store/router-state-key';
import * as fromComponentStore from '@stsm/shared/services/component-store-dev-tools.service';
import * as fromStudygroups from '@stsm/studygroups/store/studygroups.reducer';
import * as fromStudyplanEvents from '@stsm/studyplan/store/studyplan.reducer';
import * as fromTasks from '@stsm/studyplan/store/tasks/task.reducer';
import * as fromStudysetInfos from '@stsm/studysets/store/studyset-info.reducer';
import * as fromStudysets from '@stsm/studysets/store/studysets.reducer';
import * as fromSubjects from '@stsm/subjects/store/subjects.reducer';
import * as fromSummaries from '@stsm/summaries/store/summaries.reducer';
import * as fromTags from '@stsm/tags/store/tags.reducer';
import * as fromTextbooks from '@stsm/textbooks/store/textbooks.reducer';
import * as fromUser from '@stsm/user/store/user.reducer';

import * as fromApp from './app/app.reducer';

export interface SharedGlobalState {
  [routerStateKey]: RouterReducerState;
  [fromApp.APP_STATE_KEY]: fromApp.AppState;
  [fromUser.userStateKey]: fromUser.UserState;
  [fromSubjects.subjectsStateKey]: fromSubjects.SubjectsState;
  [fromStudysets.studysetsStateKey]: fromStudysets.StudysetsState;
  [fromStudysetInfos.studysetInfosStateKey]: fromStudysetInfos.StudysetInfosState;
  [fromTextbooks.textbooksStateKey]: fromTextbooks.TextbooksState;
  [fromSlidesets.slidesetsStateKey]: fromSlidesets.SlidesetsState;
  [fromSummaries.summariesStateKey]: fromSummaries.SummariesState;
  [fromStudygroups.studygroupsStateKey]: fromStudygroups.StudygroupsState;
  [fromFlashcards.flashcardsStateKey]: fromFlashcards.FlashcardsState;
  [fromTags.tagsStateKey]: fromTags.TagsState;
  [fromExercises.exercisesStateKey]: fromExercises.ExercisesState;
  [fromStudyplanEvents.studyplanStateKey]: fromStudyplanEvents.StudyplanState;
  [fromComponentStore.componentStoreStateKey]: fromComponentStore.ComponentStoreState;
  [fromTasks.tasksStateKey]: fromTasks.TasksState;
}

export const sharedReducers: ActionReducerMap<SharedGlobalState> = {
  [routerStateKey]: routerReducer,
  [fromApp.APP_STATE_KEY]: fromApp.appReducer,
  [fromUser.userStateKey]: fromUser.userReducer,
  [fromSubjects.subjectsStateKey]: fromSubjects.subjectsReducer,
  [fromStudysets.studysetsStateKey]: fromStudysets.studysetsReducer,
  [fromStudysetInfos.studysetInfosStateKey]: fromStudysetInfos.studysetInfosReducer,
  [fromTextbooks.textbooksStateKey]: fromTextbooks.textbooksReducer,
  [fromSlidesets.slidesetsStateKey]: fromSlidesets.slidesetsReducer,
  [fromSummaries.summariesStateKey]: fromSummaries.summariesReducer,
  [fromStudygroups.studygroupsStateKey]: fromStudygroups.studygroupsReducer,
  [fromFlashcards.flashcardsStateKey]: fromFlashcards.flashcardsReducer,
  [fromTags.tagsStateKey]: fromTags.tagsReducer,
  [fromExercises.exercisesStateKey]: fromExercises.exercisesReducer,
  [fromStudyplanEvents.studyplanStateKey]: fromStudyplanEvents.studyplanReducer,
  [fromComponentStore.componentStoreStateKey]: fromComponentStore.componentStoreReducer,
  [fromTasks.tasksStateKey]: fromTasks.tasksReducer,
};
