<ui-info-card data-testid="info-card" isInteractive [uiDisabled]="isOffline || !hasEnoughFlashcards" [isContentAligned]="true">
  <ng-container slot="header">
    <span class="header">
      {{ "ORAL_QUIZ.LEARN_MODE_WIDGET.TITLE" | translate }}
      <ui-premium-locked-pill *ngIf="isLocked" />
      <ui-beta-badge />
    </span>
  </ng-container>

  <ng-container slot="description">
    <ng-container slot="description">
      <ng-container *ngIf="!isOffline" [ngTemplateOutlet]="descriptionWithoutQuizHistory" />
      <span *ngIf="isOffline" class="offline-message">
        <i role="img" class="icon app-icon-offline" aria-hidden="true"></i>
        {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.OFFLINE.BUTTON" | translate }}
      </span>

      <ng-template #descriptionWithoutQuizHistory>
        {{
          hasEnoughFlashcards
            ? ("ORAL_QUIZ.LEARN_MODE_WIDGET.DESCRIPTION" | translate)
            : ("FLASHCARDS.LEARN_MODES_CAROUSEL.SMART_EXAM.DISABLED_BUTTON_WITH_COUNT" | translate: { count: minimumExamQuantity })
        }}
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container slot="icon">
    <div class="icon-with-background">
      <i role="img" class="icon app-icon-microphone" aria-hidden="true"></i>
      <ui-dot-badge notificationBadge [badgeValue]="true" />
    </div>
  </ng-container>
</ui-info-card>
