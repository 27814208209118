import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  catchError,
  combineLatest,
  exhaustMap,
  filter,
  interval,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs';

import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { PlatformBaseService } from '@stsm/shared/services/platform-base.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { JsonObject } from '@stsm/shared/types/json-object';
import { switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { SystemMaintenanceInfo, SystemMaintenanceInfoSerializer } from '../models/system-maintenance';

import { SystemMaintenanceStore } from './system-maintenance-store.service';

const POLLING_INTERVAL_MS = 5 * 60 * 1000; // 5 minutes
const SYSTEM_MAINTENANCE_ROUTE: string = 'system-maintenance';

@Injectable({
  providedIn: 'root',
})
export class SystemMaintenanceService {
  private readonly _showExitAppDialog$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _systemMaintenanceStore: SystemMaintenanceStore,
    private readonly _router: Router,
    private readonly _httpClient: HttpClient,
    private readonly _routerStoreFacade: RouterStoreFacade,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(PLATFORM_SERVICE) private readonly _platformService: PlatformBaseService,
    @Inject(IS_MOBILE_APP) private readonly _isMobile: boolean,
    private readonly _translationService: TranslationService,
    private readonly _simpleDialogService: SimpleDialogService,
  ) {}

  init(): void {
    // If there is a systemMaintenanceInfo, start polling to keep the info up to date
    this._systemMaintenanceStore.systemMaintenanceInfo$
      .pipe(
        filter((info: SystemMaintenanceInfo) => !!info.plannedMaintenanceStart),
        switchMap(() => interval(POLLING_INTERVAL_MS).pipe(switchMap(() => this._fetchSystemMaintenanceInfo()))),
      )
      .subscribe();

    this._platformService
      .resumed$()
      .pipe(
        startWith(undefined),
        throttleTime(60 * 1000), // limited to once a minute
        exhaustMap(() => this._fetchSystemMaintenanceInfo()),
      )
      .subscribe();

    // Handle routing
    combineLatest([
      this._systemMaintenanceStore.isSystemMaintenanceOngoing$,
      this._routerStoreFacade.url$.pipe(startWith(this._router.url)),
    ]).subscribe(([isSystemMaintenanceOngoing, url]: [boolean, string]) => {
      if (isSystemMaintenanceOngoing && !url.includes(SYSTEM_MAINTENANCE_ROUTE)) {
        void this._router.navigate([SYSTEM_MAINTENANCE_ROUTE]);
      }

      if (!isSystemMaintenanceOngoing && url.includes(SYSTEM_MAINTENANCE_ROUTE)) {
        if (this._isMobile) {
          this._showExitAppDialog$.next();
        } else {
          void this._router.navigate(['/']).then(() => window.location.reload());
        }
      }
    });

    this._showExitAppDialog$.pipe(exhaustMap(() => this._notifyUserAboutAppClose())).subscribe();
  }

  private _fetchSystemMaintenanceInfo(): Observable<void> {
    console.debug('fetch system maintenance info');

    // timestamp param to prevent cache hit
    return this._httpClient.get<JsonObject>(this._environment.STATUS_URL, { params: { timestamp: Date.now() } }).pipe(
      map(SystemMaintenanceInfoSerializer.fromJson),
      tap((systemMaintenanceInfo: SystemMaintenanceInfo) => {
        this._systemMaintenanceStore.setSystemMaintenanceInfo(systemMaintenanceInfo);
      }),
      switchToVoid(),
      catchError((error: unknown) => {
        console.warn(error);

        return VOID;
      }),
    );
  }

  private _notifyUserAboutAppClose(): Observable<void> {
    return this._translationService.translationsReady$.pipe(
      take(1),
      switchMap(() => {
        return this._simpleDialogService
          .scheduleConfirm({
            heading: this._translationService.get('SYSTEM_MAINTENANCE.EXIT_APP_AND_RESTART'),
            confirmText: this._translationService.get(this._platformService.isAndroid ? 'SYSTEM_MAINTENANCE.EXIT_APP' : 'GLOBAL.OK'),
            allowBackdropDismiss: false,
          })
          .pipe(
            tap((isConfirmed: boolean) => {
              if (!isConfirmed) {
                return;
              }

              if (this._platformService.isDevice) {
                // does not work on iOS
                void App.exitApp();
              } else {
                void this._router.navigate(['/']).then(() => window.location.reload());
              }
            }),
          );
      }),
      switchToVoid(),
    );
  }
}
