import { Inject, Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { ActionId, ActionsHub, OpenFlashcardDashboardSettingsActionTrigger } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { FlashcardDashboardSettingsModalComponent } from './flashcard-dashboard-settings-modal.component';

@Injectable({
  providedIn: 'root',
})
export class FlashcardDashboardSettingsModalService {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(
      ActionId.OPEN_FLASHCARD_DASHBOARD_SETTINGS,
      ({ studyset }: OpenFlashcardDashboardSettingsActionTrigger) => this._showModal(studyset),
    );
  }

  init(): void {
    noop();
  }

  private async _showModal(studyset: Studyset): Promise<void> {
    this._analyticsService.trackEvent({ action: 'spaced-repetition_settings_open' });

    const hintModal = this._platformModalService.create({
      component: FlashcardDashboardSettingsModalComponent,
      data: {
        studyset,
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });

    return firstValueFrom(hintModal.willClose().pipe(switchToVoid()));
  }
}
