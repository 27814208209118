import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, Input, Signal } from '@angular/core';

import { ImportFlashcardsActionResponse } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { ZendeskLinksService } from '@stsm/global/composite/services/zendesk-links.service';
import { ZendeskArticleType } from '@stsm/global/models/zendesk-article-type';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { DialogHeaderComponent } from '@stsm/ui-components/dialogs/components/dialog-header/dialog-header.component';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { TabComponent, TabGroupComponent } from '@stsm/ui-components/tabs';

import { FlashcardImportAsTextComponent } from './flashcard-import-as-text/flashcard-import-as-text.component';
import { FlashcardImportXlsComponent } from './flashcard-import-xls/flashcard-import-xls.component';

@Component({
  selector: 'app-flashcard-import-dialog',
  templateUrl: './flashcard-import-dialog.component.html',
  styleUrls: ['./flashcard-import-dialog.component.scss'],
  imports: [
    TranslatePipe,
    NgIf,
    TabGroupComponent,
    FlashcardImportAsTextComponent,
    FlashcardImportXlsComponent,
    TabComponent,
    DialogHeaderComponent,
    IconButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashcardImportDialogComponent {
  @Input({ required: true }) studysetId!: number;

  protected readonly showHelp: Signal<boolean> = computed(() => this._targetMarketProvider.targetMarket() === 'core');

  constructor(
    private readonly _dialogRef: DialogRef<FlashcardImportDialogComponent, ImportFlashcardsActionResponse>,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _zendeskLinksService: ZendeskLinksService,
    private readonly _targetMarketProvider: TargetMarketProvider,
  ) {
    this._analyticsService.trackEvent({ action: 'import_flashcards_open' });
  }

  onShowHelp(): void {
    window.open(this._zendeskLinksService.getLocalizedLink(ZendeskArticleType.IMPORT_FLASHCARDS), '_blank');
  }

  close(didImportFlashcards: boolean = false): void {
    this._dialogRef.dismiss({ didImportFlashcards });
  }
}
