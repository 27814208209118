import { inject } from '@angular/core';
import { isNil, isNumber, noop } from 'lodash-es';
import { defer, finalize, Observable, of, switchMap, take } from 'rxjs';

import { ActionId, ActionsHub, ShareStudygroupActionTrigger, ShareStudysetActionTrigger } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { NetworkService } from '@stsm/shared/services/network.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { Studygroup } from '@stsm/studygroups/models/studygroup';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ShareStudysetLocation } from '@stsm/studysets/models/studyset-share-location';
import { StudysetsBaseService } from '@stsm/studysets/services/studysets-base.service';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { isOwnStudyset } from '@stsm/studysets/util/functions/is-own-studyset';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { LoadingService } from '@stsm/ui-components/loading-dialog';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { ShareStudysetDialogService } from './components/share-studyset-dialog/share-studyset-dialog.service';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to share a studyset / studygroup instead.
 */
export abstract class ShareStudysetBaseService {
  protected readonly translationService: TranslationService = inject(TranslationService);
  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly toastService: ToastService = inject(ToastService);

  private readonly _simpleDialogService: SimpleDialogService = inject(SimpleDialogService);
  private readonly _studysetsService: StudysetsBaseService = inject(STUDYSETS_SERVICE);
  private readonly _loadingService: LoadingService = inject(LoadingService);
  private readonly _studysetsStoreFacade: StudysetsStoreFacade = inject(StudysetsStoreFacade);
  private readonly _networkService: NetworkService = inject(NetworkService);
  private readonly _targetMarketProvider: TargetMarketProvider = inject(TargetMarketProvider);
  private readonly _shareStudysetDialogService: ShareStudysetDialogService = inject(ShareStudysetDialogService);
  private readonly _analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);
  private readonly _actionsHub: ActionsHub = inject(ActionsHub);

  protected constructor() {
    this._actionsHub.registerActionHandler(ActionId.SHARE_STUDYSET, (trigger: ShareStudysetActionTrigger) => {
      this._shareStudyset(trigger);

      return Promise.resolve();
    });

    this._actionsHub.registerActionHandler(ActionId.SHARE_STUDYGROUP, (trigger: ShareStudygroupActionTrigger) => {
      this._shareStudygroup(trigger);

      return Promise.resolve();
    });
  }

  init(): void {
    noop();
  }

  protected getLinkForStudyset(studyset: Studyset, user: User): string {
    const url = new URL(`https://${this._targetMarketProvider.resolveWebAppUrl()}/studyset/${studyset.id}`);

    url.searchParams.append('ref', user.refId);
    url.searchParams.append('ref_type', 'studyset_share');

    return url.toString();
  }

  protected getLinkForStudygroup(studyset: Studyset, studygroup: Studygroup, user: User): string {
    const url = new URL(`https://${this._targetMarketProvider.resolveWebAppUrl()}/contribute`);

    url.searchParams.append('token', studygroup.token);
    url.searchParams.append('studyset_id', studyset.id.toString());
    url.searchParams.append('ref', user.refId);
    url.searchParams.append('ref_type', 'studyset_share');

    return url.toString();
  }

  private _shareStudyset({ studysetOrId, location }: ShareStudysetActionTrigger): void {
    if (!this._networkService.isConnected()) {
      return this._showOfflineAlert();
    }

    defer(() => (isNumber(studysetOrId) ? this._fetchStudyset(studysetOrId) : of(studysetOrId))).subscribe((studyset: Studyset) => {
      if (isOwnStudyset(studyset)) {
        this._shareStudysetDialogService.openDialog({
          studysetId: studyset.id,
          shareStudysetLink: (params: { studyset: Studyset; location?: ShareStudysetLocation }) => this.shareStudysetLink(params),
          shareStudygroup: (trigger: ShareStudygroupActionTrigger) => this._shareStudygroup(trigger),
        });
      } else {
        void this.shareStudysetLink({ studyset, location });
      }
    });
  }

  private _shareStudygroup({ studyset, studygroup }: ShareStudygroupActionTrigger): void {
    // Although right now it is not necessary to use the parent studyset because the studyset_id in
    // the shared link is not used, we use it anyway in case we want to create a preview when
    // joining a studygroup in the future. In this case it is important that we have the studysetId
    // of the parent.
    defer(() => (studyset.parentId ? this._fetchStudyset(studyset.parentId) : of(studyset))).subscribe((parentStudyset: Studyset) => {
      this._analyticsService.trackEvent({ action: 'studygroup_share_link' });
      void this.shareStudygroupLink(parentStudyset, studygroup);
    });
  }

  private _fetchStudyset(studysetId: number): Observable<Studyset> {
    return this._studysetsStoreFacade.studysetByIdUnfiltered(studysetId).pipe(
      take(1),
      switchMap((studyset: Studyset | undefined) => {
        if (!isNil(studyset)) {
          return of(studyset);
        } else {
          this._loadingService.showLoading();

          return this._studysetsService
            .getStudyset(studysetId, { shouldFetchOnline: true })
            .pipe(finalize(() => this._loadingService.hideLoading()));
        }
      }),
    );
  }

  private _showOfflineAlert(): void {
    this._simpleDialogService.showAlertMessage('OFFLINE_MODE.DISCLAIMERS.SHARING_OFFLINE_ALERT');
  }

  protected abstract shareStudysetLink(params: { studyset: Studyset; location?: ShareStudysetLocation }): Promise<void>;
  protected abstract shareStudygroupLink(studyset: Studyset, studygroup: Studygroup): Promise<void>;
}
