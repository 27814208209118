import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';

export interface PremiumLimitToastComponentInput {
  heading: string;
  description: string;
  hasUsedTrial: boolean;
}

@Component({
  selector: 'app-premium-limit-toast',
  imports: [TranslatePipe, IconButtonComponent, ButtonComponent],
  templateUrl: './premium-limit-toast.component.html',
  styleUrl: './premium-limit-toast.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'data-cy': 'premium-limit-toast' },
})
export class PremiumLimitToastComponent {
  protected readonly LogoSource: typeof LogoSource = LogoSource;

  constructor(
    private readonly _snackBarRef: MatSnackBarRef<PremiumLimitToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) protected readonly data: PremiumLimitToastComponentInput,
  ) {}

  protected dismiss(): void {
    this._snackBarRef.dismiss();
  }

  protected openPremiumModal(): void {
    this._snackBarRef.dismissWithAction();
  }
}
