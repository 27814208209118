import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom, switchMap } from 'rxjs';

import { ActionId, ActionsHub, PerformTaskActionTrigger } from '@stsm/actions-hub';
import { TaskTrackingSource } from '@stsm/analytics/models/source-property-types';
import { datesAreOnSameDay } from '@stsm/date/global/functions/dates-are-on-same-day';
import { SpacedRepetitionMode } from '@stsm/flashcards/types/spaced-repetition';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { Id } from '@stsm/shared/types/id';
import { SpacedRepetitionService } from '@stsm/spaced-repetition/services/spaced-repetition.service';
import { StudyplanTask } from '@stsm/studyplan/models/studyplan-task';
import { TasksAnalyticsService } from '@stsm/studyplan/services/tasks-analytics.service';
import { supportedTaskTypes } from '@stsm/studyplan/store/tasks/task.selectors';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';

/**
 * DO NOT INJECT this service! Use the {@link ActionsHub} to perform a task instead.
 */
@Injectable({ providedIn: 'root' })
export class LearnModeTaskService {
  constructor(
    private readonly _loggerService: LoggerService,
    private readonly _tasksAnalyticsService: TasksAnalyticsService,
    private readonly _actionsHub: ActionsHub,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _spacedRepetitionService: SpacedRepetitionService,
  ) {
    this._actionsHub.registerActionHandler(ActionId.PERFORM_TASK, ({ task, trackingSource }: PerformTaskActionTrigger) => {
      this._performTask(task, trackingSource);

      return Promise.resolve();
    });
  }

  init(): void {
    noop();
  }

  private _performTask(task: StudyplanTask, source: TaskTrackingSource): void {
    if (!supportedTaskTypes.includes(task.type)) {
      this._loggerService.warn(`Task type ${task.type} is not yet supported`);

      return;
    }

    if (task.type === 'partner_event') {
      this._tasksAnalyticsService.trackTaskCompleted(task);

      return;
    }

    if (!task.studysetId) {
      this._loggerService.warn(`Task ${task.id} has no studyset ID`);

      return;
    }

    if (task.type === 'test') {
      this._tasksAnalyticsService.trackTaskOpen(task, source);
      void this._actionsHub.triggerAction(ActionId.START_QUIZ, { studysetOrId: task.studysetId, trackingSource: source, taskId: task.id });

      return;
    }

    if (['learn', 'review'].includes(task.type)) {
      this._tasksAnalyticsService.trackTaskOpen(task, source);
      void this._startSpacedRepetition(task.type as SpacedRepetitionMode, task.studysetId, task, source);
    }
  }

  private async _startSpacedRepetition(
    action: SpacedRepetitionMode,
    studysetId: Id,
    task: StudyplanTask,
    trackingSource: TaskTrackingSource,
  ): Promise<void> {
    const isTaskToday = datesAreOnSameDay(task.date, new Date());
    const taskId = isTaskToday ? task.id : undefined;

    const isInStudysetPage = trackingSource === 'studyset_tasklist';

    await firstValueFrom(
      this._studysetsStoreFacade.studysetById(studysetId).pipe(
        switchMap((studyset: Studyset) =>
          this._spacedRepetitionService.performSpacedRepetitionAction(
            action,
            studyset,
            {
              trackingSource,
              ...(taskId ? { taskId } : {}),
            },
            !isInStudysetPage,
          ),
        ),
      ),
    );
  }
}
