import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  Input,
  input,
  InputSignal,
  OnInit,
  output,
  OutputEmitterRef,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { head } from 'lodash-es';
import { concat, map, Observable, of, ReplaySubject, switchMap, take, tap, timer, withLatestFrom } from 'rxjs';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { StreakModalOpenSource } from '@stsm/analytics/models/properties-per-event-action';
import { DeviceStore } from '@stsm/global/composite/services/device-store.service';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationKey } from '@stsm/i18n/models/translation-key';
import { StudyplanCardComponent } from '@stsm/notifications/components/studyplan-card/studyplan-card.component';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { Streak, StreakDayStatus } from '@stsm/streak/models/streak';
import { StreakIcon } from '@stsm/streak/models/streak-icon';
import { StreakStore } from '@stsm/streak/services/streak-store';
import { StudyplanTask } from '@stsm/studyplan/models/studyplan-task';
import { TasksStoreFacade } from '@stsm/studyplan/store/tasks/tasks-store-facade.service';
import { ButtonComponent } from '@stsm/ui-components/button';
import { AnimatedEmoteDirective } from '@stsm/ui-components/lottie-view/animated-emote.directive';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { StreakCalendarComponent } from '../streak-calendar/streak-calendar.component';

import { StreakInfoAnimation } from './streak-info.animation';

@UntilDestroy()
@Component({
  selector: 'app-streak-info',
  imports: [AnimatedEmoteDirective, TranslatePipe, StudyplanCardComponent, LetDirective, ButtonComponent, StreakCalendarComponent],
  templateUrl: './streak-info.component.html',
  styleUrl: './streak-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.inactive]': 'isInactive()',
    'data-cy': 'streak-dialog',
  },
  animations: StreakInfoAnimation,
})
export class StreakInfoComponent implements OnInit {
  @Input({ required: true })
  source!: StreakModalOpenSource;

  readonly animationDelay: InputSignal<number> = input(1000);

  readonly didPerformTask: OutputEmitterRef<void> = output<void>();

  protected readonly streak: Signal<Streak> = this._streakStore.streak;

  protected readonly streakStatusToday$: Observable<StreakDayStatus> = this._streakStore.streakStatusToday$;

  protected readonly streakStatusTomorrow$: Observable<StreakDayStatus> = this._streakStore.streakStatusTomorrow$;

  protected readonly isInactive: Signal<boolean> = computed(() => this.streakNumberAnimation() === 0);

  protected readonly isLearningPlanEnabledOnOpen: WritableSignal<boolean> = signal(false);

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected readonly user$: Observable<User> = this._userStoreFacade.user$;

  protected readonly notificationsEnabled$: Observable<boolean> = this._deviceStore.notificationsEnabled$;

  protected readonly motivationalMessage: Signal<TranslationKey> = computed(() => {
    if (this._streakStore.streakStatusToday() === StreakDayStatus.ACHIEVED) {
      return 'STREAK.MOTIVATIONAL.TODAY_ACHIEVED';
    }

    return 'STREAK.MOTIVATIONAL.DEFAULT';
  });

  protected readonly messageAnimationState: WritableSignal<string> = signal('final');

  protected readonly ready$: ReplaySubject<void> = new ReplaySubject<void>(1);

  /**
   * This observable controls the streak number animation:
   * 1. If an animation is pending:
   *    - First shows the previous streak number
   *    - Delays for 1 second
   *    - Then shows the new (current) streak number
   * 2. If no animation is pending:
   *    - Immediately displays the current streak number
   */
  protected readonly streakNumberAnimation$: Observable<number> = this.ready$.pipe(
    switchMap(() => this._streakStore.isCelebrationPending$),
    withLatestFrom(this._streakStore.streak$),
    switchMap(([isCelebrationPending, streak]: [boolean, Streak]) => {
      if (isCelebrationPending) {
        this.messageAnimationState.set('initial');

        return concat(
          of(streak.currentStreak - 1),
          timer(this.animationDelay()).pipe(
            tap(() => {
              this.messageAnimationState.set('final');
              this._streakStore.finishCelebration();
            }),
            map(() => streak.currentStreak),
          ),
        );
      } else {
        this.messageAnimationState.set('final');

        return of(streak.currentStreak);
      }
    }),
  );

  protected readonly streakNumberAnimation: Signal<number> = toSignal(this.streakNumberAnimation$, { initialValue: 0 });

  protected readonly nextTask$: Observable<StudyplanTask | undefined> = this._tasksStoreFacade.uncompletedStudyplanTasks$.pipe(
    map((tasks: StudyplanTask[]) => head(tasks)),
  );

  protected readonly StreakDayStatus: typeof StreakDayStatus = StreakDayStatus;
  protected readonly StreakIcon: typeof StreakIcon = StreakIcon;

  protected readonly streakActionButtonText: Signal<TranslationKey> = computed(() => {
    return this.streak().currentStreak > 0 ? 'STREAK.ACTION.START_NOW' : 'STREAK.ACTION.START_STREAK';
  });

  constructor(
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _layoutStore: LayoutStore,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _deviceStore: DeviceStore,
    private readonly _streakStore: StreakStore,
    private readonly _tasksStoreFacade: TasksStoreFacade,
    private readonly _actionsHub: ActionsHub,
  ) {}

  ngOnInit(): void {
    this.ready$.next();

    this._analyticsService.trackEvent({
      action: 'streak_modal_open',
      properties: {
        source: this.source,
        currentStreak: this.streak().currentStreak,
        isStreakLost: this._streakStore.isStreakLost(),
      },
    });

    this.user$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((user: User) => this.isLearningPlanEnabledOnOpen.set(user.settings.learningPlanEnabled));
  }

  protected performTask(task: StudyplanTask): void {
    this._performStudyplanTask(task);
    this.didPerformTask.emit();
  }

  private _performStudyplanTask(task: StudyplanTask): void {
    void this._actionsHub.triggerAction(ActionId.PERFORM_TASK, { task, trackingSource: 'streak' });
  }
}
