<ui-dialog-template showCloseButton [heading]="'FEEDBACK.HELP_CENTER.HEADING' | translate">
  <div slot="content" class="options">
    <!-- GIVE FEEDBACK -->
    <ui-info-card
      icon="app-icon-chatbox-ellipsis"
      isInteractive
      data-cy="help-center-feedback-option"
      (click)="chooseOption('give-feedback')"
    >
      <span slot="header">{{ "FEEDBACK.HELP_CENTER.GIVE_FEEDBACK_HEADING" | translate }}</span>
      <span slot="description">{{ "FEEDBACK.HELP_CENTER.GIVE_FEEDBACK_DESCRIPTION" | translate }}</span>
    </ui-info-card>

    <!-- OPEN EXTERNAL HELP CENTER (ZENDESK) -->
    <ui-info-card
      icon="app-icon-help-buoy"
      isInteractive
      data-cy="help-center-external-option"
      (click)="chooseOption('external-help-center')"
    >
      <span slot="header">{{ "FEEDBACK.HELP_CENTER.OPEN_HELP_CENTER_HEADING" | translate }}</span>
      <span slot="description">{{ "FEEDBACK.HELP_CENTER.OPEN_HELP_CENTER_DESCRIPTION" | translate }}</span>
      <i slot="options" class="app-icon-external-link"></i>
    </ui-info-card>

    <!-- BUG REPORT -->
    <ui-info-card icon="app-icon-bug" isInteractive data-cy="help-center-bug-report-option" (click)="chooseOption('bug-report')">
      <span slot="header">{{ "FEEDBACK.HELP_CENTER.BUG_REPORT_HEADING" | translate }}</span>
      <span slot="description">{{ "FEEDBACK.HELP_CENTER.BUG_REPORT_DESCRIPTION" | translate }}</span>
    </ui-info-card>
  </div>
</ui-dialog-template>
