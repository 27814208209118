import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ProgressSection } from '@stsm/global/models/progress-section';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { NumberWithUnitsPipe } from '@stsm/shared/pipes/number-with-units/number-with-units.pipe';
import { AppColor } from '@stsm/shared/util/color-util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { CircleMultipleProgressbarComponent } from '@stsm/ui-components/circle-multiple-progressbar';
import { DisabledDirective } from '@stsm/ui-components/disabled';
import { InfoCardComponent } from '@stsm/ui-components/info-card';

@Component({
  selector: 'app-practice-learn-mode-widget',
  imports: [InfoCardComponent, CircleMultipleProgressbarComponent, DisabledDirective, TranslatePipe, NumberWithUnitsPipe, NgIf],
  templateUrl: './practice-learn-mode-widget.component.html',
  styleUrls: ['../learn-mode-widgets.style.scss', './practice-learn-mode-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'practice-learn-mode-widget',
  },
})
export class PracticeLearnModeWidgetComponent {
  @Input() isOffline: boolean = false;
  @Input() isStudysetSynchronized: boolean = false;
  @Input() hasStartedPractice: boolean = false;
  @Input() disabled: boolean = false;
  @Input() studyset?: Studyset;

  @Output() readonly startPractice: EventEmitter<void> = new EventEmitter<void>();

  get descriptionTranslationKey(): string {
    return this.hasStartedPractice
      ? 'FLASHCARDS.LEARN_MODES_CAROUSEL.PRACTICE.LEARNED_COUNT'
      : 'FLASHCARDS.LEARN_MODES_CAROUSEL.PRACTICE.AB_DESCRIPTION';
  }

  get isOfflinePracticeDisabled(): boolean {
    return this.isOffline && !this.isStudysetSynchronized;
  }

  get hasLearnedAll(): boolean {
    return this.studyset?.flashcardGoodCount === this.studyset?.flashcardCount;
  }

  get progressSections(): ProgressSection[] {
    if (!this.studyset) {
      return [];
    }

    const { flashcardCount, flashcardGoodCount, flashcardMediumCount, flashcardBadCount, flashcardTrashCount } = this.studyset;

    return [
      {
        color: AppColor.FEEDBACK_GOOD,
        progress: flashcardGoodCount,
      },
      {
        color: AppColor.FEEDBACK_MEDIUM,
        progress: flashcardMediumCount,
      },
      {
        color: AppColor.FEEDBACK_BAD,
        progress: flashcardBadCount,
      },
      {
        color: AppColor.FEEDBACK_TRASH,
        progress: flashcardTrashCount,
      },
    ]
      .filter(({ progress }: ProgressSection) => progress > 0)
      .map((section: ProgressSection) => {
        return {
          ...section,
          progress: Math.round((section.progress / flashcardCount) * 100),
        };
      });
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.studyset || this.isOfflinePracticeDisabled || this.disabled) {
      return;
    }

    this.startPractice.emit();
  }
}
