import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

import { StreakModalOpenSource } from '@stsm/analytics/models/properties-per-event-action';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

import { StreakInfoComponent } from '../streak-info/streak-info.component';

@Component({
  selector: 'app-streak-dialog',
  imports: [StreakInfoComponent, IconButtonComponent],
  templateUrl: './streak-dialog.component.html',
  styleUrl: './streak-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreakDialogComponent {
  readonly source: InputSignal<StreakModalOpenSource> = input.required();

  constructor(private readonly _dialogRef: DialogRef) {}

  closeDialog(): void {
    this._dialogRef.dismiss();
  }
}
