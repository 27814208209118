import { AsyncPipe, NgForOf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noop } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { getWeekdays } from '@stsm/date/global/functions/get-weekdays';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/global/services/translation.service';
import { Language } from '@stsm/i18n/models/language';
import { trackByDefault } from '@stsm/shared/util/generic-utils';
import { tapOnce } from '@stsm/shared/util/rxjs.util';
import { ListOptionDirective, SelectionListComponent } from '@stsm/ui-components/selection-list';

@UntilDestroy()
@Component({
  selector: 'app-weekday-picker',
  templateUrl: './weekday-picker.component.html',
  styleUrls: ['./weekday-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgForOf, ReactiveFormsModule, TranslatePipe, SelectionListComponent, ListOptionDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekdayPickerComponent),
      multi: true,
    },
  ],
})
export class WeekdayPickerComponent implements ControlValueAccessor, OnInit {
  @Input({ transform: booleanAttribute })
  @HostBinding('attr.multiple')
  multiple: boolean = false;

  protected readonly weekdayControl: FormControl<number[]> = new FormControl<number[]>([], { nonNullable: true });

  protected readonly weekdayLabels$: Observable<string[]> = this._translationService.language$.pipe(
    map((language: Language) => getWeekdays(language.value, 'narrow')),
  );

  protected readonly trackByDefault: TrackByFunction<string> = trackByDefault;

  protected readonly weekdayLabelsLong: string[] = getWeekdays(this._translationService.currentLanguage(), 'long');

  @ViewChild(SelectionListComponent)
  private readonly _selectionList?: SelectionListComponent<number[]>;

  private _onChange: (value: number[] | number | undefined) => void = noop;
  private _onTouched: () => void = noop;

  constructor(private readonly _translationService: TranslationService) {}

  ngOnInit(): void {
    this.weekdayControl.valueChanges
      .pipe(
        tapOnce(() => this._onTouched()),
        untilDestroyed(this),
      )
      .subscribe((value: number[]) => {
        this._onChange(this.multiple ? [...value].sort() : value[0]);
      });
  }

  writeValue(weekdays: number[]): void {
    this.weekdayControl.setValue(weekdays);
  }

  registerOnChange(onChange: (weekdays: number[] | number | undefined) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._selectionList?.setDisabledState(isDisabled);
  }
}
