import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, Observable, tap } from 'rxjs';

import { weeksToMilliseconds } from '@stsm/date/models/duration';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import * as UserActions from '@stsm/user/store/user.actions';
import { UserEffectsBase } from '@stsm/user/store/user.effects.base';

import { CompleteProfileService } from '../../+auth/complete-profile/complete-profile.service';

const IS_LOGGED_IN_COOKIE = 'stsmIsLoggedIn';

@Injectable()
export class UserEffects extends UserEffectsBase {
  onIsUserLoggedInAction$ = createEffect(
    () =>
      this.authStore.isUserLoggedIn$.pipe(
        distinctUntilChanged(),
        tap((isUserLoggedIn: boolean) => {
          if (isUserLoggedIn) {
            this.browserStorageService.setItemCookie(IS_LOGGED_IN_COOKIE, true, weeksToMilliseconds(1));
          } else {
            this.browserStorageService.removeItemCookie(IS_LOGGED_IN_COOKIE);
          }
        }),
      ),
    { dispatch: false },
  );

  actionsOnLogout$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => {
          this._completeProfileService.dismiss();
        }),
        switchToVoid(),
      ),
    { dispatch: false },
  );

  constructor(private readonly _completeProfileService: CompleteProfileService) {
    super();
  }
}
