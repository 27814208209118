import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LocaleDateStringPipe } from '@stsm/i18n/global/pipes/locale-date-string.pipe';
import { CheckboxComponent } from '@stsm/ui-components/checkbox';

@UntilDestroy()
@Component({
  selector: 'app-file-item',
  imports: [CheckboxComponent, LocaleDateStringPipe, ReactiveFormsModule, NgIf],
  templateUrl: './file-item.component.html',
  styleUrl: './file-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'file-item',
  },
})
export class FileItemComponent {
  @Input()
  @HostBinding('class.selected')
  isChecked: boolean = false;

  @Input()
  @HostBinding('class.disabled')
  isDisabled: boolean = false;

  @Input() fileType: 'pdf' | undefined;
  @Input({ required: true }) fileName!: string;
  @Input({ required: true }) updatedAt: string | undefined;

  @Output() readonly valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get fileTypeIcon(): string {
    if (this.fileType === 'pdf') {
      return this.pdfIconSrc;
    }

    return this.blankIconSrc;
  }

  readonly blankIconSrc: string = 'assets/shared/svgs/file-icon-blank.svg';
  readonly pdfIconSrc: string = 'assets/shared/svgs/file-icon-pdf.svg';
}
