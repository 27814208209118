import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { QuizHistory } from '@stsm/quiz/models/quiz-history';
import { Studyset } from '@stsm/studysets/models/studyset';
import { BetaBadgeComponent } from '@stsm/ui-components/beta-badge/beta-badge.component';
import { DisabledDirective } from '@stsm/ui-components/disabled';
import { DotBadgeComponent } from '@stsm/ui-components/dot-badge/dot-badge.component';
import { InfoCardComponent } from '@stsm/ui-components/info-card';
import { PremiumLockedPillComponent } from '@stsm/ui-components/premium-locked-pill';

@Component({
  selector: 'app-oral-quiz-learn-mode-widget',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    TranslatePipe,
    DisabledDirective,
    InfoCardComponent,
    PremiumLockedPillComponent,
    DotBadgeComponent,
    BetaBadgeComponent,
  ],
  templateUrl: './oral-quiz-learn-mode-widget.component.html',
  styleUrls: ['../learn-mode-widgets.style.scss', './oral-quiz-learn-mode-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'oral-quiz-learn-mode-widget',
  },
})
export class OralQuizLearnModeWidgetComponent {
  @Input() studyset?: Studyset;
  @Input() isOffline: boolean = false;
  @Input() minimumExamQuantity: number = 3;
  @Input() quizHistory?: QuizHistory;
  @Input() disabled: boolean = false;
  @Input() isLocked: boolean = false;

  @Output() readonly startTutor: EventEmitter<void> = new EventEmitter<void>();

  get hasEnoughFlashcards(): boolean {
    return this.studyset?.flashcardCount ? this.studyset.flashcardCount >= this.minimumExamQuantity : false;
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.studyset || this.isOffline || !this.hasEnoughFlashcards) {
      return;
    }

    this.startTutor.emit();
  }
}
