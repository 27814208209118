<app-spaced-repetition-learn-mode-widget
  class="widget"
  [studyset]="studyset"
  [isOffline]="isOffline()"
  [chunkSize]="(chunkSize$ | async) ?? 10"
  [class.spaced-repetition-started]="studyset?.isSpacedRepetitionStarted ?? false"
  (spacedRepetitionOptionsClick)="spacedRepetitionOptionsClick()"
  (startSpacedRepetition)="startSpacedRepetition('start')"
  (spacedRepetitionReviewModeButtonClick)="startSpacedRepetition(SpacedRepetitionMode.REVIEW)"
  (spacedRepetitionLearnModeButtonClick)="startSpacedRepetition(SpacedRepetitionMode.LEARN)"
/>

<app-practice-learn-mode-widget
  class="widget"
  [studyset]="studyset"
  [isOffline]="isOffline()"
  [hasStartedPractice]="hasStartedPractice"
  [isStudysetSynchronized]="isStudysetSynchronized()"
  (startPractice)="practice()"
/>

<app-ai-learn-mode-widget
  class="widget"
  [isOffline]="isOffline()"
  [studyset]="studyset"
  [minimumExamQuantity]="minimumExamQuantity"
  [quizHistory]="(latestQuizHistory$ | async) ?? undefined"
  (startExam)="startSmartExam()"
/>
@if (isDogfoodingUser()) {
  <app-oral-quiz-learn-mode-widget
    class="widget"
    [isOffline]="isOffline()"
    [studyset]="studyset"
    [minimumExamQuantity]="minimumExamQuantity"
    [quizHistory]="(latestQuizHistory$ | async) ?? undefined"
    [isLocked]="hasReachedPremiumLimit()"
    (startTutor)="startOralQuiz()"
  />
}
