import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isNil } from 'lodash-es';

import { createSafeDate } from '@stsm/date/global/functions/safe-date';
import { SpacedRepetitionLearnState } from '@stsm/flashcards/types/spaced-repetition-learn-state';
import { DateFormatService } from '@stsm/global/composite/services/date-format.service';
import { ProgressSection } from '@stsm/global/models/progress-section';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { AppColor } from '@stsm/shared/util/color-util';
import { SpacedRepetitionUtils } from '@stsm/spaced-repetition/util/spaced-repetition-utils';
import { Studyset } from '@stsm/studysets/models/studyset';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { CircleMultipleProgressbarComponent } from '@stsm/ui-components/circle-multiple-progressbar';
import { DisabledDirective } from '@stsm/ui-components/disabled';
import { InfoCardComponent } from '@stsm/ui-components/info-card';

@Component({
  selector: 'app-spaced-repetition-learn-mode-widget',
  imports: [
    TranslatePipe,
    DisabledDirective,
    InfoCardComponent,
    CircleMultipleProgressbarComponent,
    NgIf,
    NgTemplateOutlet,
    IconButtonComponent,
  ],
  templateUrl: './spaced-repetition-learn-mode-widget.component.html',
  styleUrls: ['../learn-mode-widgets.style.scss', './spaced-repetition-learn-mode-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'spaced-repetition-learn-mode-widget',
  },
})
export class SpacedRepetitionLearnModeWidgetComponent {
  @Input() isOffline: boolean = false;
  @Input() chunkSize: number = 10;
  @Input() studyset?: Studyset;
  @Input() showOptions: boolean = true;

  @Output() readonly spacedRepetitionLearnModeButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly spacedRepetitionReviewModeButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly spacedRepetitionOptionsClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly startSpacedRepetition: EventEmitter<void> = new EventEmitter<void>();

  get learnChunkSize(): number {
    if (!this.studyset) {
      return this.chunkSize;
    }

    const flashcardsLeftToLearn: number =
      SpacedRepetitionUtils.getFlashcardCount(this.studyset) -
      this.reviewCount -
      SpacedRepetitionUtils.getAllLearnedFlashcardsCount(this.studyset);

    return flashcardsLeftToLearn > this.chunkSize ? this.chunkSize : flashcardsLeftToLearn;
  }

  get reviewCount(): number {
    return SpacedRepetitionUtils.getReviewCount(this.studyset);
  }

  get progressSections(): ProgressSection[] {
    if (!this.studyset) {
      return SpacedRepetitionUtils.DEFAULT_PROGRESS_SECTIONS;
    }

    const sections: ProgressSection[] = SpacedRepetitionUtils.getProgressSections(
      this.studyset,
      AppColor.ACCENT_BRIGHT,
      AppColor.ACCENT,
    ).filter(({ progress }: ProgressSection) => progress > 0);

    if (SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset) !== SpacedRepetitionLearnState.LEARN) {
      return sections;
    }

    sections.push({
      progress: Math.round((this.learnChunkSize / this.studyset.flashcardCount) * 100),
      color: AppColor.ACCENT_BRIGHT,
    });

    return sections;
  }

  get nextReviewDate(): string | undefined {
    if (!this.studyset || this.isReview || !this.studyset.nextRepetition) {
      return undefined;
    }

    const nextReviewDate: Date = createSafeDate(this.studyset.nextRepetition);

    return this._dateFormatService.getDateDifference(nextReviewDate, { daysLimit: this.daysLimit });
  }

  get descriptionTranslationKey(): string {
    if (!this.studyset) {
      return '';
    }

    if (this.isReview) {
      return 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.REVIEW.READY';
    }

    if (
      [SpacedRepetitionLearnState.LEARN, SpacedRepetitionLearnState.LEARNED].includes(
        SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset),
      ) &&
      this.nextReviewDate
    ) {
      return 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.REVIEW.NEXT_REVIEW';
    }

    return 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.AB_DESCRIPTION';
  }

  get titleTranslationKey(): string {
    const titleByLearnState: Record<SpacedRepetitionLearnState, string> = {
      [SpacedRepetitionLearnState.LEARN]: 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.REVIEW.LEARN_FLASHCARDS',
      [SpacedRepetitionLearnState.REVIEW]: 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.REVIEW.TITLE',
      [SpacedRepetitionLearnState.LEARNED]: 'FLASHCARDS.LEARN_MODES_CAROUSEL.SPACED_REPETITION.ALL_LEARNED',
    };

    return !isNil(this.studyset) ? titleByLearnState[SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset)] : '';
  }

  get isReview(): boolean {
    return !isNil(this.studyset) && SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset) === SpacedRepetitionLearnState.REVIEW;
  }

  get isLearning(): boolean {
    return !isNil(this.studyset) && SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset) === SpacedRepetitionLearnState.LEARN;
  }

  get isLearned(): boolean {
    return !isNil(this.studyset) && SpacedRepetitionUtils.getLearnStateFromStudyset(this.studyset) === SpacedRepetitionLearnState.LEARNED;
  }

  readonly daysLimit: number = 15;

  constructor(private readonly _dateFormatService: DateFormatService) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();

    if (!this.studyset || this.isOffline) {
      return;
    }

    if (!this.studyset.isSpacedRepetitionStarted) {
      this.startSpacedRepetition.emit();

      return;
    }

    if (this.isReview) {
      this.spacedRepetitionReviewModeButtonClick.emit();

      return;
    }

    if (this.isLearning) {
      this.spacedRepetitionLearnModeButtonClick.emit();
    }
  }

  onOptionsClick(event: Event): void {
    if (this.isOffline) {
      return;
    }

    event.stopImmediatePropagation();
    this.spacedRepetitionOptionsClick.emit();
  }
}
