import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { isOfflineError } from '@stsm/offline-mode/models/offline-error';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { Studygroup } from '@stsm/studygroups/models/studygroup';
import { StudygroupUpdateBuilder } from '@stsm/studygroups/models/studygroup-update-builder';
import { StudygroupsService } from '@stsm/studygroups/services/studygroups.service';
import { StudygroupsStoreFacade } from '@stsm/studygroups/store/studygroups-store-facade.service';
import { Studyset } from '@stsm/studysets/models/studyset';
import { AccordionComponent, AccordionItemComponent } from '@stsm/ui-components/accordion';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { SlideToggleComponent } from '@stsm/ui-components/slide-toggle';

@UntilDestroy()
@Component({
  selector: 'app-studygroup-settings',
  templateUrl: './studygroup-settings.component.html',
  styleUrls: ['./studygroup-settings.component.scss'],
  imports: [TranslatePipe, SlideToggleComponent, AccordionComponent, AccordionItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudygroupSettingsComponent {
  @Input({ required: true }) studyset!: Studyset;
  @Input({ required: true }) studygroup!: Studygroup;

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _studygroupsService: StudygroupsService,
    private readonly _studygroupsStoreFacade: StudygroupsStoreFacade,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _loggerService: LoggerService,
  ) {}

  onAllowInviteChange(shouldAllowInviting: boolean): void {
    const builder = new StudygroupUpdateBuilder(this.studygroup).allowInviting(shouldAllowInviting);
    this._updateStudygroup(builder);

    this._analyticsService.trackEvent({
      action: 'studygroup_change_setting',
      properties: {
        setting: 'allow_inviting',
        value: shouldAllowInviting,
      },
    });
  }

  onAllowJoiningChange(shouldAllowJoining: boolean): void {
    const builder = new StudygroupUpdateBuilder(this.studygroup).allowJoining(shouldAllowJoining);
    this._updateStudygroup(builder);

    this._analyticsService.trackEvent({
      action: 'studygroup_change_setting',
      properties: {
        setting: 'allow_joining',
        value: shouldAllowJoining,
      },
    });
  }

  private _updateStudygroup(builder: StudygroupUpdateBuilder): void {
    this._studygroupsService
      .updateStudygroup(this.studyset, builder)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => this._studygroupsStoreFacade.updateStudygroup(builder.partialUpdate),
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._showErrorAlert(error);
        },
      });
  }

  private _showErrorAlert(error: Error): void {
    if (isOfflineError(error)) {
      this._simpleDialogService.showAlertMessage(error.type);
    } else {
      this._simpleDialogService.showError();
    }
  }
}
