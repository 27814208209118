<form [formGroup]="form">
  <div class="row">
    <div class="form-group select-container">
      <ui-form-field>
        <label uiLabel>{{ "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.FORMAT.LABEL" | translate }}</label>
        <ui-select
          data-cy="generate-flashcards-settings-form-format-select"
          [formControl]="form.controls.format"
          [placeholder]="'AI_GENERATION.ADVANCED_SETTINGS_DIALOG.FORMAT.LABEL' | translate"
        >
          @for (format of flashcardFormats; track $index) {
            <ui-option [value]="format.value" [attr.data-cy]="'generate-flashcards-settings-form-format-option-' + format.value">
              <span class="text-with-premium-pill">
                {{ format.option | translate }}
              </span>
            </ui-option>
          }
        </ui-select>
      </ui-form-field>
    </div>

    <div class="horizontal-form-group">
      <ui-form-field>
        <label uiLabel>{{
          (showSecondLanguageInput
            ? "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.LANGUAGE_TERM"
            : "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.LANGUAGE"
          ) | translate
        }}</label>
        <ui-language-input
          formControlName="language"
          data-cy="generate-flashcards-settings-form-language-input"
          [allLanguages]="availableLanguages()"
        />
      </ui-form-field>

      @if (showSecondLanguageInput) {
        <button uiIconButton secondary size="xl" (click)="switchLanguageDirection()">
          <i class="app-icon-code-view"></i>
        </button>
        <ui-form-field data-cy="language2-field">
          <label uiLabel>{{ "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.LANGUAGE_TRANSLATION" | translate }}</label>
          <ui-language-input
            formControlName="language2"
            data-cy="generate-flashcards-settings-form-language2-input"
            [allLanguages]="availableLanguages()"
          />
        </ui-form-field>
      }
    </div>
  </div>

  <div class="form-group">
    <label uiLabel>{{ "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.TYPE.LABEL" | translate }}</label>
    <ui-toggle-button-group data-cy="generate-flashcards-settings-form-type-toggle" [formControl]="form.controls.type">
      @for (flashcardType of flashcardTypes; track $index) {
        <button
          uiToggleButton
          [value]="flashcardType.value"
          [attr.data-cy]="'generate-flashcards-settings-form-type-option-' + flashcardType.value"
        >
          <span class="text-with-premium-pill">
            {{ flashcardType.option | translate }}
          </span>
        </button>
      }
    </ui-toggle-button-group>
  </div>

  <div class="form-group">
    <label uiLabel for="instructions"> </label>
    <textarea
      id="instructions"
      rows="4"
      formControlName="instructions"
      data-cy="generate-flashcards-settings-form-instructions-textarea"
      [placeholder]="'AI_GENERATION.ADVANCED_SETTINGS_DIALOG.ADDITIONAL_INSTRUCTIONS_PLACEHOLDER' | translate"
    ></textarea>
  </div>
</form>
