import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { ActionId, ActionsHub, OpenStreakDialogActionTrigger } from '@stsm/actions-hub';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { StreakStore } from '@stsm/streak/services/streak-store';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { StreakDialogComponent } from '../components/streak-dialog/streak-dialog.component';

@Injectable({ providedIn: 'root' })
export class StreakDialogService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
    private readonly _streakStore: StreakStore,
  ) {
    this._actionsHub.registerActionHandler(ActionId.OPEN_STREAK_DIALOG, (trigger: OpenStreakDialogActionTrigger) => {
      return this._show(trigger);
    });
  }

  init(): void {
    noop();
  }

  private async _show({ source }: OpenStreakDialogActionTrigger): Promise<void> {
    await firstValueFrom(this._streakStore.fetchStreak());

    this._platformModalService.schedule<Partial<InputPropertiesOf<StreakDialogComponent>>, void, StreakDialogComponent>({
      component: StreakDialogComponent,
      data: { source },
      disposeOnNavigation: false,
      webOptions: {
        width: 'min(480px, 80vw)',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
