import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { isNil } from 'lodash-es';
import { Observable, of, pipe, ReplaySubject, switchMap, take, tap } from 'rxjs';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { LearnMenuTrackingSource } from '@stsm/analytics/models/source-property-types';
import { SpacedRepetitionAction, SpacedRepetitionMode } from '@stsm/flashcards/types/spaced-repetition';
import { SyncInfoService } from '@stsm/offline-mode/services/sync-info.service';
import { SYNC_INFO_SERVICE } from '@stsm/offline-mode/tokens/sync-info-service.token';
import { PremiumLimitsService } from '@stsm/premium/services/premium-limits.service';
import { FlashcardQuizDefaults } from '@stsm/quiz/feature/constants/flashcard-quiz-defaults';
import { QuizHistory } from '@stsm/quiz/models/quiz-history';
import { QuizHistoriesStore } from '@stsm/quiz/services/quiz-histories.store';
import { NetworkService } from '@stsm/shared/services/network.service';
import { Id } from '@stsm/shared/types/id';
import { SpacedRepetitionSettingsService } from '@stsm/spaced-repetition/services/spaced-repetition-settings.service';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { hasAnyRatedFlashcards } from '@stsm/studysets/util/functions/has-any-rated-flashcards';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { UserFeaturesService } from '@stsm/user/store/user-features.service';

import { AiLearnModeWidgetComponent } from '../learn-mode-widgets/ai-learn-mode-widget/ai-learn-mode-widget.component';
import { OralQuizLearnModeWidgetComponent } from '../learn-mode-widgets/oral-quiz-learn-mode-widget/oral-quiz-learn-mode-widget.component';
import { PracticeLearnModeWidgetComponent } from '../learn-mode-widgets/practice-learn-mode-widget/practice-learn-mode-widget.component';
import { SpacedRepetitionLearnModeWidgetComponent } from '../learn-mode-widgets/spaced-repetition-learn-mode-widget/spaced-repetition-learn-mode-widget.component';

import { LearnContextMenuSelection, LearnContextMenuSelectionData } from './learn-context-menu-selection';

@UntilDestroy()
@Component({
  selector: 'app-learn-context-menu',
  templateUrl: './learn-context-menu.component.html',
  styleUrls: ['./learn-context-menu.component.scss'],
  imports: [
    AiLearnModeWidgetComponent,
    AsyncPipe,
    PracticeLearnModeWidgetComponent,
    SpacedRepetitionLearnModeWidgetComponent,
    OralQuizLearnModeWidgetComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'learn-context-menu',
    '[class.offline]': 'isOffline()',
  },
})
export class LearnContextMenuComponent {
  @Input({ required: true })
  set studyset(studyset: Studyset | undefined) {
    if (studyset) {
      this._studyset = studyset;
      this.studysetId$.next(studyset.id);
    }
  }

  get studyset(): Studyset | undefined {
    return this._studyset;
  }

  @Input({ required: true }) trackingSource!: LearnMenuTrackingSource;

  protected readonly chunkSize$: Observable<number> = this._spacedRepetitionSettingsService.getChunkSize();

  protected latestQuizHistory$?: Observable<QuizHistory | undefined>;

  protected readonly isStudysetSynchronized: WritableSignal<boolean> = signal(false);
  protected readonly isOffline: WritableSignal<boolean> = signal(false);

  protected readonly SpacedRepetitionMode: typeof SpacedRepetitionMode = SpacedRepetitionMode;

  protected minimumExamQuantity: number = FlashcardQuizDefaults.MIN_QUIZ_QUANTITY;

  protected readonly studysetId$: ReplaySubject<number> = new ReplaySubject<number>(1);
  protected readonly hasReachedPremiumLimit: Signal<boolean> = this._premiumLimitsService.hasReachedOralQuizModePremiumLimit;
  protected readonly isDogfoodingUser: Signal<boolean> = toSignal(this._userFeaturesService.isDogfoodingUser$, { initialValue: false });

  private _studyset?: Studyset;

  constructor(
    @Optional() @Inject(SYNC_INFO_SERVICE) private readonly _syncInfoService: SyncInfoService | null,
    private readonly _spacedRepetitionSettingsService: SpacedRepetitionSettingsService,
    private readonly _dialogRef: DialogRef<LearnContextMenuComponent, LearnContextMenuSelectionData | undefined>,
    private readonly _actionsHub: ActionsHub,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _quizHistoriesStore: QuizHistoriesStore,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _networkService: NetworkService,
    private readonly _userFeaturesService: UserFeaturesService,
    private readonly _premiumLimitsService: PremiumLimitsService,
  ) {
    this.studysetId$
      .pipe(
        take(1),
        switchMap((studysetId: Id) => {
          return this._studysetsStoreFacade.studysetById(studysetId);
        }),
        tap((studyset: Studyset): void => {
          this._studyset = studyset;
          this._changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.latestQuizHistory$ = this.studysetId$.pipe(
      switchMap((studysetId: Id) => {
        return this._quizHistoriesStore.latestQuizHistoryByStudysetId$(studysetId);
      }),
    );

    rxMethod(pipe(tap((showOfflineDisclaimer: boolean) => this.isOffline.set(showOfflineDisclaimer))))(
      this._networkService.showOfflineDisclaimer$,
    );

    if (!isNil(this._syncInfoService)) {
      this.studysetId$
        .pipe(
          take(1),
          switchMap((studysetId: number) => this._syncInfoService?.isSynchronized(studysetId) ?? of(false)),
          untilDestroyed(this),
        )
        .subscribe((isStudysetSynchronized: boolean) => {
          this.isStudysetSynchronized.set(isStudysetSynchronized);
        });
    }
  }

  protected get hasStartedPractice(): boolean {
    return hasAnyRatedFlashcards(this.studyset);
  }

  protected startSpacedRepetition(action: SpacedRepetitionAction): void {
    if (isNil(this.studyset) || this.isOffline()) {
      return;
    }

    this._dialogRef.dismiss({ selection: LearnContextMenuSelection.SPACED_REPETITION, action });
  }

  protected spacedRepetitionOptionsClick(): Promise<void> {
    if (!this.studyset || this.isOffline()) {
      return Promise.resolve();
    }

    return this._actionsHub.triggerAction(ActionId.OPEN_FLASHCARD_DASHBOARD_SETTINGS, { studyset: this.studyset });
  }

  protected practice(): void {
    if (!this.studyset || (this.isOffline() && !this.isStudysetSynchronized())) {
      return;
    }

    this._dialogRef.dismiss({ selection: LearnContextMenuSelection.PRACTICE });
  }

  protected startSmartExam(): void {
    if (!this.studyset || this.isOffline()) {
      return;
    }

    this._dialogRef.dismiss({ selection: LearnContextMenuSelection.QUIZ });
  }

  protected startOralQuiz(): void {
    if (!this.studyset || this.isOffline()) {
      return;
    }

    this._dialogRef.dismiss({ selection: LearnContextMenuSelection.ORAL_QUIZ });
  }
}
