import { Injectable } from '@angular/core';

import { ShareStudygroupActionTrigger } from '@stsm/actions-hub';
import { PropertiesOf } from '@stsm/shared/types/properties-of';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ShareStudysetLocation } from '@stsm/studysets/models/studyset-share-location';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { ShareStudysetDialogComponent } from './share-studyset-dialog.component';

@Injectable({ providedIn: 'root' })
export class ShareStudysetDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openDialog({
    studysetId,
    shareStudysetLink,
    shareStudygroup,
  }: {
    studysetId: number;
    // callbacks to avoid circular dependencies
    shareStudysetLink: (params: { studyset: Studyset; location?: ShareStudysetLocation }) => Promise<void>;
    shareStudygroup: (trigger: ShareStudygroupActionTrigger) => void;
  }): void {
    const dialogRef = this._platformModalService.create({
      component: ShareStudysetDialogComponent,
      data: <PropertiesOf<ShareStudysetDialogComponent>>{
        studysetId,
      },
      webOptions: {
        width: 400,
        minHeight: 'min(600px, 80vh)',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });

    dialogRef.componentInstance?.instance.shareStudysetLinkRequest.subscribe((studyset: Studyset) => {
      void shareStudysetLink({ studyset, location: ShareStudysetLocation.STUDYGROUP });
    });

    dialogRef.componentInstance?.instance.shareStudygroupRequest.subscribe((trigger: ShareStudygroupActionTrigger) =>
      shareStudygroup(trigger),
    );
  }
}
