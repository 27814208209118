<ui-form-field>
  <label uiLabel>{{ "CREATE_EDIT_STUDYSET.COUNTRY" | translate }}</label>
  <ui-select [formControl]="control">
    <ui-option *ngFor="let country of countries; trackBy: trackByIndex" [value]="country.value" [triggerLabel]="country.value">
      <ng-container *ngIf="!country.imageUrl; else iconTmpl">
        <img class="flag" alt="" height="16" width="24" [ngSrc]="country.imageUrl" />
      </ng-container>
      <ng-template #iconTmpl>
        <i class="app-icon-subject-geography"></i>
      </ng-template>
      <span class="selected-option"> {{ country.value }}</span>
    </ui-option>
  </ui-select>
</ui-form-field>
