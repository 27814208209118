import { AsyncPipe } from '@angular/common';
import type { EffectRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, effect, Inject, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IonBackdrop, NavController } from '@ionic/angular/standalone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilKeyChanged, map, Observable, startWith } from 'rxjs';

import { NavbarMaterialCreationDialogService } from '@stsm/ai-assistant/components/navbar-material-creation-dialog/navbar-material-creation-dialog.service';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { HelpCenterDialogService } from '@stsm/global/composite/components/help-center/help-center-dialog.service';
import { SharingBaseService } from '@stsm/global/composite/services/sharing-base.service';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { SHARING_SERVICE } from '@stsm/global/composite/tokens/sharing-service.token';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { Tab } from '@stsm/shared/enums/tab';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { ToolbarItemComponent } from '@stsm/ui-components/toolbar-item/toolbar-item.component';
import { User } from '@stsm/user/models/user';
import { canShowJobsForUserCountry, shouldShowDealsForUser } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { NavigationRoute } from '../../models/navigation-route';
import { ScaffoldStore } from '../../scaffold-store.service';
import { TabIndicatorMap, TabStatusService } from '../../tab-status.service';
import { PLATFORM_NAVIGATION_ROUTES } from '../../tokens/navigation-routes.token';
import { NavigationMenuComponent } from '../navigation-menu/navigation-menu.component';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[appNavigation]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ToolbarItemComponent, TranslatePipe, AsyncPipe, NavigationMenuComponent, IonBackdrop, IconButtonComponent],
})
export class NavigationComponent {
  logo$: Observable<LogoSource>;

  protected readonly showSubMenu: WritableSignal<boolean> = signal(false);

  protected readonly LogoSource: typeof LogoSource = LogoSource;

  protected readonly currentTab: Signal<Tab | undefined> = toSignal(this._scaffoldStore.currentTab$);

  protected readonly indicators: Signal<TabIndicatorMap | undefined> = toSignal(this._tabStatusService.indicators$);

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected readonly isFullPageRouteActive: Signal<boolean | undefined> = this._layoutStore.isFullPageRouteActive;

  protected readonly canShowJobsTab: Signal<boolean> = toSignal(
    this._userStoreFacade.user$.pipe(
      distinctUntilKeyChanged('countryId'),
      map((user: User | undefined) => (user ? canShowJobsForUserCountry(user.countryId) : false)),
    ),
    { initialValue: false },
  );

  protected readonly showJobsTab: Signal<boolean> = computed(() => {
    const canShow = this.canShowJobsTab();
    const isMobileLayout = this._layoutStore.isMobileLayout();
    const showCta = this.showCreationCtaInNavigationBar();

    return canShow && (!isMobileLayout || showCta);
  });

  protected readonly showDealsTab: Signal<boolean> = toSignal(this._userStoreFacade.user$.pipe(map(shouldShowDealsForUser)), {
    initialValue: false,
  });

  protected readonly showCreationCtaInNavigationBar: Signal<boolean> = computed(
    () => this._scaffoldStore.enableCreationCtaInNavigationBar() && this.isMobileLayout(),
  );

  protected readonly isMoreTabActive: Signal<boolean> = computed(() => {
    const currentTab = this.currentTab();

    return (
      this.showCreationCtaInNavigationBar() &&
      !this.showSubMenu() &&
      !this.isFullPageRouteActive() &&
      this.subNavigationRoutes().some((route: NavigationRoute) => {
        return route.path === currentTab;
      })
    );
  });

  protected showIndicatorOnMoreTab: Signal<boolean> = computed(() => {
    const indicatorMap = this.indicators();

    return (
      this.showCreationCtaInNavigationBar() &&
      !this.showSubMenu() &&
      this.subNavigationRoutes().some((route: NavigationRoute) => {
        return indicatorMap?.[route.path];
      })
    );
  });

  protected readonly Tab: typeof Tab = Tab;

  protected readonly visibleNavigationRoutes: Signal<NavigationRoute[]> = computed(() => {
    const showDealsTab = this.showDealsTab();
    const showJobsTab = this.showJobsTab();

    return this._navigationRoutes.filter((navigationRoute: NavigationRoute) => {
      switch (navigationRoute.path) {
        case Tab.JOBS:
          return showJobsTab;
        case Tab.DEALS:
          return showDealsTab;
        default:
          return true;
      }
    });
  });

  protected readonly mainNavigationRoutes: Signal<NavigationRoute[]> = computed(() => {
    return this.visibleNavigationRoutes().filter((navigationRoute: NavigationRoute) => {
      return ![Tab.PROFILE, Tab.JOBS, Tab.DEALS].includes(navigationRoute.path);
    });
  });

  protected readonly subNavigationRoutes: Signal<NavigationRoute[]> = computed(() => {
    return this.visibleNavigationRoutes().filter((navigationRoute: NavigationRoute) => {
      return [Tab.PROFILE, Tab.JOBS, Tab.DEALS].includes(navigationRoute.path);
    });
  });

  protected readonly showBackdrop: WritableSignal<boolean> = signal(false);

  constructor(
    @Inject(PLATFORM_NAVIGATION_ROUTES) private readonly _navigationRoutes: NavigationRoute[],
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(SHARING_SERVICE) private readonly _sharingService: SharingBaseService,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _navbarMaterialCreationDialogService: NavbarMaterialCreationDialogService,
    private readonly _tabStatusService: TabStatusService,
    private readonly _helpCenterDialogService: HelpCenterDialogService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _scaffoldStore: ScaffoldStore,
    private readonly _navController: NavController,
    private readonly _themingStore: ThemingStore,
    private readonly _layoutStore: LayoutStore,
  ) {
    this.logo$ = this._themingStore.darkThemeActive$.pipe(
      map((isDarkThemeActive: boolean) => this._getLogo(isDarkThemeActive)),
      startWith(LogoSource.HAT_BLACK),
    );

    void this._scaffoldStore.initCreationCtaInNavigationBarExperiment();

    const effectRef: EffectRef = effect(
      (): void => {
        // Trigger exposure event when the user sees the mobile navigation bar once per session
        if (this.isMobileLayout()) {
          this._scaffoldStore.triggerCreationCtaExposureEvent();
          effectRef.destroy();
        }
      },
      { manualCleanup: true },
    );
  }

  protected onNavItemClicked(tab: Tab): void {
    const shouldAnimate = tab === this.currentTab();

    this._closeSubMenu();
    void this._navController.navigateRoot(tab, { animated: shouldAnimate, animationDirection: 'back' });
  }

  protected onLogoClick(): void {
    this.onNavItemClicked(Tab.HOME);
  }

  protected onInviteFriendsClick(): void {
    this._closeSubMenu();
    this._sharingService.shareApp({ source: 'side_menu' });
  }

  protected openFeedbackModal(): void {
    this._helpCenterDialogService.showHelpCenter();
  }

  protected openMaterialCreationDialog(): void {
    this._closeSubMenu();
    this._navbarMaterialCreationDialogService.openMaterialCreationDialog();
  }

  /**
   * Handles the click on the hamburger menu.
   */
  protected onHamburgerMenuClick(): void {
    const shouldShowBackdrop = !this.showSubMenu();

    if (shouldShowBackdrop) {
      this._analyticsService.trackEvent({ action: 'hamburger_more_menu_open' });
    }

    this.showBackdrop.set(shouldShowBackdrop);
    this.showSubMenu.set(shouldShowBackdrop);
  }

  protected onHideBackdrop(): void {
    this.showBackdrop.set(false);
    this.showSubMenu.set(false);
  }

  private _getLogo(isDarkThemeActive: boolean): LogoSource {
    if (this._environment.CONTENT_CREATORS) {
      return LogoSource.HAT_CONTENT;
    }

    return isDarkThemeActive ? LogoSource.HAT_WHITE : LogoSource.HAT_BLACK;
  }

  private _closeSubMenu(): void {
    if (this.showSubMenu()) {
      this.showBackdrop.set(false);
      this.showSubMenu.set(false);
    }
  }
}
