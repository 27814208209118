<ui-dialog-template showCloseButton [heading]="(isEditMode ? 'STUDYSETS.EDIT_STUDYSET' : 'STUDYSETS.CREATE_STUDYSET') | translate">
  <form id="studyset-form" slot="content" [formGroup]="form" (keydown.enter)="onEnterKeyPressed($event)">
    <ui-form-field data-cy="studyset-creation-form-name-field">
      <label uiLabel [for]="GlobalElementId.STUDYSET_SUBJECT_INPUT">{{ "CREATE_EDIT_STUDYSET.NAME_LABEL" | translate }}</label>
      <input
        #input
        uiInput
        data-cy="create-edit-studyset-studyset-name-input"
        formControlName="name"
        autocapitalize="none"
        autocomplete="off"
        [id]="GlobalElementId.STUDYSET_SUBJECT_INPUT"
        [placeholder]="'CREATE_EDIT_STUDYSET.NAME_PLACEHOLDER' | translate"
        [attr.maxlength]="studysetNameMaxLength"
        (keyup.enter)="input.blur()"
      />
    </ui-form-field>

    <div data-cy="studyset-creation-form-subject-field" [attr.id]="GlobalElementId.STUDYSET_SELECT_SUBJECT">
      <label uiLabel>{{ "CREATE_EDIT_STUDYSET.SUBJECT_LABEL" | translate }}</label>
      <app-subject-dropdown formControlName="subjectId" [showCustomSubjects]="false" [canEditUserSubjects]="(isPupil$ | async) === true" />
    </div>

    <ui-form-field class="color-selector" data-cy="studyset-creation-form-color-field">
      <label uiLabel>{{ "CREATE_EDIT_STUDYSET.COLOR_LABEL" | translate }}</label>
      <ui-color-carousel-selector formControlName="colorId" ngDefaultControl />
    </ui-form-field>

    <div *ngIf="isContentEnv">
      <!-- COUNTRIES -->
      <app-studyset-country-picker formControlName="countryIds" />

      <!-- LANGUAGE -->
      <ui-form-field>
        <label uiLabel>{{ "CREATE_EDIT_STUDYSET.LANGUAGE" | translate }}</label>
        <ui-select formControlName="language">
          @for (language of languages; track language.value) {
            <ui-option [value]="language.value" [triggerLabel]="language.labelTranslationKey | translate">
              <span>{{ language.labelTranslationKey | translate }}</span>
            </ui-option>
          }
        </ui-select>
      </ui-form-field>

      <ng-container *ngIf="countriesIncludesDach">
        <!-- SCHOOL TYPE -->
        <ui-form-field>
          <label uiLabel>{{ "CREATE_EDIT_STUDYSET.SCHOOL_TYPE" | translate }}</label>
          <ui-select formControlName="schoolTypeId">
            @for (schoolType of schoolTypes$ | async; track schoolType.id) {
              <ui-option [value]="schoolType.id" [triggerLabel]="schoolType.name">
                <span>{{ schoolType.name }}</span>
              </ui-option>
            }
          </ui-select>
        </ui-form-field>

        <!-- CLASS LEVEL -->
        <ui-form-field>
          <label uiLabel>{{ "CREATE_EDIT_STUDYSET.CLASS_LEVEL" | translate }}</label>
          <ui-select formControlName="classLevel">
            @for (classLevel of classLevels; track classLevel) {
              <ui-option [value]="classLevel" [triggerLabel]="classLevel">
                <span>{{ classLevel }}</span>
              </ui-option>
            }
          </ui-select>
        </ui-form-field>
      </ng-container>
    </div>

    <div class="public-wrapper">
      <div class="share-container">
        <div class="label-container">
          <label data-cy="create-edit-studyset-share-heading" [for]="GlobalElementId.SHARE_STUDYSET">
            {{ "CREATE_EDIT_STUDYSET.PUBLIC_TOGGLE_TITLE" | translate }}
          </label>
          <div class="message" data-cy="studyset-initially-public-text">
            {{ "CREATE_EDIT_STUDYSET.PUBLIC_TOGGLE_DESCRIPTION" | translate }}
          </div>
          <button *ngIf="showSharingHelp()" class="learn-more-button" uiButton quarternary accent (click)="showSharingHelpDialog()">
            {{ "CREATE_EDIT_STUDYSET.LEARN_MORE" | translate }}
          </button>
        </div>

        <ui-slide-toggle
          data-cy="studyset-creation-form-public-share-toggle"
          formControlName="shared"
          [attr.id]="GlobalElementId.SHARE_STUDYSET"
        />
      </div>
    </div>
  </form>

  <div class="button-wrapper" slot="footer">
    <button
      uiButton
      data-cy="studyset-creation-form-create-button"
      form="studyset-form"
      [attr.id]="GlobalElementId.CREATE_NEW_STUDYSET_BUTTON"
      [disabled]="form.invalid"
      (click)="submitForm()"
    >
      {{ createEditButtonTranslationKey | translate }}
    </button>
  </div>
</ui-dialog-template>
