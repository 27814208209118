<ui-info-card isInteractive [uiDisabled]="isOfflinePracticeDisabled || disabled" [isContentAligned]="true">
  <ng-container slot="header">
    {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.PRACTICE.AB_TITLE" | translate }}
  </ng-container>

  <ng-container slot="description">
    <span *ngIf="!isOfflinePracticeDisabled">
      {{
        descriptionTranslationKey
          | translate
            : {
                learnedCount: studyset?.flashcardGoodCount ?? 0 | numberWithUnits,
                totalCount: studyset?.flashcardCount ?? 0 | numberWithUnits,
              }
      }}
    </span>
    <span *ngIf="isOfflinePracticeDisabled" class="offline-message">
      <i role="img" class="icon app-icon-offline" aria-hidden="true"></i>
      {{ "FLASHCARDS.LEARN_MODES_CAROUSEL.PRACTICE.OFFLINE.BUTTON" | translate }}
    </span>
  </ng-container>

  <ng-container slot="icon">
    <div class="icon-with-background">
      <i *ngIf="!hasLearnedAll" role="img" class="icon app-icon-smiley-good" aria-hidden="true"></i>
      <i *ngIf="hasLearnedAll" role="img" class="icon app-icon-check" aria-hidden="true"></i>
      <ui-circle-multiple-progressbar *ngIf="hasStartedPractice" [progressSections]="progressSections" [strokeWidth]="12" />
    </div>
  </ng-container>
</ui-info-card>
