import { ChangeDetectionStrategy, Component, computed, Inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { AdLocation } from '@stsm/advertisement/models/ad-location';
import { SidebarAdsStore } from '@stsm/advertisement/services/sidebar-ads-store.service';
import { AdGroup } from '@stsm/advertisement/util/ad-sidebar-util';
import { PREMIUM_AD_ID } from '@stsm/advertisement/util/premium-ads-util';
import { AbTestService, ExperimentFlag } from '@stsm/analytics/global/services/ab-test.service';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { PremiumAdComponent } from '@stsm/premium/feature/components/premium-ad/premium-ad.component';
import { IsElementVisibleInformation, IsVisibleDirective } from '@stsm/shared/directives';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';

import { AdvertisementComponent } from '../advertisement/advertisement.component';

@Component({
  selector: 'app-enigma-sidebar',
  templateUrl: './ads-sidebar.component.html',
  styleUrl: './ads-sidebar.component.scss',
  imports: [AdvertisementComponent, PremiumAdComponent, IsVisibleDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'enigma-sidebar',
    '[class.first-slot-premium-enigma]': 'isFirstSlotPremiumAd()',
  },
})
export class AdsSidebarComponent {
  protected readonly currentAdGroup: Signal<AdGroup | undefined> = toSignal(this._sidebarAdsStore.currentAdGroup$);
  protected readonly isFullPageRouteActive: Signal<boolean> = toSignal(this._layoutStore.isFullPageRouteActive$, { initialValue: true });
  protected readonly isFirstSlotPremiumAd: Signal<boolean> = computed(() => this.currentAdGroup()?.[0]?.ad.id === PREMIUM_AD_ID);

  protected readonly AdLocation: typeof AdLocation = AdLocation;
  protected readonly PREMIUM_AD_ID: number = PREMIUM_AD_ID;

  private _isPremiumAdsCustomExposureSent: boolean = false;

  constructor(
    private readonly _layoutStore: LayoutStore,
    private readonly _sidebarAdsStore: SidebarAdsStore,
    private readonly _abTestService: AbTestService,
    private readonly _actionsHub: ActionsHub,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {}

  protected onGetPremiumClick(): void {
    void this._actionsHub.triggerAction(ActionId.OPEN_PREMIUM_DIALOG, { source: 'right_sidebar' });
  }

  protected onIsPremiumAdVisible({ isVisible }: IsElementVisibleInformation, options: { location: AdLocation }): void {
    if (!isVisible || this.isFullPageRouteActive()) {
      return;
    }

    if (!this._isPremiumAdsCustomExposureSent) {
      void this._abTestService.trackCustomExposure({
        flag: ExperimentFlag.PREMIUM_ADS,
        eventAction: 'exposure_premium_ads',
      });

      this._isPremiumAdsCustomExposureSent = true;
    }

    this._analyticsService.trackEvent({
      action: 'premium_ad_impression',
      properties: { location: options.location },
    });
  }
}
