import { Inject, Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { ActionId, ActionsHub, OpenCreateFlashcardContextMenuActionTrigger } from '@stsm/actions-hub';
import { AiCreationFlowDialogService } from '@stsm/ai-generation/feature/components/ai-creation-flow/ai-creation-flow-dialog.service';
import { FlashcardCreationTrackingSource, StudysetOpenTrackingSource } from '@stsm/analytics/models/source-property-types';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { ContextMenuOption } from '@stsm/global/models/context-menu-option';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetMessageService } from '@stsm/studysets/services/studyset-message.service';
import { StudysetsTrackingEventsService } from '@stsm/studysets/services/studysets-tracking-events.service';
import { ContextMenuService } from '@stsm/ui-components/dialogs/context-menu/context-menu.service';

import { FlashcardActionsService } from './flashcards-actions.service';

type CreateFlashcardContextMenuKey = 'manual' | 'ai' | 'import';

interface ContextMenuParams {
  isMobileApp: boolean;
  showAiOption: boolean;
}

interface CreateFlashcardContextMenuOption extends ContextMenuOption<CreateFlashcardContextMenuKey> {
  showOption?: (params: ContextMenuParams) => boolean;
}

const contextMenuOptions: CreateFlashcardContextMenuOption[] = [
  {
    key: 'manual',
    translationKey: 'FLASHCARDS.CREATE_MANUALLY',
    iconClass: 'app-icon-edit',
    dataCy: 'flashcard-grid-actions-create-button',
  },
  {
    key: 'ai',
    translationKey: 'SMART_CREATION.GENERATE_WITH_AI',
    iconClass: 'app-icon-sparkles',
    useAiTheme: true,
    dataCy: 'flashcard-grid-actions-create-ai-button',
  },
];

@Injectable({ providedIn: 'root' })
export class CreateFlashcardContextMenuService {
  constructor(
    private readonly _contextMenuService: ContextMenuService,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    private readonly _studysetsTrackingEventsService: StudysetsTrackingEventsService,
    private readonly _flashcardActionsService: FlashcardActionsService,
    private readonly _aiCreationFlowDialogService: AiCreationFlowDialogService,
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
    private readonly _studysetMessageService: StudysetMessageService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(
      ActionId.OPEN_CREATE_FLASHCARD_CONTEXT_MENU,
      (trigger: OpenCreateFlashcardContextMenuActionTrigger) => {
        return this._openCreateFlashcardContextMenu(trigger);
      },
    );
  }

  init(): void {
    noop();
  }

  private async _openCreateFlashcardContextMenu({
    studyset,
    event,
    trackingSource,
    showAiOption,
    shouldNavigateViaStudyset = false,
  }: OpenCreateFlashcardContextMenuActionTrigger): Promise<void> {
    // for web, we directly open the standalone editor
    // for mobile we only open the context menu if the AI option should be shown
    if (!this._isMobileApp || (this._isMobileApp && !showAiOption)) {
      if (shouldNavigateViaStudyset) {
        await this._navigateViaStudyset(studyset, trackingSource as StudysetOpenTrackingSource);
      }

      this._createFlashcardManually(studyset, trackingSource, true);

      return;
    }

    this._studysetsTrackingEventsService.studysetCreateFlashcardsContextMenuOpenEvent(trackingSource, 'non_ai');

    const selection = await this._contextMenuService.showContextMenu<CreateFlashcardContextMenuKey>(contextMenuOptions, {
      flexibleConnectedTo: event.target as Element,
    });

    if (shouldNavigateViaStudyset && ['ai', 'manual'].includes(selection)) {
      await this._navigateViaStudyset(studyset, trackingSource as StudysetOpenTrackingSource);
    }

    switch (selection) {
      case 'ai':
        void this._aiCreationFlowDialogService.openAiCreationFlowDialog(studyset);
        break;
      case 'manual':
        this._createFlashcardManually(studyset, trackingSource);
        break;
      case 'import':
        this._importFlashcard(studyset);
        break;
      default:
        break;
    }
  }

  private _createFlashcardManually(studyset: Studyset, trackingSource: FlashcardCreationTrackingSource, skipTracking?: boolean): void {
    if (!skipTracking) {
      this._studysetsTrackingEventsService.studysetCreateFlashcardsContextMenuInteractEvent('create_flashcards_manually', 'non_ai');
    }
    void this._navigationService.navigateToCreateFlashcard(studyset.id, { trackingSource });
  }

  private _importFlashcard(studyset: Studyset): void {
    this._studysetsTrackingEventsService.studysetCreateFlashcardsContextMenuInteractEvent('import_flashcards', 'non_ai');
    this._flashcardActionsService.importFlashcards(studyset.id);
  }

  private _navigateViaStudyset(studyset: Studyset, trackingSource: StudysetOpenTrackingSource): Promise<void> {
    return this._navigationService
      .navigateForwardToStudyset(studyset.id, trackingSource)
      .then(() => firstValueFrom(this._studysetMessageService.onStudysetPageViewDidEnter()));
  }
}
