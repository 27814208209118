import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

import { FeedbackService } from '@stsm/global/composite/services/feedback.service';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogHeaderComponent } from '@stsm/ui-components/dialogs/components/dialog-header/dialog-header.component';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

@UntilDestroy()
@Component({
  selector: 'app-report-import-problem',
  templateUrl: './report-import-problem.component.html',
  styleUrls: ['./report-import-problem.component.scss'],
  imports: [ButtonComponent, TranslatePipe, FormsModule, DialogHeaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportImportProblemComponent {
  @Input() content: string = '';

  reportArea: string = '';

  constructor(
    private readonly _dialogRef: DialogRef<ReportImportProblemComponent>,
    private readonly _feedbackService: FeedbackService,
  ) {}

  closeProblemReporting(): void {
    this._dialogRef.dismiss();
  }

  submitProblemReport(): void {
    this._feedbackService
      .sendUserFeedbackOrBugReport({
        feedbackSource: 'Flashcard Import',
        message: `${this.reportArea},
      full content of the flashcard paste: ${this.content}`,
      })
      .pipe(
        tap(() => this._dialogRef.dismiss()),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
