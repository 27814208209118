import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { EnvironmentProviders, ErrorHandler, inject, provideAppInitializer, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha-2';

import { LeadGenAdModalService } from '@stsm/advertisement/feature/components/lead-gen-ad/lead-gen-ad-modal.service';
import { AdLinkOpeningWebService } from '@stsm/advertisement/services/ad-link-opening-web.service';
import { AD_LINK_OPENING_SERVICE } from '@stsm/advertisement/services/tokens/ad-link-opening-service.token';
import { AiCreationSettingsAndPreviewDialogService } from '@stsm/ai-generation/feature/components/ai-creation-settings-and-preview/ai-creation-settings-and-preview.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { CONTEXTUAL_TUTORIAL_SERVICE } from '@stsm/contextual-tutorial';
import { recaptchaV3SiteKeyFactory } from '@stsm/devtools/feature/util/devtools-util';
import { SURVEY_SERVICE } from '@stsm/feed';
import { FlashcardImportDialogService } from '@stsm/flashcards/feature/flashcard-import/flashcard-import-dialog.service';
import { CreateFlashcardContextMenuService } from '@stsm/flashcards/feature/services/create-flashcard-context-menu.service';
import { FLASHCARD_TAGS_DIALOG_SERVICE } from '@stsm/flashcards/feature/tokens/flashcard-tags-dialog-service.token';
import { LearnContextMenuService, LearnModeTaskService } from '@stsm/flashcards/learn-modes';
import { FlashcardsAdService } from '@stsm/flashcards/services/flashcards-ad.service';
import { FLASHCARD_DATA_STORE } from '@stsm/flashcards/services/tokens/flashcard-data-store.token';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { NotificationsService } from '@stsm/global/composite/services/notifications.service';
import { TitleService } from '@stsm/global/composite/services/title.service';
import { FEATURE_TIME_TRACKING_SERVICE } from '@stsm/global/composite/tokens/feature-time-tracking-service.token';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { NOTIFICATIONS_SERVICE } from '@stsm/global/composite/tokens/notifications-service.token';
import { SHARING_SERVICE } from '@stsm/global/composite/tokens/sharing-service.token';
import { ONBOARDING_STORE } from '@stsm/onboarding/control/onboarding-store.token';
import { PremiumLimitToastService } from '@stsm/premium/feature/services/premium-limit-toast.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/feature/tokens/premium-modal-service.token';
import { PREMIUM_SERVICE } from '@stsm/premium/services/tokens/premium-base-service.token';
import { PREMIUM_PLANS_SERVICE } from '@stsm/premium/services/tokens/premium-plans-service.token';
import { PURCHASE_SERVICE } from '@stsm/premium/services/tokens/purchase-service.token';
import { FlashcardQuizService } from '@stsm/quiz/feature/services/flashcard-quiz.service';
import { PLATFORM_NAVIGATION_ROUTES } from '@stsm/scaffold/tokens/navigation-routes.token';
import { LOGGER_CONFIG } from '@stsm/shared/logger/logger-config.token';
import { WINDOW_PROVIDER } from '@stsm/shared/providers/window.provider';
import { BROWSER_STORAGES_PROVIDER } from '@stsm/shared/services/browser-storage/browser-storages.provider';
import { APP_VERSION } from '@stsm/shared/tokens/app-version.token';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { FlashcardDashboardSettingsModalService } from '@stsm/spaced-repetition/feature/components/flashcard-dashboard-settings-modal/flashcard-dashboard-settings-modal.service';
import { StreakDialogService } from '@stsm/streak/feature/services/streak-dialog.service';
import { CreateEditStudyplanEventModalService } from '@stsm/studyplan/creation';
import { CreateEditStudysetDialogService } from '@stsm/studysets/create-edit';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { SHARE_STUDYSET_SERVICE } from '@stsm/studysets/sharing';
import { TAGS_SERVICE } from '@stsm/tags/services/tokens/tags-service.token';
import { USER_SERVICE } from '@stsm/user/services/tokens/user-service.token';

import { environment } from '../environments/environment';

import { NAVIGATION_ROUTES } from './app.routes';
import { AppSettings } from './app-settings';
import { PaymentDialogService } from './components/payment-dialog/payment-dialog.service';
import { ContextualTutorialService } from './contextual-tutorials/services/contextual-tutorial.service';
import { CustomErrorHandler } from './error-handler';
import { SurveyService } from './feed/services/survey.service';
import { FlashcardDataStore } from './flashcards/flashcard-data-store.service';
import { FlashcardTagsDialogService } from './flashcards/flashcard-tags-dialog/flashcard-tags-dialog.service';
import { FlashcardsService } from './flashcards/flashcards.service';
import { HTTP_INTERCEPTOR_PROVIDERS } from './http-interceptors';
import { OnboardingStore } from './onboarding/onboarding-store.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { FeatureTimeTrackingService } from './shared/services/feature-time-tracking.service';
import { NavigationService } from './shared/services/navigation.service';
import { PlatformService } from './shared/services/platform.service';
import { PremiumService } from './shared/services/premium.service';
import { PremiumModalWebService } from './shared/services/premium-modal-web.service';
import { PremiumPlansWebService } from './shared/services/premium-plans-web.service';
import { ShareStudysetService } from './shared/services/share-studyset.service';
import { SharingService } from './shared/services/sharing.service';
import { StudysetsService } from './shared/services/studysets.service';
import { TagsService } from './shared/services/tags.service';
import { UserService } from './shared/services/user.service';

export function getAppProviders(): (Provider | EnvironmentProviders)[] {
  return [
    { provide: LOGGER_CONFIG, useValue: { loggerLevel: environment.LOGGER.LEVEL } },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: ErrorHandler, useClass: environment.SENTRY_DSN ? CustomErrorHandler : ErrorHandler },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: () => recaptchaV3SiteKeyFactory(environment.production, environment.RECAPTCHA_SITE_KEY),
    },
    { provide: ANALYTICS_SERVICE, useExisting: AnalyticsService },
    { provide: FEATURE_TIME_TRACKING_SERVICE, useExisting: FeatureTimeTrackingService },
    { provide: USER_SERVICE, useExisting: UserService },
    { provide: ONBOARDING_STORE, useExisting: OnboardingStore },
    { provide: NAVIGATION_SERVICE, useExisting: NavigationService },
    { provide: STUDYSETS_SERVICE, useExisting: StudysetsService },
    { provide: FLASHCARDS_SERVICE, useExisting: FlashcardsService },
    { provide: AD_LINK_OPENING_SERVICE, useExisting: AdLinkOpeningWebService },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: PLATFORM_SERVICE, useExisting: PlatformService },
    { provide: CONTEXTUAL_TUTORIAL_SERVICE, useExisting: ContextualTutorialService },
    { provide: PREMIUM_MODAL_SERVICE, useExisting: PremiumModalWebService },
    { provide: PREMIUM_PLANS_SERVICE, useExisting: PremiumPlansWebService },
    { provide: PREMIUM_SERVICE, useExisting: PremiumService },
    { provide: PURCHASE_SERVICE, useExisting: PaymentDialogService },
    { provide: APP_VERSION, useValue: AppSettings.VERSION_NUMBER },
    { provide: IS_MOBILE_APP, useValue: false },
    { provide: SHARING_SERVICE, useExisting: SharingService },
    { provide: PLATFORM_NAVIGATION_ROUTES, useValue: NAVIGATION_ROUTES },
    { provide: SURVEY_SERVICE, useExisting: SurveyService },
    { provide: TAGS_SERVICE, useExisting: TagsService },
    { provide: FLASHCARD_DATA_STORE, useExisting: FlashcardDataStore },
    { provide: FLASHCARD_TAGS_DIALOG_SERVICE, useExisting: FlashcardTagsDialogService },
    { provide: SHARE_STUDYSET_SERVICE, useExisting: ShareStudysetService },
    { provide: NOTIFICATIONS_SERVICE, useExisting: NotificationsService },
    { provide: TitleStrategy, useExisting: TitleService },
    BROWSER_STORAGES_PROVIDER,
    HTTP_INTERCEPTOR_PROVIDERS,
    WINDOW_PROVIDER,
    provideAppInitializer(() => {
      inject(AnalyticsService).init();
      inject(FlashcardQuizService).init();
      inject(CreateEditStudysetDialogService).init();
      inject(CreateEditStudyplanEventModalService).init();
      inject(FlashcardImportDialogService).init();
      inject(SHARE_STUDYSET_SERVICE).init();
      inject(StreakDialogService).init();
      inject(LearnModeTaskService).init();
      inject(FlashcardDashboardSettingsModalService).init();
      inject(LearnContextMenuService).init();
      inject(AiCreationSettingsAndPreviewDialogService).init();
      inject(LeadGenAdModalService).init();
      inject(PREMIUM_MODAL_SERVICE).init();
      inject(PremiumLimitToastService).init();
      inject(CreateFlashcardContextMenuService).init();
    }),

    /**
     * This is currently a bad solution. The FlashcardDataStore is provided in root and needs the FlashcardsAdService.
     * Usually the FlashcardsAdService should be local to the flashcard trainer page / spaced repetition / quiz.
     * For web, we need to split the data storage / fetching layer and the store that handles the practice mode, but
     * this is currently not the case.
     *
     * For web, we thus have one global instance and local instances for Quiz and Spaced Repetition
     */
    FlashcardsAdService,
  ];
}
