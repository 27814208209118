import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { firstValueFrom, map, Observable, Subject, take, tap } from 'rxjs';

import { ActionId, ActionsHub, GetAiCreationSettingsActionTrigger } from '@stsm/actions-hub';
import { AiCreationSettingsResult } from '@stsm/ai-generation/types/ai-creation-settings-result';
import type { FlashcardSettingsValues, GenerateAiFlashcardsOptions } from '@stsm/ai-generation/types/flashcard-generation';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { AiCreationSettingsAndPreviewComponent } from './ai-creation-settings-and-preview.component';

@Injectable({ providedIn: 'root' })
export class AiCreationSettingsAndPreviewDialogService {
  private readonly _flashcardGenerationSettings$: Subject<FlashcardSettingsValues | undefined> = new Subject<
    FlashcardSettingsValues | undefined
  >();

  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.GET_AI_CREATION_SETTINGS, (trigger: GetAiCreationSettingsActionTrigger) =>
      firstValueFrom(this._getAiCreationSettings(trigger)),
    );
  }

  init(): void {
    noop();
  }

  private _getAiCreationSettings({
    studysetId,
    generationOptions,
    slidesetId,
    usePreviousSettings,
  }: GetAiCreationSettingsActionTrigger): Observable<AiCreationSettingsResult> {
    if (usePreviousSettings) {
      return this._flashcardGenerationSettings$.pipe(
        take(1),
        map((settings: FlashcardSettingsValues | undefined) => ({ settings })),
      );
    }

    const ref: DialogRef<AiCreationSettingsAndPreviewComponent, FlashcardSettingsValues | undefined> = this._platformModalService.create({
      component: AiCreationSettingsAndPreviewComponent,
      data: { studysetId, generationOptions, slidesetId },
      mobileOptions: { isAutoHeight: true },
      webOptions: { maxHeight: '90vh', width: 1100 },
    });

    return ref.afterClosed().pipe(
      tap((settings: FlashcardSettingsValues | undefined) => this._flashcardGenerationSettings$.next(settings)),
      map((settings: Readonly<Pick<GenerateAiFlashcardsOptions, 'type' | 'format' | 'language' | 'instructions'>> | undefined) => ({
        settings,
      })),
    );
  }
}
