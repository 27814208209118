import { inject } from '@angular/core';
import { isNil } from 'lodash-es';
import { catchError, distinctUntilKeyChanged, map, Observable, retry, shareReplay, throwError } from 'rxjs';

import {
  DESIGN_TRIAL_PRODUCT_ID_MOBILE,
  DESIGN_TRIAL_PRODUCT_ID_PAYPAL_DEV,
  DESIGN_TRIAL_PRODUCT_ID_PAYPAL_PROD,
} from '@stsm/premium/models/constants/design-trial-product-id';
import {
  DISCOUNT_PRODUCT_ID_MOBILE,
  DISCOUNT_PRODUCT_ID_PAYPAL_DEV,
  DISCOUNT_PRODUCT_ID_PAYPAL_PROD,
} from '@stsm/premium/models/constants/discount-product-id';
import { PlanDuration, PremiumPlan, PremiumPlanSerializer, PremiumPlansWithStorePricesResult } from '@stsm/premium/models/premium-plan';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { BaseService } from '@stsm/shared/services/base.service';
import { PlatformBaseService } from '@stsm/shared/services/platform-base.service';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { JsonObject } from '@stsm/shared/types/json-object';
import { PaymentProvider } from '@stsm/user/models/payment-provider';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

export abstract class PremiumPlansService {
  protected readonly baseService: BaseService = inject(BaseService);
  protected readonly sentryService: SentryService = inject(SentryService);
  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly isMobileApp: boolean = inject(IS_MOBILE_APP);
  protected readonly platformService: PlatformBaseService = inject(PLATFORM_SERVICE);
  protected readonly environment: EnvironmentBase = inject(ENVIRONMENT);

  private _premiumPlans$: Observable<PremiumPlan[]> | undefined;

  protected constructor() {
    this.userStoreFacade.userAvailable$.subscribe(() => {
      // reset the observable such that the premiumPlans are refetched for the user
      this._premiumPlans$ = undefined;
    });

    this.userStoreFacade.premiumInfo$.pipe(distinctUntilKeyChanged('isPremium')).subscribe(() => {
      // reset the observable if the user's premium status changes. If the user purchases the hard-coded yearly
      // subscription, the backend makes sure that this plan is returned in the plans/ request. We need to avoid that a
      // plans/ request from before the purchase is being cached as this causes the user to be unable to cancel the
      // subscription.
      this._premiumPlans$ = undefined;
    });
  }

  getPaymentProvider(): PaymentProvider {
    return !this.isMobileApp ? PaymentProvider.PAYPAL : this.platformService.isIOS ? PaymentProvider.APPLE : PaymentProvider.GOOGLE;
  }

  getPremiumPlans(): Observable<PremiumPlan[]> {
    if (isNil(this._premiumPlans$)) {
      this._premiumPlans$ = this.baseService.get('payments/plans/').pipe(
        map((res: JsonObject) => PremiumPlanSerializer.listFromJson(res, this.getPaymentProvider())),
        retry({
          delay: 1000,
          count: 2,
          resetOnSuccess: true,
        }),
        catchError((error: Error) => {
          this.loggerService.error(error);
          this.sentryService.reportToSentry(`Error in getPremiumPlans()`, error);

          return throwError(() => error);
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false, // we do not want to trigger a new backend call just because all subscribers unsubscribed
        }),
      );
    }

    return this._premiumPlans$;
  }

  protected getHardCodedYearlyDiscountPremiumPlan(): PremiumPlan {
    const productID = this.isMobileApp
      ? DISCOUNT_PRODUCT_ID_MOBILE
      : this.environment.production
        ? DISCOUNT_PRODUCT_ID_PAYPAL_PROD
        : DISCOUNT_PRODUCT_ID_PAYPAL_DEV;

    const overallPrice: string = '34.99€';
    const monthlyPrice: string = '2.92€';
    const discount: number = 50;
    const priceWithoutDiscount: string = '69.99€';
    const duration: PlanDuration = PlanDuration.YEARLY;
    const titleKey: string = 'PREMIUM.SUBSCRIPTIONS_YEARLY_TITLE';
    const durationKey: string = 'PREMIUM.SUBSCRIPTIONS_YEARLY_DURATION';

    return {
      productID,
      titleKey,
      overallPrice,
      monthlyPrice,
      priceWithoutDiscount,
      discount,
      duration,
      durationKey,
      hasTrial: false,
    };
  }

  /**
   * 1 year subscription with introductory offer for first month at 4.99€
   */
  protected getHardCodedDesignTrialPremiumPlan(): PremiumPlan {
    return {
      productID: this.isMobileApp
        ? DESIGN_TRIAL_PRODUCT_ID_MOBILE
        : this.environment.production
          ? DESIGN_TRIAL_PRODUCT_ID_PAYPAL_PROD
          : DESIGN_TRIAL_PRODUCT_ID_PAYPAL_DEV,
      titleKey: 'PREMIUM.SUBSCRIPTIONS_YEARLY_TITLE',
      overallPrice: '69.99€',
      monthlyPrice: '5.83€',
      priceWithoutDiscount: '69.99€',
      discount: 0,
      duration: PlanDuration.YEARLY,
      durationKey: 'PREMIUM.SUBSCRIPTIONS_YEARLY_DURATION',
      hasTrial: false,
      introductoryOffer: {
        period: 'P1M',
        billingCycleCount: 1,
        displayPrice: '4.99€',
      },
    };
  }

  abstract getPremiumPlansWithStorePrices(): Observable<PremiumPlansWithStorePricesResult>;

  abstract getYearlyDiscountPremiumPlan(): Observable<PremiumPlan>;

  abstract getDesignTrialPremiumPlans(): Observable<PremiumPlansWithStorePricesResult>;
}
