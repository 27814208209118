import { Injectable } from '@angular/core';
import { MatSnackBarDismiss } from '@angular/material/snack-bar';
import { noop } from 'lodash-es';
import { switchMap, take } from 'rxjs';

import { ActionId, ActionsHub, ShowPremiumLimitToastActionTrigger } from '@stsm/actions-hub';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import {
  PremiumLimitToastComponent,
  PremiumLimitToastComponentInput,
} from '../components/premium-limit-toast/premium-limit-toast.component';

@Injectable({ providedIn: 'root' })
export class PremiumLimitToastService {
  constructor(
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _toastService: ToastService,
    private readonly _actionsHub: ActionsHub,
  ) {
    this._actionsHub.registerActionHandler(ActionId.SHOW_PREMIUM_LIMIT_TOAST, (trigger: ShowPremiumLimitToastActionTrigger) => {
      this.showPremiumLimitToast(trigger);

      return Promise.resolve();
    });
  }

  init(): void {
    noop();
  }

  showPremiumLimitToast(options: ShowPremiumLimitToastActionTrigger): void {
    this._userStoreFacade.premiumInfo$
      .pipe(
        take(1),
        switchMap((premiumInfo: PremiumInfo) => {
          const snackbar = this._toastService.showComponentToast(PremiumLimitToastComponent, {
            data: <PremiumLimitToastComponentInput>{
              heading: options.heading,
              description: options.description,
              hasUsedTrial: premiumInfo.usedFreeTrial,
            },
            horizontalPosition: 'start',
            verticalPosition: 'bottom',
          });

          return snackbar.afterDismissed();
        }),
      )
      .subscribe((result: MatSnackBarDismiss) => {
        if (result.dismissedByAction) {
          void this._actionsHub.triggerAction(ActionId.OPEN_PREMIUM_DIALOG, {
            source: options.premiumModalCallSource,
          });
        }
      });
  }
}
