<ui-dialog-template showCloseButton [heading]="'AI_GENERATION.ADVANCED_SETTINGS_DIALOG.HEADLINE' | translate">
  <div slot="content" class="content">
    <app-generate-flashcards-settings-form
      [availableLanguages]="availableLanguages()"
      (formDataOutput)="updateAdditionalSettings($event)"
    />
    <div class="preview">
      <header>
        <h4>{{ "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.PREVIEW.HEADLINE" | translate }}</h4>
        <button uiButton tertiary accent [disabled]="isLoadingPreview()" (click)="regenerateExampleClick()">
          @if (isLoadingPreview()) {
            <span uiAnimatedIllustration name="ai-sparkles" slot="icon"></span>
          } @else {
            <i class="app-icon-refresh" slot="icon"></i>
          }
          <span>{{ "AI_GENERATION.ADVANCED_SETTINGS_DIALOG.PREVIEW.UPDATE_PREVIEW" | translate }}</span>
        </button>
      </header>
      <div class="fc-preview-wrapper">
        @if (isLoadingPreview()) {
          <ui-skeleton-card />
        } @else {
          <app-flashcard-preview-card data-cy="ai-creation-settings-and-preview-preview-card" showAnswer [flashcard]="previewFlashcard" />
        }
      </div>
    </div>
  </div>

  <div slot="footer" class="footer">
    <button uiButton secondary [attr.data-cy]="'ai-creation-settings-and-preview-cancel-button'" (click)="cancel()">
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button uiButton aiTheme [attr.data-cy]="'ai-creation-settings-and-preview-create-button'" (click)="submit()">
      {{ "SMART_CREATION.BUTTON_GENERATE_TITLE" | translate }}
    </button>
  </div>
</ui-dialog-template>
