import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { catchError, defer, finalize, firstValueFrom, Observable, of, tap } from 'rxjs';

import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { StudysetCrudTrackingSource } from '@stsm/analytics/models/source-property-types';
import { BrowserService } from '@stsm/global/composite/services/browser.service';
import { GlobalElementId } from '@stsm/global/models/enums/element-id';
import { ZendeskArticleType } from '@stsm/global/models/zendesk-article-type';
import { TranslatePipe } from '@stsm/i18n/global/pipes/translate.pipe';
import { baseLanguageOptions, LanguageOption } from '@stsm/i18n/models/language-options';
import { getSupportedLanguage, SupportedLanguage } from '@stsm/i18n/models/supported-language';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { COUNTRY_ID_GERMANY } from '@stsm/shared/models/country-ids';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { CreateEditMode } from '@stsm/shared/types/create-edit-mode';
import { Id } from '@stsm/shared/types/id';
import { shareReplayRefCount } from '@stsm/shared/util/rxjs.util';
import { noWhitespaceValidator } from '@stsm/shared/validators/nowhitespace-validator';
import { ContentDestinationSelectionMode } from '@stsm/studysets/models/content-destination-selection-mode';
import { getEventPropsForStudyset, Studyset } from '@stsm/studysets/models/studyset';
import { StudysetCountry } from '@stsm/studysets/models/studyset-country';
import { studysetCountryIdMapping } from '@stsm/studysets/models/studyset-country-picker';
import { StudysetUpdateBuilder } from '@stsm/studysets/models/studyset-update-builder';
import { StudysetsBaseService } from '@stsm/studysets/services/studysets-base.service';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { SubjectDropdownComponent } from '@stsm/subjects/feature/components/subject-dropdown/subject-dropdown.component';
import { isCustomSubjectId } from '@stsm/subjects/models/subject';
import { ButtonComponent } from '@stsm/ui-components/button';
import { ColorCarouselSelectorComponent } from '@stsm/ui-components/color-carousel-selector/color-carousel-selector.component';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { FormFieldComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { InputDirective } from '@stsm/ui-components/input';
import { LoadingService } from '@stsm/ui-components/loading-dialog';
import { OptionComponent } from '@stsm/ui-components/option';
import { SelectComponent } from '@stsm/ui-components/select';
import { SlideToggleComponent } from '@stsm/ui-components/slide-toggle';
import { SchoolService } from '@stsm/user/feature/services/school.service';
import { SchoolType } from '@stsm/user/models/school-type';
import { isPupil } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { StudysetCountryPickerComponent } from './components/studyset-country-picker/studyset-country-picker.component';

export const CREATE_EDIT_STUDYSET_DIALOG_ID = 'create-edit-studyset-dialog';

interface StudysetCreationForm {
  name: FormControl<string>;
  subjectId: FormControl<Id | null>;
  colorId: FormControl<number>;
  shared: FormControl<boolean>;

  // CCP
  language: FormControl<SupportedLanguage>;
  schoolTypeId: FormControl<number | null>;
  countryIds: FormControl<number[]>;
  classLevel: FormControl<ClassLevelLabel | null>;
}

const contentDestinationSelectionModeToSubmitTranslationKey: Record<ContentDestinationSelectionMode, string> = {
  onboarding: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CONTINUE',
  examDate: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CONTINUE',
  add: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CREATE_AND_ADD_FLASHCARDS_BUTTON',
  addAi: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CREATE_AND_ADD_FLASHCARDS_BUTTON',
  move: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CREATE_AND_MOVE_FLASHCARDS_BUTTON',
  addNote: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CREATE_AND_ADD_NOTE_BUTTON',
  aiAssistant: 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CONTINUE',
};

type ClassLevelLabel = '5/6' | '7/8' | '9/10' | '11/12' | 'Abiturrelevante Themen';
const classLevelLabelIdsMapping: { [Key in ClassLevelLabel]: number[] } = {
  '5/6': [5, 6],
  '7/8': [7, 8],
  '9/10': [9, 10],
  '11/12': [11, 12],
  'Abiturrelevante Themen': [13],
};

const STUDYSET_NAME_MAX_LENGTH: number = 100;
const STUDYSET_NAME_MIN_LENGTH: number = 3;

@UntilDestroy()
@Component({
  selector: 'app-create-edit-studyset-dialog',
  templateUrl: './create-edit-studyset-dialog.component.html',
  styleUrls: ['./create-edit-studyset-dialog.component.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    ColorCarouselSelectorComponent,
    SlideToggleComponent,
    TranslatePipe,
    InputDirective,
    ButtonComponent,
    FormFieldComponent,
    SubjectDropdownComponent,
    OptionComponent,
    StudysetCountryPickerComponent,
    DialogTemplateComponent,
    LabelComponent,
    SelectComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'create-edit-studyset-dialog',
  },
})
export class CreateEditStudysetDialogComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  trackingSource!: StudysetCrudTrackingSource;

  @Input()
  contentDestinationSelectionMode: ContentDestinationSelectionMode | undefined;

  @Input() set studyset(studyset: Studyset | undefined) {
    this.form.reset();

    if (isNil(studyset)) {
      this.mode = CreateEditMode.CREATE;

      return;
    }

    this._studyset = studyset;
    this._studysetColorId = studyset.colorId;
    this.mode = CreateEditMode.EDIT;

    // School type and class levels is currently supported for germany only!
    // Refactoring needed after a proper concept to support different countries is created.
    const germany = studyset.countries?.find(({ id }: StudysetCountry) => id === COUNTRY_ID_GERMANY);
    const classLevelsConcatinated = germany?.classLevels?.join('/');

    this.form.patchValue({
      name: studyset.name,
      shared: studyset.shared,
      colorId: studyset.colorId,
      subjectId: studyset.subjectId,
      language: getSupportedLanguage(studyset.language),
      countryIds: studyset.countries?.map(({ id }: StudysetCountry) => id) ?? [],
      classLevel:
        classLevelsConcatinated === '13' ? ('Abiturrelevante Themen' as ClassLevelLabel) : (classLevelsConcatinated as ClassLevelLabel),
      schoolTypeId: germany?.schoolTypes[0]?.id, // Only one school type is supported.
    });

    if (this.mode === CreateEditMode.EDIT && studyset.shared) {
      this.isInitiallyPublic.set(true);
      this.form.controls.shared.disable();
    }
  }

  get studyset(): Studyset | undefined {
    return this._studyset;
  }

  get isContentEnv(): boolean {
    return this._environment.CONTENT_CREATORS;
  }

  protected mode: CreateEditMode = CreateEditMode.CREATE;
  protected isInitiallyPublic: WritableSignal<boolean> = signal(false);
  protected readonly studysetNameMaxLength: number = STUDYSET_NAME_MAX_LENGTH;

  protected readonly showSharingHelp: Signal<boolean> = computed(() => this._targetMarketProvider.targetMarket() === 'core');

  protected readonly form: FormGroup<StudysetCreationForm> = new FormGroup<StudysetCreationForm>({
    colorId: new FormControl<number>(1, { nonNullable: true }),
    name: new FormControl<string>('', {
      validators: [
        Validators.required,
        Validators.minLength(STUDYSET_NAME_MIN_LENGTH),
        Validators.maxLength(STUDYSET_NAME_MAX_LENGTH),
        noWhitespaceValidator(),
      ],
      nonNullable: true,
    }),
    shared: new FormControl<boolean>(true, { nonNullable: true }),
    subjectId: new FormControl<Id | null>(null),

    // CCP
    language: new FormControl<SupportedLanguage>('en', { nonNullable: true }),
    schoolTypeId: new FormControl<number | null>(null),
    countryIds: new FormControl<number[]>([], { nonNullable: true }),
    classLevel: new FormControl<ClassLevelLabel | null>(null),
  });

  protected readonly schoolTypes$: Observable<SchoolType[]> = this._schoolService
    .getSchoolTypes(COUNTRY_ID_GERMANY)
    .pipe(shareReplayRefCount(1));

  protected readonly GlobalElementId: typeof GlobalElementId = GlobalElementId;
  protected readonly isPupil$: Observable<boolean> = this._userStoreFacade.isPupil$;

  protected get isEditMode(): boolean {
    return this.mode === CreateEditMode.EDIT;
  }

  get countriesIncludesDach(): boolean {
    return this.form.value.countryIds?.some((id: number) => studysetCountryIdMapping.DACH.includes(id)) ?? false;
  }

  protected get createEditButtonTranslationKey(): string {
    if (isNil(this.contentDestinationSelectionMode)) {
      return this.mode === CreateEditMode.EDIT ? 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.SAVE' : 'CREATE_EDIT_STUDYSET.SUBMIT_BUTTON.CREATE';
    }

    return contentDestinationSelectionModeToSubmitTranslationKey[this.contentDestinationSelectionMode];
  }

  protected readonly languages: LanguageOption[] = baseLanguageOptions;
  protected readonly classLevels: ClassLevelLabel[] = ['5/6', '7/8', '9/10', '11/12', 'Abiturrelevante Themen'];

  private _studyset: Studyset | undefined;

  private _studysetColorId?: number;

  constructor(
    private readonly _userStoreFacade: UserStoreFacade,
    @Inject(STUDYSETS_SERVICE) private readonly _studysetsService: StudysetsBaseService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _platformModalService: PlatformModalService,
    private readonly _loadingService: LoadingService,
    private readonly _loggerService: LoggerService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _targetMarketProvider: TargetMarketProvider,
    private readonly _browserService: BrowserService,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    private readonly _schoolService: SchoolService,
    private readonly _layoutStore: LayoutStore,
  ) {}

  ngOnInit(): void {
    this._analyticsService.trackEvent({
      action: this.isEditMode ? 'edit_subject_open' : 'studyset_creation_modal_open',
      properties: {
        source: this.trackingSource,
      },
    });

    void this._setInitialSubjectForStudents();

    // when inside studyset, live update the color
    if (this._layoutStore.currentTheme() !== undefined) {
      this.form.controls.colorId.valueChanges.pipe(untilDestroyed(this)).subscribe((colorId: number) => {
        this._layoutStore.setCurrentTheme(colorId);
      });
    }
  }

  ngOnDestroy(): void {
    if (this._layoutStore.currentTheme() !== undefined) {
      this._layoutStore.setCurrentTheme(this._studysetColorId);
    }
  }

  /**
   * Prevents the form from being submitted when the user presses enter.
   */
  protected onEnterKeyPressed(event: Event): void {
    event.preventDefault();
  }

  protected async submitForm(): Promise<void> {
    if (this.form.invalid || isNil(this.form.value.name) || this.form.value.name.length < STUDYSET_NAME_MIN_LENGTH) {
      return;
    }

    const name = this.form.value.name.trim();
    const colorId = this.form.value.colorId ?? 1;

    // use getRawValue as the sharedState formControl is disabled in case the studyset is already shared
    const isShared = this.form.getRawValue().shared;
    const subjectId = this.form.value.subjectId ?? undefined;
    const countryIds = this.form.value.countryIds;
    const language = this._environment.CONTENT_CREATORS ? this.form.value.language : undefined;
    const schoolTypeId = this.form.value.schoolTypeId ?? undefined;
    const classLevelValue = this.form.value.classLevel;
    const selectedClassLevels = !isNil(classLevelValue) ? classLevelLabelIdsMapping[classLevelValue] : undefined;

    // In the Content Env, make sure that a subject is set
    if (this.isContentEnv && this.mode === CreateEditMode.CREATE && (isNil(subjectId) || isCustomSubjectId(subjectId))) {
      this._simpleDialogService.showAlert({
        text: 'STUDYSETS.NO_SUBJECT_SET',
      });

      return;
    }

    const studyset$ = defer(() => {
      this._loadingService.showLoading();

      if (this.mode === CreateEditMode.CREATE) {
        return this._studysetsService
          .createStudyset({
            name,
            colorId,
            isShared,
            subjectId,
            countryIds,
            language,
            classLevels: selectedClassLevels,
            schoolTypeId,
            trackingSource: this.trackingSource,
          })
          .pipe(
            catchError((error: unknown) => {
              this._loggerService.warn(error);
              this._simpleDialogService.showError('ERROR.ERROR_CREATE_STUDYSET');

              return of(undefined);
            }),
          );
      } else {
        if (isNil(this.studyset)) {
          return of(undefined);
        }

        // EDIT MODE
        const builder = new StudysetUpdateBuilder(this.studyset).name(name).colorId(colorId).shared(isShared);
        !isNil(subjectId) && builder.subjectId(subjectId);

        if (this.isContentEnv) {
          !isNil(countryIds) && builder.countries(countryIds, schoolTypeId, selectedClassLevels);
          !isNil(language) && builder.language(language);
        }

        return this._studysetsService.updateStudyset(builder).pipe(
          tap((studyset: Studyset) => {
            this._analyticsService.trackEvent({
              action: 'studyset_edit_complete',
              properties: {
                source: this.trackingSource,
                colorId: studyset.colorId,
                ...getEventPropsForStudyset(studyset),
              },
            });
          }),
          catchError((error: unknown) => {
            this._loggerService.warn(error);
            this._simpleDialogService.showError('ERROR.ERROR_STUDYSET_EDIT');

            return of(undefined);
          }),
        );
      }
    }).pipe(finalize(() => this._loadingService.hideLoading()));

    const newOrUpdatedStudyset = await firstValueFrom(studyset$);

    if (!isNil(newOrUpdatedStudyset)) {
      this._studysetColorId = newOrUpdatedStudyset.colorId;
      this._platformModalService.dismiss(newOrUpdatedStudyset, {
        id: CREATE_EDIT_STUDYSET_DIALOG_ID,
      });
    }
  }

  protected showSharingHelpDialog(): void {
    this._browserService.openZendeskHelpLink(ZendeskArticleType.CREATE_STUDYSET, () => {
      this._simpleDialogService.showAlertMessage('ERROR.ERROR_OPEN_LINK');
    });
  }

  private async _setInitialSubjectForStudents(): Promise<void> {
    const user = await firstValueFrom(this._userStoreFacade.user$);

    if (isPupil(user)) {
      return;
    }

    if (!this.isEditMode && user.subjectIds.length > 0) {
      this.form.patchValue({ subjectId: user.subjectIds[0] });
    }
  }
}
